export const PRODUCT_IMAGES = {
  MENPRO1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1HE1kd9gxfSQ2ix3Ld4lF1/4fabef6ffd6b2dd01cda61ceee235927/label-men.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/VmV5ha1jDkl5UZcIioKVX/54d0a50468c9cab4f575d38d3f469b50/product-men.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6Iu1Q5Y119DRL1N2XCAf92/dda14a021be762022b775a963450cb85/product-men-badge.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/NP4VXw6tvIpDbJCPRyOBR/2822ed5c9562059235cc8239827253f2/product-men-pills.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5UTK9prcQFbM9mChmYjRFh/ff0a40851df5c946eb6029b94be1dc0e/product-men-badge-pills.png?fl=progressive",
    label5:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4OPgHFKVLnRRUGcYjgOeVm/3730e2b25c2f39207aa4a57c8cffdbc1/us-news-weekly-mens-hgn.png?fl=progressive",
    label6:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6RyBeywd12Pj0jqBtsECA5/b439406142d3c54a2149ee7f01d9eea4/MEN3_3.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/10s85I10s2xRjp7r5qbXql/08d9ae0923ed3b7fa374d3e37b1ad4cb/men-kit-gift.png?fl=progressive",
    gift2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5tsZP5d3VWBm4f6NV0RqLg/89e3b51e1201a94a5205c9591a615448/men-free-gift.png?fl=progressive",
  },
  MENPRO3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1HE1kd9gxfSQ2ix3Ld4lF1/4fabef6ffd6b2dd01cda61ceee235927/label-men.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/i9BFDrbSutRtQ4aDzIKGf/3657d4cd1ae2a105e65219a0ea604faa/MENPRO3_1.png?fl=progressive",
    label2: "",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6RyBeywd12Pj0jqBtsECA5/b439406142d3c54a2149ee7f01d9eea4/MEN3_3.png?fl=progressive",
    label4: "",
    lebel5: "",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/3FdT5LhhdX9rZ2Wh41Z8Lt/b21809de11e5e46201581e08a39d13e8/men-core-gift.png?fl=progressive",
    gift2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3WaFjnTWeLkjyX13CmBqIL/59b1527e2e49917fbbeaa5286d093213/menpro3_free-gift.png?fl=progressive",
  },
  MENPRO6: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1HE1kd9gxfSQ2ix3Ld4lF1/4fabef6ffd6b2dd01cda61ceee235927/label-men.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/AXexW6Qs1EEHSGLhOX5cX/971e20610296473040325b8f6c59cd65/MENPRO6_1.png?fl=progressive",
    label2: "",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6nRIML2TtnArsLzxZoJFs8/176e95c1d932637608747fb023eef137/MENPRO6_3.png?fl=progressive",
    label4: "",
  },
  MENDEF1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5d4cvYcGl5wNPgoPepKBi3/6167fde0662532520b014a824fbc09d9/label-mens-dht-inhibitor.png?fl=progressive",
    //prod
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2fHcW2v4HnNGHVenrXP6kP/f62c1cbd797907146e76dc5948826fba/product-men-dht.png?fl=progressive",
    //PRODUCT_PILLS1
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2mMwFqoZhy7Arc1mKhTJOb/e9ef8ff656bef7306226cb270c42a885/product-men-dht-pills1.png?fl=progressive",
    //PRODUCT_PILLS2
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4qGT2SGfaNz10YWh3jlOTh/a06af538c854dc5973d266a4cb60e787/product-men-dht-pills2.png?fl=progressive",
    //PRODUCT_PILLS_BOTH
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6zRIQGQoEse6avnscbdkwB/97641885b1a43a1a7923ffa219fd3ad4/product-men-dht-pills-both.png?fl=progressive",
    //PRODUCT_PILLS_BOTH + BADGE
    label5:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4bKOrduZRNyNSOU91utrUL/971045dfc93bcd9a710868e212910164/product-men-dht-pills-badge.png?fl=progressive",
    //PRODUCT_PILLS_BOTH + BADGE LARGE
    label6:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6cYWGFfrcPiABSik40o3MB/55d7b6551f9072171b41631ad4db8be5/product-men-dht-pills-badge-large.png?fl=progressive",
  },
  MENDEF3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1HE1kd9gxfSQ2ix3Ld4lF1/4fabef6ffd6b2dd01cda61ceee235927/label-men.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2fHcW2v4HnNGHVenrXP6kP/f62c1cbd797907146e76dc5948826fba/product-men-dht.png?fl=progressive",
  },
  MENDEF6: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1HE1kd9gxfSQ2ix3Ld4lF1/4fabef6ffd6b2dd01cda61ceee235927/label-men.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2fHcW2v4HnNGHVenrXP6kP/f62c1cbd797907146e76dc5948826fba/product-men-dht.png?fl=progressive",
  },
  MENFUL1: {
    label0: `https://images.ctfassets.net/0rbfqd9c4jdo/6W2qkWX8KXxuPcnrF0wDfQ/1a5519cde9fa4e249dfe55bfd6137984/label-ga-men-text.png?fl=progressive`,
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1q5fMNguNmmrD96wDhcTic/1d61f00a98ad482eb67f471e69a1254a/product-men-full-support.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3jZeB2frExEKCYPrgG0GfV/8c09bf3f73b5d8dbf64a7d9a97528688/product-men-full-support-pill1.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1f0xKB7VaOrT1U3ei8Xx2k/8f5c7a770610d0b6c211de5f348c3bde/product-men-full-support-pill2.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4GWu3yhd6wjtkGbhojpx7s/b81c0656d608c5c8948733eea8f30de7/men-product-full-support.png?fl=progressive",
    label5:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4CvbnyohYeYHwKpCCqdKoX/5c562f10bf7ae7f0ac46ebf2081ae45e/product-men-full-support-pills-badge.png?fl=progressive",
  },
  MENFUL3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1HE1kd9gxfSQ2ix3Ld4lF1/4fabef6ffd6b2dd01cda61ceee235927/label-men.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4Q5d2h50akWSjYlGJIRrpy/76ca6607a59874c687545adaa26171b7/product-men-growth.png?fl=progressive",
  },
  MENFUL6: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1HE1kd9gxfSQ2ix3Ld4lF1/4fabef6ffd6b2dd01cda61ceee235927/label-men.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4Q5d2h50akWSjYlGJIRrpy/76ca6607a59874c687545adaa26171b7/product-men-growth.png?fl=progressive",
  },
  MENFHK3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1HE1kd9gxfSQ2ix3Ld4lF1/4fabef6ffd6b2dd01cda61ceee235927/label-men.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/11K6NqELQbyzmrDLnbCrNu/49c885e554f99628277028f565e3fc34/product-men-growth-kit.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4j1g1UH1N8HbZkW6JLI7dJ/63c496ccf19e2a12e33081deec3d252a/product-men-fullest-hair-kit-pills.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/mEDmJFvfyAFZ9C2ngKySQ/ee3d15acba561e11dafb655a4e4cc748/fhk-men-gift.png?fl=progressive",
  },
  MEN3PROMO: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1HE1kd9gxfSQ2ix3Ld4lF1/4fabef6ffd6b2dd01cda61ceee235927/label-men.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/VmV5ha1jDkl5UZcIioKVX/54d0a50468c9cab4f575d38d3f469b50/product-men.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6Iu1Q5Y119DRL1N2XCAf92/dda14a021be762022b775a963450cb85/product-men-badge.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5QudScSptWr9kZOt5NyvvO/7a97b52f56b10e46d9254dc860a031d8/product-men-pills.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5UTK9prcQFbM9mChmYjRFh/ff0a40851df5c946eb6029b94be1dc0e/product-men-badge-pills.png?fl=progressive",
    single:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2NYxzwFTsaQ9NNdjFGUnhG/7af9c8351ea89e39a46f33a7151ae118/promo-mens.png?fl=progressive",
    kit: "https://images.ctfassets.net/0rbfqd9c4jdo/1P57RCPNtIMd2fjoWLMUTl/931a28660d7e547cf39eebda60b41472/promo-hairkit-mens.png?fl=progressive",
  },
  WOM1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6SKgaItY9KAaTjjHzUIuRY/7de61e796cc077412bdea1c0f2c0d31f/product-women.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4jLfmyAN0kgFgKbgbUdnIC/f9904849751488385a2736aa82dd9e80/product-women-badge.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5NDic96IdPLZcQAh5dFmm2/0af9281ded8c41b5fc2d0427d0efc10e/product-women-pills.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1fLA7SA0rZWXvJjhjF32wo/3497bbca5f819e59b023ca0290b06c3f/product-women-badge-pills.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/3lMVJR5T62by3zXMzi4zhe/9a1b3bbc30130059f48fc9a5dba7ac32/wom-kit-gift.png?fl=progressive",
    gift2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7J3Wi2XlgqAT3wPXFqM9Gh/8ebe541f7e9f1e8b302463b5c247fe8b/women-free-gift.png?fl=progressive",
  },
  WOM3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2Ftu47NVdfxwcmKQxEAiZH/fe4a20c80cdedab4ac33919ef0aba460/WOM3_1.png?fl=progressive",
    label2: "",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/35FZesV2OnEC6sUndl9Kqq/093a62e42a643699c074b322d6b0a91c/WOM3_3.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/6OHPENfHTo6JFFEoZq0mmY/c6ec2222bfe5bc21476dd20f74f6856a/wom3_free-gift.png?fl=progressive",
  },
  WOM6: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7fg4XWHvaTece4mpumrYej/6aed5902aab64ebfd3fd0c28d1ac992e/WOM6_1.png?fl=progressive",
    label2: "",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2vSwKFDdCltDyK5b37f6kS/aae9fd44175c606faf25cf5c1cb631d2/WOM6_3.png?fl=progressive",
  },
  WOMB1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2NBnN5KQIN2aGvitGXgfZl/d1ee4828f18d35cc346a52c7f06c1fac/womb1-1.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/60pzG5V0YsxqDd3uDK3wiE/dea853a3501358cfc462451944f3e8f8/womb1-2.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7sQhTKr3s9XIscKueL3VDd/9603f143336b973b2fee039480070a5b/womb1-3.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7JDo9w3InLaPytC1VfyZrQ/71b95a32809f0305b0380c066aa0043d/womb1-4.png?fl=progressive",
    label5:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6fVn4K34IxUX6yO9Vn70qj/286d8d41b69b469ce9e6f91605e14052/womb1-5.png?fl=progressive",
  },
  WOMB3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4uFtTilteWIpX06FMxlfOF/690bff181f99aa190fb157c70b32b7c0/womb3-1.png?fl=progressive",
  },
  WOMG1: {
    label0: "",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/HDgKNxll4sssxalxjTfW3/2fdd8fa2be3f345a60bd826fcca3595c/WOMG1_1.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1AHyr14QDRqvCJCcPQtaDp/9cb847a6dfc74b1ac9ebd3e485b91743/WOMG1_2.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1vlpeUjVTZ415MoJH8mK4r/709aaf148ca05e684d1064025e8fe46c/WOMG1_3.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6YUviUwq4Gn7A1psp8VDiU/2fa5380cf3b38f13ed13b9da00986c75/WOMG1_4.png?fl=progressive",
  },
  WOMI1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3I2DZwnz1a6cxuZkTlDguE/a4ffd12950ded376f822546218de2d8e/product-women-collagen.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6G8g3WZWJFsvbKyMFT8rg3/5d4ad5b076bbc0d41f54ff8e62c03aae/product-women-collagen-pills1.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/466NGz5XHd6ixnU3L6Szlf/c27d4624d262d6d19f2e04637817ca16/product-women-collagen-pills2.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/jvx1fv3WYmQ1V5WLQMZck/00876054a83751a31d23d8596fd45430/product-women-collagen-pills-both.png?fl=progressive",
  },
  WOMI3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3I2DZwnz1a6cxuZkTlDguE/a4ffd12950ded376f822546218de2d8e/product-women-collagen.png?fl=progressive",
  },
  WOMM1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6Rvt7Y4iYwk3yzP2AVnO48/5ed7f292538820d724a068012009e423/product-women-dht.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5uGDiAttSu0w29rJfl3MjW/c7c291232d7405585efa7f3a16433528/product-women-dht-pills1.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7oiF2tIbr4c8dSzQPCPhVz/ad105577888d16cdceea3095b4e433e2/product-women-dht-pills2.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6MsJ80Gwzzovif8b0ew6bq/fce94416587a9f6991c56d3c04a8e8bd/product-women-dht-pills-both.png?fl=progressive",
  },
  WOMM3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6Rvt7Y4iYwk3yzP2AVnO48/5ed7f292538820d724a068012009e423/product-women-dht.png?fl=progressive",
  },
  WOMH1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2DnFgH5NoPvdBkwWYkYMlQ/f140bddec5f7d45ff4f52d12cf827319/product-women-hairbiotic.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1RtrQ8IogZ3LcEZqe9MWr5/c0a470024232c4b2b8c8ba48e588daaf/product-women-hairbiotic-pill1.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/mU9UyW0L4ddLCc2VH9P4Y/a7f1da305f74faffd5838b783b3d2526/product-women-hairbiotic-pills2.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4SCdC2nP0lvzGXougKLhG9/d264906e925aba88a41aa6ba2f1f3d64/product-women-hairbiotic-pill-both.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/49iwMThy185DcrSBzzobrp/b1c88825649d9de2c76366b5703c5e45/wom-hairbiotic-gift.png?fl=progressive",
  },
  WOMH3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2DnFgH5NoPvdBkwWYkYMlQ/f140bddec5f7d45ff4f52d12cf827319/product-women-hairbiotic.png?fl=progressive",
  },
  WOMT1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/57U2asWL2iNdFzb8T8uRcq/cfdf6640ce80145dc37efa708a88fab6/product-women-toxin.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/zsG0EXrnHmVfypGuY0ZCf/84be1d9ecc6381e633a3cf9a667e13ec/product-women-toxin-pills1.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7zOYZG4H0V1v24imsUhaxw/2279313ea0c961dc7c08b28c26ff11e4/product-women-toxin-pills2.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/54OThiX22eE26COKfjreof/e831c1be7076b09c86953c7a14b1f59e/product-women-toxin-pills.png?fl=progressive",
  },
  WOMT3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/57U2asWL2iNdFzb8T8uRcq/cfdf6640ce80145dc37efa708a88fab6/product-women-toxin.png?fl=progressive",
  },
  WOMS1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7txGIhGMoCvBN0NerpWMFk/874d2245fca02b77bf6b3c7c2e352f93/product-women-stress.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5vL6XXvQ8CzBP7pKd72QQn/fab2811d4ebd4d3321761857ef9271fd/product-women-stress-pills1.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2yi4DiOnvrSiaF64JUMh88/a127a742444976c864222faaa806e557/product-women-stress-pills2.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1jkyi6FHIctmY7WpobDT2j/48f9f480e540cea9b1ad7a2c0a7a8e66/product-women-toxin-pills-both.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/51mTZ8MHA22rtzgP3w3QUb/bbc14c54fe7a7bfb0fd824ce0af7092d/stress-wom-gift.png?fl=progressive",
  },
  WOMS3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7txGIhGMoCvBN0NerpWMFk/874d2245fca02b77bf6b3c7c2e352f93/product-women-stress.png?fl=progressive",
  },
  WOMFHK3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5fN38811w27uJZ90mS9RJH/e7f02df43aef5f749b883b842d75683d/product-women-growth-kit.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/72sm2AfGGqjNFYZ8xTZwjz/f95df9dee2d34ab7b2d0ba03c329cc44/product-women-fullest-hair-kit-pills.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/SpDFzdxHVECCeqIvILlod/bffbe1bd4bf94ee50c4e6327c472a909/fhk-women-gift.png?fl=progressive",
  },
  WOM3PROMO: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6SKgaItY9KAaTjjHzUIuRY/7de61e796cc077412bdea1c0f2c0d31f/product-women.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4jLfmyAN0kgFgKbgbUdnIC/f9904849751488385a2736aa82dd9e80/product-women-badge.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5NDic96IdPLZcQAh5dFmm2/0af9281ded8c41b5fc2d0427d0efc10e/product-women-pills.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1fLA7SA0rZWXvJjhjF32wo/3497bbca5f819e59b023ca0290b06c3f/product-women-badge-pills.png?fl=progressive",
    single:
      "https://images.ctfassets.net/0rbfqd9c4jdo/0wYJ3BQpbGfoPrk2tR5k6/528af547f1ffc8373b2906ddd31ec381/promo-women.png?fl=progressive",
    kit: "https://images.ctfassets.net/0rbfqd9c4jdo/1FrL8GlQohx2T3eORMAP1T/dcc5a6fd591936634537bcd044fe3482/promo-hairkit-women.png?fl=progressive",
  },
  BAL1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6IvAhsyOqyBRsDWy5rnfVK/840bfed06b5234c353b85cda32fe53e5/product-balance.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6aBN9yyCcvAULfVC6RZ7VV/aa97859ce315fd1b71c64f20f300e7ce/product-balance-badge.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3SMUpWp804YgnTwGYLmZPu/b952671f4fc7a019e03bc296f8415d3b/product-balance-pills.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7V7COM1YUYLRB7cjd5qCm/07211792a63ac8e549f31c6b5c97ff28/product-balance-badge-pills.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/6ImlVsLMX4o3fhPtfH2SnD/943604045bdc5b3ae5b6b52e54aa8f3d/balance-kit-gift.png?fl=progressive",
    gift2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7EoSzwLspM0QEli58fWoeY/f2e5cf405bbdb2fc527ae3d99aff25eb/balance-free-gift.png?fl=progressive",
  },
  BAL3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3eEgNHQKzgxtpIAfewEbJt/af89e7abd19fe3cc337374aa44bf9cf5/BAL3_1.png?fl=progressive",
    label2: "",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3XCN8Yn32ljuv4e2qtXdwJ/5107b3a6f9336a5d6edc3346dae4b415/BAL3_3.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/2RNnvqXfKFdVdsZkUHzRJN/97a4cbca5be6a65d98938838833205dd/bal3_free-gift.png?fl=progressive",
  },
  BAL6: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7eaVkJObfSqJX089pZsKcd/62b90c19fdcd74793a2c649ab2211e94/BAL6_1.png?fl=progressive",
    label2: "",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4Ps2VZU4vxxuFGpi8oGLSQ/286aabefe6c33741ae2af9e08ec9fce5/BAL6_3.png?fl=progressive",
  },
  BALT1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1a3c9GGjBlttWeagXOwfCv/df1ee4146479ee2d89902357bff69c40/product-balance-toxin-cleanse.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6rvxh6hdhWLg5NjfInVU70/993001ac963ae3a8c84f8695c8bd7440/product-balance-toxin-cleanse-pills1.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/20SzLgku4bfD9HZ8c8MtQa/9b0fd13a6726ff72a690869ac4b7f171/product-balance-toxin-cleanse-pills2.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5oJsyAPjGlsy63OWZEpGLC/dd21ca6e9d7c6a728b3f9aa407e36e1e/product-balance-toxin-cleanse-both.png?fl=progressive",
  },
  BALT3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1a3c9GGjBlttWeagXOwfCv/df1ee4146479ee2d89902357bff69c40/product-balance-toxin-cleanse.png?fl=progressive",
  },
  BALI1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1tdCD8QPArlzyt9D7AjZ2s/bd7d9aef9690889ce0f135eee80343ba/product-balance-collagen.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/25TWHoSZtvrUxkT6ytZgSJ/00b764a54a5b942333160d77f0472b29/product-balance-collagen-pills1.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4boKMafkvAjGO1NDoZh6aC/92cb7bedcdf54a0071df434a5633eae2/product-balance-collagen-pills2.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/01cH7ubHLHggPTv4CWhFlZ/400dd69faa0428d063266fd21816fb39/product-balance-collagen-both.png?fl=progressive",
  },
  BALI3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1tdCD8QPArlzyt9D7AjZ2s/bd7d9aef9690889ce0f135eee80343ba/product-balance-collagen.png?fl=progressive",
  },
  BALGI1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6W2qkWX8KXxuPcnrF0wDfQ/1a5519cde9fa4e249dfe55bfd6137984/label-ga-men-text.png?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/356pIOvCTXCLY1ZxKHWl2M/3515dd5e6e1b41fa795296a33876e45d/Boosters_3DCollagen__2B_Hair_Serum__PillsSubstance_3DOff.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1P7PUPXU6TUimQxJQIVKLd/4686371912941793f4f225c613b47afe/Frame_1556.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3bD4L4TC7U5WZmxsvl0W9W/6f7e0bc8d7798e7892959430077d17ae/Balance_collagen_serum.png?fl=progressive",
  },
  BALB1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5zSDTGoaMvczyeNO0eqSiq/a93b07be903272a703ca667d44f49950/balb1-1.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/19EcjI6rGLgAhJEyIkUGWo/8e145add768f0d20c154757fede2944d/balb1-2.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7gC7wf5ysqCfxZkI46XIw6/01ffdcefad5e655201f22339e1a56873/balb1-3.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/37eJCCUuSEYchvgW49xVmI/c5ee320eb9df78d067d69016452e2676/balb1-4.png?fl=progressive",
  },
  BALB3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6YG36iM04eYxrLGfTKaqGR/27c2186595d591ac0e80eeebcd9278a4/balb3-1.png?fl=progressive",
  },
  BALM1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/J06IRbG5fA0K0zB1X1AHe/f618a77ea664ae67e089d5770c9d9ca4/product-balance-hormone-support.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/346D1zWkP6ybWRVdRAe8cj/79e0d10dfae4bde65aa69d0ce302aea4/product-balance-hormone-support-pills1.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7xZSMffNcbdAa12GgTnyJk/f01f0e7d266fa6521023c35e3813284c/product-balance-hormone-support-pills2.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1WleY74rY6wnYBYieU3cr9/ceec76fb16594bd68e4a2cb35bdf5776/product-balance-hormone-support-both.png?fl=progressive",
  },
  BALM3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/J06IRbG5fA0K0zB1X1AHe/f618a77ea664ae67e089d5770c9d9ca4/product-balance-hormone-support.png?fl=progressive",
  },
  BALH1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2pQxseVCgRMUIl2oQH1cp3/cde381f46df21623cb3d8613f2089a47/product-balance-hairbiotic.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/vnZLwPfuzWayDrIqEthbp/66e7f1c527f16730785002b55edfd440/product-balance-hairbiotic-pills1.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7ud4HRoYhCUQLus3Y1dVZ4/9f0259971602d0436b466f674b15d929/product-balance-hairbiotic-pills2.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6I24rEwSyKPuU8E6qh5lSf/af31b277088fc86dcfd0fa8595a17400/product-balance-hairbiotic-both.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/6Grrzrp8Qq6hkQHyPSWIoU/78863ec0a314fbf2aeb283bc44916162/balance-hairbiotic-gift.png?fl=progressive",
  },
  BALH3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2pQxseVCgRMUIl2oQH1cp3/cde381f46df21623cb3d8613f2089a47/product-balance-hairbiotic.png?fl=progressive",
  },
  BALS1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3ewVsvjG0e33UdFsynvpuc/b3609328229cdb218f290a8579017bf5/product-balance-stress-adaptogen.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/46H3po0CMW9ssBnQSarbWM/c8dee7cc16bd36803e40b6e6a1c25754/product-balance-stress-adaptogen-pills1.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1IWROwlgiVxWJLj6eUhNkQ/981df48748ffd34ee3c31e6d42fc6293/product-balance-stress-adaptogen-pills2.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/mvfGXuT4IpOKrM4H1qZVY/0d381e9141fec454cf6483c718bcc6f6/product-balance-stress-adaptogen-both.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/3fTZkK4uRvQoIfXgmYhJ1k/75411744050e75b72725482e80d12c45/stress-bal-gift.png?fl=progressive",
  },
  BALS3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3ewVsvjG0e33UdFsynvpuc/b3609328229cdb218f290a8579017bf5/product-balance-stress-adaptogen.png?fl=progressive",
  },
  BALFHK3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5zEshDDhAUOa46CcUqHsvd/e36bc09850e84e122e14b1c5b6bb86fc/product-balance-growth-kit.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2mtf7Um9jctxmhU8BuD9dd/40267d9e0e43c8b4c6078d2397c65c50/product-balance-fullest-hair-kit-pills.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/7c5rgA4JnVZn44nKTQFeJN/b03c6448bc971f5235e936b5286425b0/fhk-balance-gift.png?fl=progressive",
  },
  BAL3PROMO: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6IvAhsyOqyBRsDWy5rnfVK/840bfed06b5234c353b85cda32fe53e5/product-balance.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6aBN9yyCcvAULfVC6RZ7VV/aa97859ce315fd1b71c64f20f300e7ce/product-balance-badge.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3SMUpWp804YgnTwGYLmZPu/b952671f4fc7a019e03bc296f8415d3b/product-balance-pills.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7V7COM1YUYLRB7cjd5qCm/07211792a63ac8e549f31c6b5c97ff28/product-balance-badge-pills.png?fl=progressive",
    single:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4nXTJiAANguirdAcOxz4F1/331246e9e18a0584ae7272786231049c/promo-balance.png?fl=progressive",
    kit: "https://images.ctfassets.net/0rbfqd9c4jdo/2cJgnb4N8DdMGY7rUyERfV/8fbfad01c54d7346caf582ddb1dcc2d4/promo-hairkit-balance.png?fl=progressive",
  },
  POS1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/27OS8msL4TPAlckNhsaVDY/5f244625e9d9effd2bfa87f62e2425f4/label-postpartum.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5zjIqnesQPzeB2sCLBQ0LG/864d523a552ac6a327edd776af916593/product-postpartum.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5TP33PtO5whkdCaSyXf2o1/a2f7ab2fdd80db8570287ae414c20ba3/product-postpartum-badge.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4pWAqKs8B4ygq8YBMUpEEY/6a801e7952b236a0e00576e72854120a/product-postpartum-pills.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1MQNMQ2sDd1eKWQctx7j9G/e7bad7501a7eaf3582f49f1e429815f3/POS1_4.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/1Vny683jmThAHQzEyyXwou/8e07eac6ac5c9a082b152320735bab5d/postpartum-kit-gift.png?fl=progressive",
    gift2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3MxDGh0U86H9DtYFqV8zbW/304fc59d4bc66987158da5bac59c68a7/postpartum-free-gift.png?fl=progressive",
  },
  POS3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/27OS8msL4TPAlckNhsaVDY/5f244625e9d9effd2bfa87f62e2425f4/label-postpartum.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3L4RUmcB2G5rKGPVh95od2/f32bff26c6ba44cd6f9c76eb47cafbaf/POS3_1.png?fl=progressive",
    label2: "",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7LHbxYgcYggU9AVoVwD35F/b859f03b5fa96e83e65e6b554b950e41/POS3_3.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/5eovorNOIAgjMiCq2GcqPt/fbcc08e1d810b627a295b63803d0a6bc/pos3_free-gift.png?fl=progressive",
  },
  POS6: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/27OS8msL4TPAlckNhsaVDY/5f244625e9d9effd2bfa87f62e2425f4/label-postpartum.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5fNBWTKOeGqll1PMtTMGSo/55015197f785965d6fb42a3b54ffdd2e/POS6_1.png?fl=progressive",
    label2: "",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6v7QVYJapNTwyTOSIxm1ef/265b61f1d6894f423fcf661e8a5767ac/POS6_3.png?fl=progressive",
  },
  POSFHK3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/27OS8msL4TPAlckNhsaVDY/5f244625e9d9effd2bfa87f62e2425f4/label-postpartum.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5Sr3nQPqcihyjLBtWTDtCT/a56eb8ab3676895770d11c8a73bff848/product-postpartum-growth-kit.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1uagHOTkjKvi07bmGEV2fv/49ec129e2b60eaed903116b631a66f87/product-postpartum-fullest-hair-kit-pills.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/cX398B1kJjijEDT6eA2ec/491751bf73c219c4954abe7d8c8f0ea0/fhk-postpartum-gift.png?fl=progressive",
  },
  POS3PROMO: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/27OS8msL4TPAlckNhsaVDY/5f244625e9d9effd2bfa87f62e2425f4/label-postpartum.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5zjIqnesQPzeB2sCLBQ0LG/864d523a552ac6a327edd776af916593/product-postpartum.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5TP33PtO5whkdCaSyXf2o1/a2f7ab2fdd80db8570287ae414c20ba3/product-postpartum-badge.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4pWAqKs8B4ygq8YBMUpEEY/6a801e7952b236a0e00576e72854120a/product-postpartum-pills.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2E8SNVc56cmm4BAcKd3MOB/dc420f62073be1a67b11f53edf2b5a85/product-postpartum-badge-pills.png?fl=progressive",
    single:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7H8sBxRaznUfLOsH3nYD5R/343ed428a1ae267d6a35d18697f7eaaf/promo-postpartum.png?fl=progressive",
    kit: "https://images.ctfassets.net/0rbfqd9c4jdo/3Rp4NfCpKG4XUBdZxZAKx3/c508beb7c47ada379b3ced8ba720a8e9/promo-hairkit-postpartum.png?fl=progressive",
  },
  GACTIVATOR: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5riNaThhDLnB4dAQvbLyBw/06376ba6f90b4b1679eec95c8c467947/Hair-Serum-ingred-list-cropped.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2RIBi7MAeaF3L6IgWDZsPg/b893cbc4d99c9554edee516e02ac493e/booster-hair-serum.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/CAByMuK5nVvSU2nvZvJRH/f86f4419bbe403ec1d347e19535bcb57/booster-hair-serum-pills.png?fl=progressive",
  },
  MGACTIVATOR: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6W2qkWX8KXxuPcnrF0wDfQ/1a5519cde9fa4e249dfe55bfd6137984/label-ga-men-text.png?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2hN3xaMYUd9tqIGjIEtz2g/b6e51a39340f41dc618fed424b22f383/booster-hair-serum-men.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3J5e419fhYTIwGfeE0yZF2/f219b31c32224c6c73dccb41dcd54ed8/booster-hair-serum-pills-men.png?fl=progressive",
  },
  STRESSADAPTOGEN: {
    label0: `https://images.ctfassets.net/0rbfqd9c4jdo/44wGHj2DXAoS3l12zTb6zW/5ee324e3f0532a9c30dd5f3925fdd2df/label-stress.png?fl=progressive`,
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5ISs27oAWhhIv3Q1YNBkss/ae78e8e8c62a453956928198ec26048c/booster-stress-adaptogen.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4VfoDkAca2ccTXPi9YFhre/09535ae5dbcbb50657005e576e75fd88/booster-stress-adaptogen-pills.png?fl=progressive",
  },
  BENERGIZED: {
    label0: `https://images.ctfassets.net/0rbfqd9c4jdo/1eE6OhiR08gVqxDcvM3G4x/d33251226f24a16b88791cfae5a2f684/benergized-label.jpg?fl=progressive`,
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2MkCRGxUOkRVWkJfHuoOsn/30e8e8168699c0aabdab52e752daa263/benergized-1.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5eb7Triv3qZTpICIQUJHWu/05458c4e9bfaf8d4f609b41ce5d9a206/benergized-3.png?fl=progressive",
  },
  HAIRBIOTIC: {
    label0: `https://images.ctfassets.net/0rbfqd9c4jdo/2XVt3oe67szfLdkJfHKRvz/18d3d05dfc727cd125083e47b6f25f60/label-hairbiotic.png?fl=progressive`,
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1wc2ur9J0tSTbfgCtHr09P/36536f4db9daf875138011e4880e40d6/booster-hairbiotic.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/53f4uoZjuEavu6H70k2sEj/34084bcd8843ec331ed0f4a3321bd793/booster-hairbiotic-pills.png?fl=progressive",
  },
  DHTINHIBITOR: {
    label0: `https://images.ctfassets.net/0rbfqd9c4jdo/5d4cvYcGl5wNPgoPepKBi3/6167fde0662532520b014a824fbc09d9/label-mens-dht-inhibitor.png?fl=progressive`,
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6buEEnV6TLyzB6LXCdO1ze/97cf61a207fbd3bccee6b7c6a2c11ac7/booster-men-dht-inhibitor.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5XCwEzMuMHReMb12Bi4mpl/2907d8d2bd109eb17af8396678d6ee07/booster-men-dht-inhibitor-pills.png?fl=progressive",
  },
  CINFUSION: {
    label0: `https://images.ctfassets.net/0rbfqd9c4jdo/2SD5QJk2K3XwK58m7OvHJq/a3f633eae249553a5c60267fd7dd00b9/label-collagen-infusion.png?fl=progressive`,
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1SjonRAthhIY0s2h5iE6nF/e7d36ea4f4ca92cec3e06ca4abb6e99c/booster-collagen-infusion.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7N5pBQBpj9Kp5QPNiuLiv/5fc59d5af6ab800511198263fdd30801/booster-collagen-infusion-pills.png?fl=progressive",
  },
  CBUILDER: {
    label0: `https://images.ctfassets.net/0rbfqd9c4jdo/1amT6WITYF2UYImZo3TLJ3/fa2f9c66ac0b16de4899473dd06385a4/label-collagen-builder.png?fl=progressive`,
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2IvaP6p3rY37CMbbyFQlrn/c1e86decd6e681944b10b1c2020cca25/booster-men-collagen-builder.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/24DB5ap5T0OP9CYfORHVBX/c56d669be6e7ad022383e9fa50e820d4/booster-men-collagen-builder-pills.png?fl=progressive",
  },
  HORMONESUPPORT: {
    label0: `https://images.ctfassets.net/0rbfqd9c4jdo/4Ou7tW1WBwFukVfRgV58gl/e59f93208d0ae6f03a35e32aaf48f857/label-hormone.png?fl=progressive`,
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/73RPc2lEk8d7SR3AejlDHC/0180cd6fed39a9e7883e4af5f7930f7a/booster-hormone-support.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5aSZOBOQGINQHvDW7JNX7s/f560573abe0ecd5288c137b9bf0b3b23/booster-hormone-support-pills.png?fl=progressive",
  },
  TOXINCLEANSE: {
    label0: `https://images.ctfassets.net/0rbfqd9c4jdo/22FbL2aaBzmiCUVredz7pr/4f86a4cdf80d4ef70000856d309e8844/Nutrafol_Toxin_Cleanse_Supp_Label.png?fl=progressive`,
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/62aQ7nswZ7PMT1nI0co3Zl/19004bb42d7c32c60b53618a65b47240/booster-toxin-cleanse.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2qLbmNKsnmaHZVUbWOQLOs/de66c82e7683acccdaf9c7e3d9ef830d/booster-toxin-cleanse-pills.png?fl=progressive",
  },
  RPURIFIER: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4LwLj2i50KJLtvWPiTQEu4/c5736233d0e5b9cabbafa991a3c8fca5/scalp-label-root.png?w=780&h=780",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7uLIydhnBreP770ik5hDH1/dfa0a62423e887039ff59fcaa0eb8207/scalp-root.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/ulTdZSm3MJxnBSJAY9SZ6/4764dc0dfcad305940d390d0ec86a63a/scalp-root-pills.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/654EpgosKT3ZTxh3tX1Elo/aca05ccb6c24a392e42b661dac6f9cd3/shampoo_color_award_desktop.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/746Kodzsa6pO5FnEnCPbmv/6c863f5da3e5fcee8b29682426277954/scalp-root-pills-badge.png?fl=progressive",
    travelsize:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1mCZxyGtHz7wMLTN8CTfj4/666a2aae9defda53e041af2dc979fe79/2oz-Shampoo.png?fl=progressive",
  },
  SDEFENDER: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7zMXlrbPWZoeTDJst9rDb6/6a3fd43a644719b4f17dc7fe13623071/scalp-label-conditioner.png?w=780&h=780",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/ljUju0VWNYodZCPKBprtR/e8ce1b8df544c2f6274898bbf26275c8/scalp-strand.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6dEtq24EixagGW0D6j1eg6/fa18b09a74be582510b7a057dbe75e56/scalp-strand-pills.png?fl=progressive",
    travelsize:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4BFIAzMjdbJAtmwullTXWf/736285a66799957b4a0f096c736d8ba9/2oz-Conditioner.png?fl=progressive",
  },
  BBLOCKER: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2p5bb8rAHwMLhqHTmAeU9p/a82eddc8a18389381fff8c6efb9ff9d8/Nutrafol-BuildUpBlocker-IngredientList.png?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/42XvrjBVXrmLH5BUori7rq/f31d92f45c229ae96327b50731f34bc8/scalp-buildup.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1mJGxzbXpjaVfh82gnt9rF/0aa4197d6ed60f3b5ce0de9337bdb4ae/scalp-buildup-pills.png?fl=progressive",
  },
  HBALANCEKIT: {
    label0: "",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5kbFo8s3TSyDrAPr0KkfHb/50d4769617be1838716ccf58d5d54e86/scalp-kit.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7wQ2zHt0VaS6sjMeKITcYU/77de442c03a2faeaa9a810fa656c9d84/scalp-kit-square.png?fl=progressive",
  },
  HBALANCEKITFULL: {
    label0: "",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5kbFo8s3TSyDrAPr0KkfHb/50d4769617be1838716ccf58d5d54e86/scalp-kit.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7wQ2zHt0VaS6sjMeKITcYU/77de442c03a2faeaa9a810fa656c9d84/scalp-kit-square.png?fl=progressive",
  },
  SCALPSCPROMO: {
    label0: "",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5h2NPYLXi7Md13TIzbWNBq/1a417c769c6f0cb61b9b61edb0bec3f9/Nutrafol-Strand-Defender-and-Root-Purifier-bottle-texture.png?fl=progressive",
  },
  SCALPSC: {
    label0: "",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/77OXVKTklVlxvFhfUAhioP/f36a0f0dd680feab9a1c51b49158efac/shampoo-conditioner.png",
  },
  SCALPSCK: {
    label0: "",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5blzD3xQpHvWNyTIAuQITk/358e87c31342249eb59ddfa8d3b6867b/shampoo-conditioner-mask.png",
  },
  SCALPSCGK: {
    label0: "",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4od1FDefthz44ZNgejgOWP/6874afa6623f8697b8d572059af69fc8/shampoo-conditioner-mask-serum.png",
  },
  CAREKIT: {
    label0: "",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/RddNmQWR7aeHHk0jjslym/b7c097e26ff9b5841c38f36b07c37388/CAREKIT_1.png?fl=progressive",
  },
  VEG1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/yVOGFH39I7nlrQscREW1r/d9df6a9e0ffbd3acb48368a5c4ea815d/label-vegan.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7vyev0TMEbusfjNOQwrvkU/697e821f9b9b6488cc0e64918221f4ad/vegan.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5AgoDWo9AzCJ5C6l2TyoPe/223f9e1e2414b26b1289be24a2f2f209/vegan-badge.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5OWwX7V8OBTmK2iDNmRhQC/dc5331d0ffca6b7c7bf7814f6c89db24/vegan-pills.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/188ZechYAqcxJ5dNBBgmpB/6c29854654014269eeae81a730502fc9/vegan-pills-badge.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/2cyZSiUDyvNlhcDQlMSe9X/5fcf7e5b16d94cc524a2464c18e9ba6e/vegan-kit-gift.png?fl=progressive",
    gift2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5chMM9m1gY1IEuw1ZzPU5j/9f94fd13eeca695482057eb3f9837fc7/vegan-free-gift.png?fl=progressive",
  },
  VEG3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/yVOGFH39I7nlrQscREW1r/d9df6a9e0ffbd3acb48368a5c4ea815d/label-vegan.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4pLyT2ru933iuLvglnKbYO/914fabb9192492b02826b55e7c98c73e/veg3.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4pLyT2ru933iuLvglnKbYO/914fabb9192492b02826b55e7c98c73e/veg3.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2TNS3VIGfDt6SY8z26q7lU/cc84ee9e080261b2a1e59b12e9842369/veg3-pills.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2TNS3VIGfDt6SY8z26q7lU/cc84ee9e080261b2a1e59b12e9842369/veg3-pills.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/33BSzmdB69DmZJMHGWf5YD/b546faba88ec54a954c255831a51a246/veg3_free-gift.png?fl=progressive",
  },
  VEG3PROMO: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/yVOGFH39I7nlrQscREW1r/d9df6a9e0ffbd3acb48368a5c4ea815d/label-vegan.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4pLyT2ru933iuLvglnKbYO/914fabb9192492b02826b55e7c98c73e/veg3.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4pLyT2ru933iuLvglnKbYO/914fabb9192492b02826b55e7c98c73e/veg3.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2TNS3VIGfDt6SY8z26q7lU/cc84ee9e080261b2a1e59b12e9842369/veg3-pills.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2TNS3VIGfDt6SY8z26q7lU/cc84ee9e080261b2a1e59b12e9842369/veg3-pills.png?fl=progressive",
    single:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7awk7ud0weCcDsxquJzWX8/d4d1f8c0c1e677b3ad5e5df6ae9348af/promo-vegan.png?fl=progressive",
    kit: "https://images.ctfassets.net/0rbfqd9c4jdo/26fCJ495nQzVsGZx4Ey3Uq/ca95fe4b321429767c5953295e737957/promo-hairkit-vegan.png?fl=progressive",
  },
  VEG6: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/yVOGFH39I7nlrQscREW1r/d9df6a9e0ffbd3acb48368a5c4ea815d/label-vegan.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/35nCwbVT3boko0I2AEX5su/5bb4cc88097f4bf37d3659c05cb5d71f/veg6.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/35nCwbVT3boko0I2AEX5su/5bb4cc88097f4bf37d3659c05cb5d71f/veg6.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5aecCZkQES40zHZ1ZUSKTZ/d26917a5b816de28c44a35e8f93b4a7c/veg6-pills.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5aecCZkQES40zHZ1ZUSKTZ/d26917a5b816de28c44a35e8f93b4a7c/veg6-pills.png?fl=progressive",
  },
  VEG6PROMO: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/yVOGFH39I7nlrQscREW1r/d9df6a9e0ffbd3acb48368a5c4ea815d/label-vegan.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/35nCwbVT3boko0I2AEX5su/5bb4cc88097f4bf37d3659c05cb5d71f/veg6.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/35nCwbVT3boko0I2AEX5su/5bb4cc88097f4bf37d3659c05cb5d71f/veg6.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5aecCZkQES40zHZ1ZUSKTZ/d26917a5b816de28c44a35e8f93b4a7c/veg6-pills.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5aecCZkQES40zHZ1ZUSKTZ/d26917a5b816de28c44a35e8f93b4a7c/veg6-pills.png?fl=progressive",
  },
  VEGFHK3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/yVOGFH39I7nlrQscREW1r/d9df6a9e0ffbd3acb48368a5c4ea815d/label-vegan.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3gNEuRBG4W6ouJvIQK248u/c487f6b3874a7a7ee3ee18bab58534e0/vegan-fghk.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1INT9rj25soBxnTZ1HzZzq/29226ba2a2a930334cbf75e7967c4691/product-vegan-fullest-hair-kit-pills.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/7M7YEI3QaGDHiY2sqi9I3E/53a0a063971c68d975e2c19fe49bb301/fhk-vegan-gift.png?fl=progressive",
  },
  VEGB1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/yVOGFH39I7nlrQscREW1r/d9df6a9e0ffbd3acb48368a5c4ea815d/label-vegan.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/55Lsda1jHaIef4pEPhyNUJ/d4f7718244bc205391d5d0de6c135119/vegb1-1.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4NZz74n3ojdYDpKpOtkU7H/f8e8be9e5dfdb1e064af85e3b2e19f2c/vegb1-2.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1uYaa6ocNGm2OYIdueIMWu/44bbb66464192151666668f8cb8d69bc/vegb1-3.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/485V6yMf97WhcAabaFbZ75/32c4f2597a3e9a3027557e03cf0012a5/vegb1-4.png?fl=progressive",
  },
  VEGB3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/yVOGFH39I7nlrQscREW1r/d9df6a9e0ffbd3acb48368a5c4ea815d/label-vegan.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6mlwT0EHze7mYMmPlnclAf/3684ee0c18dcda81a88f3227265e0f97/vegb3-1.png?fl=progressive",
  },
  VEGI1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/yVOGFH39I7nlrQscREW1r/d9df6a9e0ffbd3acb48368a5c4ea815d/label-vegan.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3YVs1aCZsNz4muldZXoTPC/caee75e8cb79edf598b228ae882e4ea5/vegan-collagen.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/Di8IxDGTMr4cOGaWPbrkf/bb1ed33817df5e661bcc44d3d475d848/vegan-collagen-pills1.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6OyXkE62zQuQBAfoagvjuY/fa14e0ff06058467902c216ee579da4d/vegan-collagen-pills2.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/35cFjWkEnLWdaRWesGGkf6/2b8fa85b596cd3a4c0407e109f15a6b7/vegan-collagen-pills-both.png?fl=progressive",
  },
  VEGS1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/yVOGFH39I7nlrQscREW1r/d9df6a9e0ffbd3acb48368a5c4ea815d/label-vegan.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3PUvxgNBXf7tHE6Icttino/6c9cb8de28adc7592c9448a3e686cb33/vegan-stress.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3dYVnReajsLk9zxnll1R1j/1f90746f634e0634586fdde4f8bcce5c/vegan-stress-pills1.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3BionC2TAZz78tSpCoklDo/c232f9da6982f3c5a51c17f3f3f096ad/vegan-stress-pills2.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5uburraicA5Lnd7mh2Ww25/4c1fa6145200fa2d15d2297211f502e3/vegan-stress-pills-both.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/55qGTn9XwIw3Kpmqpx7XM4/ed3f9098f135fc6d8ee06a93dd151b6d/stress-veg-gift.png?fl=progressive",
  },
  VEGH1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/yVOGFH39I7nlrQscREW1r/d9df6a9e0ffbd3acb48368a5c4ea815d/label-vegan.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1lvdvWeMlcv6olrd6Che9q/e2e835d7c9e8b7b8a1f3a8a5587d48db/vegan-hairbiotic.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/16k0JuyG9kdh1QRTdeo6r4/132a420bf9d3b8dfdd25332f315a5d47/vegan-hairbiotic-pills1.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7mSYchTjOkn9jTBGrx0BOJ/9b2f590b85b6b8f7a587a503fb2d7e72/vegan-hairbiotic-pills2.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/54JY3PoU1vNjoY8pqAXhaT/ee082478cf4739bb640b5a5c58a73b0e/vegan-hairbiotic-pills-both.png?fl=progressive",
    gift: "https://images.ctfassets.net/0rbfqd9c4jdo/2IacIFJ33sezV3DD1NzKbo/1a2b033780164a54cecac8a83c43219a/vegan-hairbiotic-gift.png?fl=progressive",
  },
  VEGM1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/yVOGFH39I7nlrQscREW1r/d9df6a9e0ffbd3acb48368a5c4ea815d/label-vegan.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6MOsdTKPLNLQctSQqL6lRl/69c555dfed0a1668e6d319f1b95eda8d/vegan-hormone.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/C6gv6jrkcpJZOe1SblZqV/4d18f9680ba38719bbcca151eb8c5607/vegan-hormone-pills1.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2tRDBLdgbBdumqqeDwkxAI/32b988b187b70eede7a2a05c8d815d14/vegan-hormone-pills2.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2nyyTY4T6xsImHQO4vGasM/20f7efa86c0666d367cd21400e03e87c/vegan-hormone-pills-both.png?fl=progressive",
  },
  VEGT1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/yVOGFH39I7nlrQscREW1r/d9df6a9e0ffbd3acb48368a5c4ea815d/label-vegan.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7kry0RNpEMWYWHWZ2atXIp/862514533772b1340ece0d671aa56ef6/vegan-toxin.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7MOtHxrmNr2Xa3gikRrpCf/8b817a5adedf7cfa60b749ec3a4fc6b8/vegan-toxin-pills1.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1uNbdOpBmCGPfYVVfrKqns/d8be27f32a5fd21e2a2b21e6a4f67642/vegan-toxin-pills2.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1zvglsoYtA9OrzqNZDDURs/f01c3e905e50eb6d1de9b931a280a5e1/vegan-toxin-pills-both.png?fl=progressive",
  },
  BAL6SCTRAVELPROMO: {
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3H7WFKiSdsPBkvEzOmcvro/981affd0507ee9d919c4ab29403ab45d/BALSCTRAVEL6PROMO.png?fl=progressive",
  },
  MEN6SCTRAVELPROMO: {
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1PGf7MoLKPPjYfxBRldFgs/a3cd274afcdc17491f6b6e83d0ae8943/MENSCTRAVEL6PROMO.png?fl=progressive",
  },
  POS6SCTRAVELPROMO: {
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7wfPSrxmQ1JzSDBfrzzJWe/9aed750a29f11903f2382483a8f24904/POSSCTRAVEL6PROMO.png?fl=progressive",
  },
  VEG6SCTRAVELPROMO: {
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6UHGZ3cnDnoI57EqnTMUwA/2875158977252f9e079255b53b981bb8/VEGSCTRAVEL6PROMO.png?fl=progressive",
  },
  WOM6SCTRAVELPROMO: {
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/inEkqXwi6msEPoktcmPte/163123fd98e5c555aa2d8f23578fb7d5/WOMSCTRAVEL6PROMO.png?fl=progressive",
  },
  SCALPSCTRAVEL_PROMO: {
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4MImsLxKB09AbbXOkrMJi4/fc10b5458988965525f3a4bfe96bbdda/free-two-items.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/42C9EvXGhIqqY8HXoFroBZ/ba93ad4299e67f8cbd8a063359560ec6/SCALPSCTRAVEL.png?fl=progressive",
  },
  SKIN1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/20gZofbMylOOheWcFBnsaz/1170675cc6e71f1099e8026cb4aee609/nutrafol_skin_supplement_label.png?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7hjb2zrgbd4RvtlNWrCl9K/a4e604a141d363639fb47077bb87f5ce/SKIN1_1.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/DGFd6iyouc8rRO33iupSW/6d4ac2624224892162cc3bd33466f2ab/SKIN1_2.png?fl=progressive",
    alt2: "Clear Skin Nutraceutical bottle with four pills in front.",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/32iZ4ig8h1gJM7xHcGR1bg/ee2dc517bfc54d5b3c4f12c7d20a0821/SKIN1_3.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2dElCLVWQ28yn111DgEQr4/0276363bf213820ef6907b4da0a200e6/SKIN1_4.png?fl=progressive",
  },
  SKIN3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/20gZofbMylOOheWcFBnsaz/1170675cc6e71f1099e8026cb4aee609/nutrafol_skin_supplement_label.png?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7a8G8YHHxMmVebjROKQ7t0/543a037dced011acd5508ba72d50382f/SKIN3_1.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1SChcbHfg8gnPY810uUQjX/04360e6996e01979d6759d605bbb4d8f/SKIN3_2.png?fl=progressive",
    alt2: "Clear Skin Nutraceutical bottle with two pouches and four pills in front.",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4mxncFJA4aUNuPqcH3aM7Y/0b5b9a8d67b1ae0632a7d444cd565c5b/SKIN3_3.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1pGOGo9zGVFA2bfij6Hw0Z/eba6fe4cb4b79c9f47e189218ecd7b40/SKIN3_4.png?fl=progressive",
  },
  HAIRSERUMSML: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5riNaThhDLnB4dAQvbLyBw/06376ba6f90b4b1679eec95c8c467947/Hair-Serum-ingred-list-cropped.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4TX6M1V6B5C705wKxvw7Tx/4454569ed7ca6c582d9813e8cafcd882/HAIRSERUMSML-01.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1H9q7cQULAA3YJt9mGAhkx/9716e7170bc2491df2799821122e4267/HAIRSERUMSML-03.png?fl=progressive",
  },
  MHAIRSERUMSML: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6W2qkWX8KXxuPcnrF0wDfQ/1a5519cde9fa4e249dfe55bfd6137984/label-ga-men-text.png?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/lSlOwd8r5CsL2tzQpzIFQ/63add9a8234db08d90d46754d17fd7eb/MHAIRSERUMSML-01.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1X0Dn21pDlKfkagCzgqSEz/651153cb3f10c14edd8f939d5899ec8b/MHAIRSERUMSML-03.png?fl=progressive",
  },
  ACTIVECLEANSE: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4TXfuiun9x1vyGm563xORZ/7987a1d6b2e2c729c194eaf5c8c1e17e/active-cleanse-label.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/sRdofIvMwzu9Q8lZxcG3F/2a7510730defdfdae98ba157181f624a/men-active-cleanse.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3UnkKb1AlRqZ91kNRvmmTt/ffc488d942ff3d6dc1fae5f71a39f4d2/men-active-cleanse-alt.png?fl=progressive",
  },
  MENX1: {
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3uVGM046EzFUF1UGnAQOmM/c3a690ca1d612f58e9d3df24e89a10f8/MENX1_1.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/60lnCOGbjEJl1V50F1df1Y/e365222218ad3abf1c8b31c84154010f/MENX1_2.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/MRKNnOkLEiaH12TGDy5Au/90478f26a390a4e59c268cc439d3d963/MENX1_3.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4d25MuKkN20eYgCzuYbf5O/7080cc37f88a634e9f5c2e348c354305/MENX1_4.png?fl=progressive",
    label5:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3Y7U8SQeQAspNsEEz7ExQQ/65e4f771f5588b302e5cee6cc3eebaaa/MENX1_5.png?fl=progressive",
  },
  MENGSML3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1HE1kd9gxfSQ2ix3Ld4lF1/4fabef6ffd6b2dd01cda61ceee235927/label-men.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6LsQJouiONFmt0znRYo7Ch/2032d370e892c1bf75424aacc4475c53/MENGSML3-01.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/YMgoCWk2zXbKREGfK64sY/e45068a2c0baa5ae81303b87a49da55a/MENGSML3-02.png?fl=progressive",
  },
  WOMGSML3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7yfNjVTUjItgJqZL4l7VAc/1b260d9c6b38cc195488f3d0dc355127/WOMGSML3-01.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7FsyuZy5Xm1IS1Xu3SWRFT/249f99c033f080a2ef163c3323805bdd/WOMGSML3-02.png?fl=progressive",
  },
  BALGSML3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/43bYh5dapRAYC6nlET2e4q/6397d1422cb77c0e4e3e2f26eb03b86a/BALGSML3-01.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2Q421M65V6ejJHbp7MD37X/9a4af1746f237b75c2ac2a57d50b9b53/BALGSML3-02.png?fl=progressive",
  },
  POSGSML3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/27OS8msL4TPAlckNhsaVDY/5f244625e9d9effd2bfa87f62e2425f4/label-postpartum.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4V0LfUNeudUdKACuUblvvU/5ed95deacc7cd69286b244d75b6c3fef/POSGSML3-01.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3lnhOXh3645NmVklFrTUBD/fe63d607bacc0dd45629296be25f96f8/POSGSML3-02.png?fl=progressive",
  },
  VEGGSML3: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/yVOGFH39I7nlrQscREW1r/d9df6a9e0ffbd3acb48368a5c4ea815d/label-vegan.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5gMw2JMFYlJj1bbcJHyA5w/86cc1bc47323c863e676d3802198c4ac/VEGGSML3-01.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1Fb15hWSdm4BLVBrNhuMfa/448e01e04fa247bb21641ef8e8e54a4f/VEGGSML3-02.png?fl=progressive",
  },
  MENGSML1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6W2qkWX8KXxuPcnrF0wDfQ/1a5519cde9fa4e249dfe55bfd6137984/label-ga-men-text.png?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7y3HszgvtjBLaq1BainkFI/44134966c0707ab2f4ed8bcf228ebb09/MENGSML1-01.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6hKbJSUvoKtzL5l3Cnp2Kv/db293160145c93d37a3d118c1812a7ac/MENGSML1-02.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4CntHAIM17FQ3fAhTwkutr/04bafeb57e6582f731f0d342ceff09cc/MENGSML1-03.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6a6Bl85oe5laig3J5dU99W/39b604e1bd0ca6c5ee22a876a46e443e/MENGSML1-04.png?fl=progressive",
    label5:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6cRR9cDX4nd7Fz4v7iWmCR/677e733890bd803fc8ff1f1577025d27/MENGSML1-05.png?fl=progressive",
  },
  MENDGSML1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6W2qkWX8KXxuPcnrF0wDfQ/1a5519cde9fa4e249dfe55bfd6137984/label-ga-men-text.png?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/kVUb3bfhZX2AOx08KeK0j/3582ee16b89bb760ee45307d4a38712b/MENDGSML1-01.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/kVUb3bfhZX2AOx08KeK0j/3582ee16b89bb760ee45307d4a38712b/MENDGSML1-01.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/kVUb3bfhZX2AOx08KeK0j/3582ee16b89bb760ee45307d4a38712b/MENDGSML1-01.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/kVUb3bfhZX2AOx08KeK0j/3582ee16b89bb760ee45307d4a38712b/MENDGSML1-01.png?fl=progressive",
  },
  WOMGSML1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6wg2nbw2PcNsmg9BQW3sYq/c2cf993a3775bb4c41ddc26d0b502e13/label-women.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5HixrpgFhGY3GW3YqaItNm/18069ea54259cd41ef8d58a94f361c45/WOMGSML1-01.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5fNm2rckoRwrsceDgnIIr2/0cf78ae405d3ae45f0451d266fa41936/WOMGSML1-02.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/69enFbym8df83NM4wFpiIN/88c58e4566ba36056203a1bfc80bc969/WOMGSML1-03.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/ToJwiuS2Ryb5EvUTVzUd2/0dc3400c1a2a3825a4fe4b6531e0776d/WOMGSML1-04.png?fl=progressive",
    label5:
      "https://images.ctfassets.net/0rbfqd9c4jdo/35FZesV2OnEC6sUndl9Kqq/093a62e42a643699c074b322d6b0a91c/WOM3_3.png?fl=progressive",
  },
  BALGSML1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4G63TsZKDi8eZaUhAbwHvu/81ee031de143a30ad77598ba60a0b9cb/label-balance.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5xowfvJk0EZHOPmqEHxoS5/190efd8d3cd11029cf98933572a3ceb0/BALGSML1-01.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6BZsABz9qAZCs38QwXNSV/d3b52ae2576721e15e9a65123e1784e7/BALGSML1-02.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/xa6R92RAVHWtaPnG3bG9E/66cc0edb275adde449696189a29b98b8/BALGSML1-03.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/50f12psRdCpwwvWOtwBm9A/5f3a5d3a2b03b2cb9ccc8b2c8a970a53/BALGSML1-04.png?fl=progressive",
    label5:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3XCN8Yn32ljuv4e2qtXdwJ/5107b3a6f9336a5d6edc3346dae4b415/BAL3_3.png?fl=progressive",
  },
  POSGSML1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/27OS8msL4TPAlckNhsaVDY/5f244625e9d9effd2bfa87f62e2425f4/label-postpartum.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6nCmbA5B9sRb8QoOYkDacG/cc11ad165c1a30632fb288df4c6ebdfc/POSGSML1-01.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/UPO8yBAmXl4IQfkUGVsyd/83d91d13f7de5eecc51581e7bae681d6/POSGSML1-02.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/nrR6fX8pIWvX6IQ4VNzgj/457e66da43548bb27f11cf7545a89030/POSGSML1-03.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/37eG3PzJ2RjJ0kQn9Hmo6b/aa113b4277574531438248cbba18e492/POSGSML1-04.png?fl=progressive",
    label5:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7LHbxYgcYggU9AVoVwD35F/b859f03b5fa96e83e65e6b554b950e41/POS3_3.png?fl=progressive",
  },
  VEGGSML1: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/yVOGFH39I7nlrQscREW1r/d9df6a9e0ffbd3acb48368a5c4ea815d/label-vegan.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/34o4F5FfflLBaoAK40Mgp1/cd93134fe2768744516bf9ee2f033532/VEGGSML1-01.png?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3ii44ZpmVyNSGWRXqwJm91/c08da8ae5bf31ec6114767380c5513af/VEGGSML1-02.png?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5tlB2K8GIl8brR5Zv6Lx12/47276522f64401798245642bb8bf1bc6/VEGGSML1-03.png?fl=progressive",
    label4:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5paH2pgP7LRwC2bR84lPRI/aa8c73ec5230236ea1ceed8f617eb8e7/VEGGSML1-04.png?fl=progressive",
    label5:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2TNS3VIGfDt6SY8z26q7lU/cc84ee9e080261b2a1e59b12e9842369/veg3-pills.png?fl=progressive",
  },
  SCALPEKGSML: {
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3vQzWKeK2779w07tmcaFOH/a65ba17a50805fea63424724cf8ac9b9/SCALPEKGSML_1.png",
  },
  SCALPSCGSML: {
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5QeF5JhCrMtcfObQjV91vC/fcb058fe3484ab72f7263ddbabc5c740/SCALPSCGSML_1.png",
  },
  MSCALPXGSML: {
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/kssRsxuNUx6jRySDcQ4xC/59fa367273b1d3aeef2447b542711633/serum-2inone-bundle-men.png?fl=progressive",
  },
}

export const INGREDIENTS_IMAGES = {
  AFRICAN_CHERRY: `https://images.ctfassets.net/0rbfqd9c4jdo/4W3Po3qClKKKSz06s8Ri9I/751316de2a74610f9452b5457377bc79/ing-african-cherry.png?fl=progressive`,
  AFRICAN_CHERRY_TREE: `https://images.ctfassets.net/0rbfqd9c4jdo/7Hi0N1Uuut2I2QQQX8VAYK/d90c86f9d4f6412ce3851f07c085f682/ing-african-cherry-tree-bark.png?fl=progressive`,
  AHA_FRUIT2: `https://images.ctfassets.net/0rbfqd9c4jdo/2njLtbFEQ6Ck41XoHkIjwx/2f9eb39e1ba024ead05bbbd906d55f2f/ing-aha-fruit-sugars-2.png?fl=progressive`,
  AHA_FRUIT: `https://images.ctfassets.net/0rbfqd9c4jdo/36joWDbIRFOEx665GKaukT/0bc395705b4088cd9b6df1f0e00c1373/ing-aha-fruit-sugars.png?fl=progressive`,
  APPLE: `https://images.ctfassets.net/0rbfqd9c4jdo/2J0QYlx8vc9fCfR05O1GlO/f48739d8019a06c1d4363b335c35ef0e/ing-apple.png?fl=progressive`,
  ARTICHOKE: `https://images.ctfassets.net/0rbfqd9c4jdo/76N10MzwA2lr7dNjCGFrXq/fc6cf6fc6120916acf6d79962ad09782/ing-artichoke-leaf.png?fl=progressive`,
  ASHWAGANDHA: `https://images.ctfassets.net/0rbfqd9c4jdo/1SJAxyJ94j6DJbMSN4Ssqh/c74e8cb9bf0a9cc64ef7095b8314b139/ing-ashwagandha.png?fl=progressive`,
  ASPERGILLUS: `https://images.ctfassets.net/0rbfqd9c4jdo/4vqh50oKDXvwIuJQypVdJm/a613c821337761139e067b01c21b836a/ing-aspergillus.png?fl=progressive`,
  ASTAXANTHIN: `https://images.ctfassets.net/0rbfqd9c4jdo/2q4jUgVLFEnk0ZXJq50Efb/78454e2aa1fcb366198eb764c817f4c7/ing-astaxanthin.png?fl=progressive`,
  BACILLUS: `https://images.ctfassets.net/0rbfqd9c4jdo/7sMjeJpDy1yMBZCF8IKbP0/f9eb7401dcf2b20651be898c459ce296/Ingredient_3DBacillus_ferment.png?fl=progressive`,
  BACTERIOFAGE: `https://images.ctfassets.net/0rbfqd9c4jdo/3a5emOWCUElmhzVKjtOriS/0b87e17d3a05595824c7c489d8ec7a58/ing-bacteriophage_prebiotic.png?fl=progressive`,
  BAMBOO: `https://images.ctfassets.net/0rbfqd9c4jdo/2sDUXZrSXLcEab02Gw5x8s/235c753ee868e4e6fc04ceac7e8d479f/1px.png?fl=progressive`,
  BEET_ROOT: `https://images.ctfassets.net/0rbfqd9c4jdo/2sDUXZrSXLcEab02Gw5x8s/235c753ee868e4e6fc04ceac7e8d479f/1px.png?fl=progressive`,
  BIO_SURFACTANT: `https://images.ctfassets.net/0rbfqd9c4jdo/2X8rCcwmdMn8eZHoAgbGXS/3c5123714ff7a23114dfc0dec48ded38/ing-biosurfactant.png?fl=progressive`,
  B_COMPLEX: `https://images.ctfassets.net/0rbfqd9c4jdo/2jKw3Sv1srgWSfqGEsfCDA/9c49fd62f88513ec4e987ba6d462ada9/ing-collagen-osa.png?fl=progressive`,
  CHIA: `https://images.ctfassets.net/0rbfqd9c4jdo/6jqaPs5TLwlhQNEprDnmyI/5577c89eeb69d1473a5c5d58712b9bff/ing-chia.png?fl=progressive`,
  CHORELLA: `https://images.ctfassets.net/0rbfqd9c4jdo/2sDUXZrSXLcEab02Gw5x8s/235c753ee868e4e6fc04ceac7e8d479f/1px.png?fl=progressive`,
  CHROMIUM: `https://images.ctfassets.net/0rbfqd9c4jdo/4KnqeHZ3ljjptzDYzRPvdz/855d932c83a486ba340a1c228f7c733c/ing-chromium.png?fl=progressive`,
  CITRUS: `https://images.ctfassets.net/0rbfqd9c4jdo/6garxLNJNRlxCOZOEZZbTz/f57334879f40913e7d4453c2f3b8a39b/ing-citrus-flower-acids.png?fl=progressive`,
  COFFEE: `https://images.ctfassets.net/0rbfqd9c4jdo/6dGPXbOYNwWdSz5eBDomgV/ed944720e4aa52c286887d23405e8490/ing-coffee-bean.png?fl=progressive`,
  COLLAGEN_OSA: `https://images.ctfassets.net/0rbfqd9c4jdo/2jKw3Sv1srgWSfqGEsfCDA/9c49fd62f88513ec4e987ba6d462ada9/ing-collagen-osa.png?fl=progressive`,
  CONEZYME_Q10: `https://images.ctfassets.net/0rbfqd9c4jdo/4V6q9AuMAxCxJVhgeIMPvB/dcf3cb3b3a8cca7936603b1b8717bac2/ing-coq10.png?fl=progressive`,
  CURCUMIN: `https://images.ctfassets.net/0rbfqd9c4jdo/ULfUMqQXDaivdswtRdImf/437e0904d465aca008014b1c2619e0a4/ing-curcumin.png?fl=progressive`,
  DRAGONHEAD: `https://images.ctfassets.net/0rbfqd9c4jdo/287JxDVwihrSf7fRXMw7Kd/4e8ae3dca1b0190fa01cbee8409e54c9/Ingredient_3DDragonhead.png?fl=progressive`,
  GINSENG: `https://images.ctfassets.net/0rbfqd9c4jdo/6gij9kJtHkilSc954nhEgG/d74a33917e58f0bc874ac744484f35ec/ing-ginseng.png?fl=progressive`,
  GLUCOSE: `https://images.ctfassets.net/0rbfqd9c4jdo/5Q7sSgAs5lp3zT0NcCyqQt/acd72063ef707d15df6d2619f8c425c2/ing-glucose.png?fl=progressive`,
  GLUTATHIONE: `https://images.ctfassets.net/0rbfqd9c4jdo/3U0ELxev2ndaF84mvr2y55/c23405e004dc3648201b875a783c9604/ing-glutathione.png?fl=progressive`,
  GREEN_TEA: `https://images.ctfassets.net/0rbfqd9c4jdo/5eRGqy5MxO2vySNHOwuTPj/f0ba38d93725f0f240f08409e9563386/ing-green-tea.png?fl=progressive`,
  HONOKIOL: `https://images.ctfassets.net/0rbfqd9c4jdo/3G1E1o0rb389ftaBdNsljS/e65a2e7d1ceb91cd8e0a3611453f0bdc/ing-honokiol.png?fl=progressive`,
  HORSETAIL: `https://images.ctfassets.net/0rbfqd9c4jdo/69ZSDnF7dhDjKPsMuOfnkB/3ccda64d9375ea18baf05db0f12b20c6/ing-horsetail.png?fl=progressive`,
  JOJOBA: `https://images.ctfassets.net/0rbfqd9c4jdo/aPRPhj61DcRIbXYEnQQKU/0ab9b321e275020009200b294c18b442/ing-jojoba-ester.png?fl=progressive`,
  KELP: `https://images.ctfassets.net/0rbfqd9c4jdo/6mlVjQKorNdaeHBC6GFvdy/05a84b0783ca3b89b41821991ebbdcf8/ing-kelp.png?fl=progressive`,
  KERATIN: `https://images.ctfassets.net/0rbfqd9c4jdo/5kv8Gc7NCstlCVMsNGA28e/3f62b6e11b269bcb7304d5c4b5d1aaa8/ing-keratin.png?fl=progressive`,
  L_REUTERI: `https://images.ctfassets.net/0rbfqd9c4jdo/175CgGGz9mOVZlV9esFsLi/4ab723041ad4264ab6b914fb2dae9bf3/ing-l_reuteri.png?fl=progressive`,
  MACA: `https://images.ctfassets.net/0rbfqd9c4jdo/7J1uqNgE7txUUEBlEcSwJp/4a97e14d1a33280a23e8849ced8a2fd1/ing-maca.png?fl=progressive`,
  MARINE_COLLAGEN: `https://images.ctfassets.net/0rbfqd9c4jdo/2j5DcFvOR3toH81b2hH9CJ/00256e9c72cf3381a5c746905d1bb385/ing-marine-collagen.png?fl=progressive`,
  MILK_THISTLE: `https://images.ctfassets.net/0rbfqd9c4jdo/26zSmYiDwJgh6b9PJlFFTG/ebd638264cab83ff9d38081bb50db8af/ing-milk-thistle.png?fl=progressive`,
  MOSS: `https://images.ctfassets.net/0rbfqd9c4jdo/379Ir0jC6H56pEV4ocB1cy/bcd69e81f7f3c07010c1e5c46f7b5cc7/ing-moss.png?fl=progressive`,
  MSM: `https://images.ctfassets.net/0rbfqd9c4jdo/4tj6Sg9dXcfHS1oYRsXlXI/99905f63c521d090683f61f52e1fa640/ing-msm.png?fl=progressive`,
  MYOINOSITOL: `https://images.ctfassets.net/0rbfqd9c4jdo/1w6F43mOyepAl9JCUOcSLX/1acab5ca328d6c5e07c8692aec413676/ing-myoinositol.png?fl=progressive`,
  NETTLE: `https://images.ctfassets.net/0rbfqd9c4jdo/3HsvTtSTyqQlSzfVxz12NU/105a106a15b7c695e2aa5c78179b3703/ing-nettle.png?fl=progressive`,
  OMEGAS: `https://images.ctfassets.net/0rbfqd9c4jdo/71RlghzLRtrETudjNapQsl/5b21ed567fb3c281320805db0b646627/ing-omegas.png?fl=progressive`,
  PEACH_GUM: `https://images.ctfassets.net/0rbfqd9c4jdo/5f1wag41mnqbsObxwBupHp/07d9c011f07b12dace4390a5a86fabf7/ing-peach-gum-prickly-pear.png?fl=progressive`,
  PEA_SPROUT: `https://images.ctfassets.net/0rbfqd9c4jdo/4S3oYglGT2lMj21WciL3xS/4a6c551957ca31d6fffc3cac0860e3e9/ing-pea-sprout.png?fl=progressive`,
  POSTBIOTIC: `https://images.ctfassets.net/0rbfqd9c4jdo/2sLcyGY1Vxk43grNlXiRiQ/d59f8ca4fbcf78046a925bf90b889995/ing-postbiotic.png?fl=progressive`,
  PREBIOTIC: `https://images.ctfassets.net/0rbfqd9c4jdo/6Q8SEWCSUvMbDhv4CfX6SX/32ab091cd55cef8223bb5453f08901a4/ing-shampoo-prebiotic.png?fl=progressive`,
  PUMPKIN: `https://images.ctfassets.net/0rbfqd9c4jdo/1SMFTX56hvs1IDfwHNTerc/e5efc24ec6fe02b8d8120de4fc7923ab/ing-pumpkin-seed.png?fl=progressive`,
  REISHI: `https://images.ctfassets.net/0rbfqd9c4jdo/3KSRhpDJvtBFVUHCey2OmW/a67c605a3b01dd2d0efcb1d6fe781802/ing-reishi-mushroom.png?fl=progressive`,
  RESVERATROL: `https://images.ctfassets.net/0rbfqd9c4jdo/69b8su4mDSh7uxUqdwCDVZ/8a82e9fa68c49c2c6f62039c4c7ca450/ing-resveratrol.png?fl=progressive`,
  RHODIOLA: `https://images.ctfassets.net/0rbfqd9c4jdo/56rLeH6JbIcWwVq76ywAjQ/c7bfbd1bf135af15814a2f9dd4bef460/ing-rhodiola.png?fl=progressive`,
  SAW_PALMETTO: `https://images.ctfassets.net/0rbfqd9c4jdo/25HodgHbM9altc4LhdgRy3/d793aef9e8eaae46f0c91a6900e25b85/ing-saw-palmetto.png?fl=progressive`,
  SEA_BUCKTHORN: `https://images.ctfassets.net/0rbfqd9c4jdo/2uJuPS33CLFKEGZvuA9aX2/c20b619809e7e6de5c63a5b0a0bdd2f3/ing-sea-buckthorn.png?fl=progressive`,
  SHATAVARI: `https://images.ctfassets.net/0rbfqd9c4jdo/1UZtl5JcO58XvbizowB3MJ/ab81e565dcbb44e1d60d17cc433f98b1/ing-shatavari.png?fl=progressive`,
  SCHISANDRA: `https://images.ctfassets.net/0rbfqd9c4jdo/1FaWc9bk1NqfHoYyBl5Tje/83dc8c71c9af7e592368439f97192eed/ing-schisandra.png?fl=progressive`,
  SMITHSONITE: `https://images.ctfassets.net/0rbfqd9c4jdo/7GKaywbmJtgtGnpPZLLA93/d571e585d2292f3480da291259952f5f/ing-smithsonite.png?fl=progressive`,
  THEANINE: `https://images.ctfassets.net/0rbfqd9c4jdo/70VDAEqA6NlW70rIRilVwm/ca353c6fa762ae4bea81e55262a556c8/ing-theanine.png?fl=progressive`,
  TOCOTRIENOL: `https://images.ctfassets.net/0rbfqd9c4jdo/6zLEqMMEHQPrJnAPTFLMJx/79fd12a83804a06fe5c2ce13aeb71168/ing-tocotrienol-complex.png?fl=progressive`,
  VEGAN_PROTEIN: `https://images.ctfassets.net/0rbfqd9c4jdo/IwUpszVIn2oV63uwtyJFr/93703f27dc61b784b37fdedec5ea681b/ing-vegan-protein.png?fl=progressive`,
  WILD_BERRIES: `https://images.ctfassets.net/0rbfqd9c4jdo/5rZoQ6lVVzk4TSPtJounQJ/582a986fcbc55ccc242024ac8eb061bb/ing-wild-berries.png?fl=progressive`,
  XIMENIA: `https://images.ctfassets.net/0rbfqd9c4jdo/3zysAuEvdywmNCR1mmEux8/c5f070ff4d17a440e1b39207148efa71/ing-ximenia.png?fl=progressive`,
  YELLOW_DOCK: `https://images.ctfassets.net/0rbfqd9c4jdo/7tMMM5sfZZk7c3X8r4tZOV/ee67f8fdfed48a167fcf2c3c1effb932/ing-yellow-dock.png?fl=progressive`,
}

export const INGREDIENTS_IMAGES_NOVA = {
  SENSORIL_ASHWAGANDA: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4fPqRSds0uve2nbg39msiG/f51437279366ce38ac49036a0071a8c6/sensoril-ashwagandha_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2H92fWyhykbNpg7nRaPs3F/af2def99a2cc5bf03b215a15082be6ea/sensoril-ashwagandha_3-2.jpg?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7GIm6SHB8STHrCoFeOz9V9/dd774ee30c5efb73e4070d05fd9428e5/sensoril-ashwagandha-powder_1-1.jpg?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5BMUkYMovIvklxu8R1133P/053ce9d73b78bfc07cdea233602411ee/sensoril-ashwagandha-powder_3-2.jpg?fl=progressive",
  },
  CURCUMIN: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1xBaXEJQzZXDRvJbsp9Ylh/aa1171c741c49cc176e87f4088f92d68/curcumin_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2Zqqvgp6RxnH2CVxTe8LKV/5bde6483bf80713e0084dd5d2ec0cb24/curcumin_3-2.jpg?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1CiBKdqBf1MvpP8W5ddVY0/45b6fcf043debb66b3795d2bfeb9685c/curcumin-powder_1-1.jpg?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/ZgUfrOyxXfh57KNn1Bi81/b083e356ace2adf1b03929f954ea0cac/curcumin-powder_3-2.jpg?fl=progressive",
  },
  SAW_PALMETTO: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3F1eO7GCXWebAoZLLkTr9S/97e006f155d61296f6e1cb04d420581f/saw-palmetto_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6SEdI8xfk61qUxcmSVVrdo/a307318a99c1c74d6d552bbf4615ca83/saw-palmetto_3-2.jpg?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3PGcgc1RmD2S8tHGRN8dkQ/6ea85fa074f3a8c5da9192d776a0a61f/saw-palmetto-powder_1-1.jpg?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/SyzlDo8De9yHu6GNoA2Or/e34131270ec675c7370711e761294f22/saw-palmetto-powder_3-2.jpg?fl=progressive",
  },
  APPLE_POLYPHENOLS: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7bDMyQBgf4j3hbWmQsLOGS/73b13b142462c27909467badd90075a1/apple-polyphenols_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/rbBKeVnYzDQORiPR4ff7d/a265846704388f7a276e3a98c26bae40/apple-polyphenols_3-2.jpg?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/54XSXZtP0m1RBLB7yGenbw/fd2664d1af72bfc7c657f0fbde6fdc09/apple-polyphenols-powder_1-1.jpg?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7JUyFuuUloSYXENUEvGiv2/267d6afa290b95356a631b948114c460/apple-polyphenols-powder_3-2.jpg?fl=progressive",
  },
  STINGING_NETTLE: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/66EsuHKqVR4Bq6y9vgzaT0/dfe916e456d25ac01029b2dcbb3d337e/stinging-nettle_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1Wjjgt8H1iFgjA3DThrnbX/af2a98ba4b32f96e54c70a9822718cb3/stinging-nettle_3-2.jpg?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2nlvtzYAjUn3TXPoWmXT29/9a927f540d91b8d2ce7993249e067812/stinging-nettle-powder_1-1.jpg?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1Lf6q01R93cTUCoKiOHwTq/851479206c77ff74ebd765d084f5e631/stinging-nettle-powder_3-2.jpg?fl=progressive",
  },
  TOCOTRIENOL_COMPLEX: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4Bhda9ptFOBCg9TTiYZdBN/d992e18707cc42266f91db185f0c1c93/tocotrienol-complex_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4pkgkqQyyWo2XWtPQwO3np/7a88430f3eec5d5e385494b61e45f176/tocotrienol-complex_3-2.jpg?fl=progressive",
  },
  MARINE_COLLAGEN_PEPTIDES: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2aiEhOGqtEZgpDuAh7scmG/3e11ea23dd244ff862a92dc5af878106/marine-collagen-peptides_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6STDXZlZC4kp59F82oDwIs/dda42f57a086f950d219c6efa7b600c8/marine-collagen-peptides_3-2.jpg?fl=progressive",
  },
  MACA: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4k9pRZICZyawJHS0bs1gNJ/5b0a54427dccf37616efddd3b321366f/maca_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5V9PKcCwZtZlIKdIJbPt41/7447bbb2e1cc844d6baa499d23c6d15e/maca_3-2.jpg?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/pKPxqf81U68UQ8OecYDHW/26fee3f5315eaa4ee99059055894aafd/maca-powder_1-1.jpg?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3hHyOueUenzRZC1jnWgswC/4a83d597a4d647fe56c7ded539e352d0/maca-powder_3-2.jpg?fl=progressive",
  },
  SHATAVARI: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2YXSDFWfqbIy3xZylJKdT2/cdf8cd3d4fb889034ad861e0ce0ef140/shatavari_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1pq67XuSsg58ixXBHG6xvd/60988b23e31e11b7c3138ea68e104477/shatavari_3-2.jpg?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1nhBBmXqVSKqB98BZlqTam/3174976463fd15f03b70c635c59aedb9/shatavari-powder_1-1.jpg?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4LsNXQfFmIAvSahwBeVaNg/ef129428f55581d71e921c61341b4495/shatavari-powder_3-2.jpg?fl=progressive",
  },
  DHA_OMEGA: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5u1X5c2f55faAyN3g59bNl/064c19e2cf73c0af3aef6f5f2442c670/dha-omega-3s_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6GLv1KxC0Y3rmd3joUokRu/e1dad94c0904a9729269a09362e5f8d1/dha-omega-3s_3-2.jpg?fl=progressive",
  },
  ASTAXANTHIN: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4NpTIupw2YtKTpecelWst/75e1c945b9f838d6c06ead77dd81b67a/astaxanthin_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/9jmjtsviXtifbJC5U6wZW/1880120bb35a4c6764d605063729c44f/astaxanthin_3-2.jpg?fl=progressive",
  },
  COLLAGEN_OSA: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4ayu7jzqegU2AMFAjCy7Yu/c49cf33b6ca3e95926cce59acb2f3f11/collagen-osa_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/Ry60dPDNVOsewPDOqTJ0k/0c9e8085e2cffa429c32518e7924c0e4/collagen-osa_3-2.jpg?fl=progressive",
  },
  THEANINE: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6eYO2C2Q4f09CgyxLHzowM/8cbaccc0c1e9fef9d624716f3fc35d87/theanine_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5ieujXJv3yIdJsryqGb5Sk/c9715b6c7b3126130b6e99651d9c8cde/theanine_3-2.jpg?fl=progressive",
  },
  KELP_MINERALS: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5otZJBKR1Bl1yzYOOUfX5F/f920f22531dfb2b1d77863a3a7d0c9a2/kelp-minerals_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6zDBNZA0Tjorfb34cRQCKO/3c5110841a6202334ad157d35a608e65/kelp-minerals_3-2.jpg?fl=progressive",
  },
  RESVERATROL: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/78xTZWJqGeIT7akQsazp59/7dd7ef98e941d1ea599a936b4f8b17f3/resveratrol_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5KoJj1zWTvqNq0f2KiLnuq/8fe229ea08721d5448476e582a2acaea/resveratrol_3-2.jpg?fl=progressive",
  },
  HORSETAIL: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6UhAKJV9mLyR3vpn52MP46/0da11215af5f14f3a4d447fe9387d64f/horsetail_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2dTPwhJnD703sqesDqIrk/562b76939f68c00e77f2b439a9a498d5/horsetail_3-2.jpg?fl=progressive",
  },
  KERATIN: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4dKKirRFGozj9wKf4D8QY/45470125a377fc0f1a6b55928eece108/keratin_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4nll5dJJcE7XYHkj1prCcd/7e2cbaa848a9f1a167bed491924f4cfd/keratin_3-2.jpg?fl=progressive",
  },
  REISHI: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/rwCWdcjDvvDd5TYRaXKJv/8dffa6eed6fca88e7184b9e407cd293e/reishi_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/26Zi41zVznF86hRSrWJTnm/aca7ac269669f66d819e28def45cddba/reishi_3-2.jpg?fl=progressive",
  },
  HONOKIOL: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1QSVXfnzDZ6gvrKzTneNWV/417aeccfcf2d65b4e1e1473871655536/honokiol_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/59Ews9lbv88n5JaLdJkAft/4103847daf6f6763b25f1d1826c7f8ef/honokiol_3-2.jpg?fl=progressive",
  },
  AFRICAN_CHERRY_TREE: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3BRNt1p23dIbyqHqZh6k7C/7c8ff2932e3975c278941b0975ca36bc/african-cherry-tree_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/WkY4C3ddd7ttEvpeRRPLP/8741ef2ce651842992a1455cc1626932/african-cherry-tree_3-2.jpg?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2LppjVWKx171K1jOWflAYM/fede7a3ffdd045b9279f1d5e55bd47b1/african-cherry-tree-powder_1-1.jpg?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3WkDpFVFAHzdrA5VlkoVzO/d719a0c4c020a1535392425ceebf0741/african-cherry-tree-powder_3-2.jpg?fl=progressive",
  },
  LACTOBACILLUS_REUTERI: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/46DM3BOumBWGyUkp6arQYC/5672abf5e13cf0186cb4cfde3907a98a/lactobacillus-reuteri_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7fm4ITxCdSMBNaUBMZZ2dK/fe79d5d39f3c136fe235bd242a9f0cd7/lactobacillus-reuteri_3-2.jpg?fl=progressive",
  },
  LACTOBACILLUS_HELVETICUS: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/49XcjnG0EPZIppiu3DDHXX/3ce1f577266a9d56ed309fa0b1f012c1/lactobacillus-helveticus_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6yVLmal06EJQjF1X3GJbGL/1a5736016e14b4311e469a7a5fcec2a2/lactobacillus-helveticus_3-2.jpg?fl=progressive",
  },
  PREFORPRO_BACTERIOPHAGE_PREBIOTIC: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2ztSXuDpkTotbEPajVDsnN/df8790a0b8f3c86325e97ad168c1259c/preforpro-bacteriophage-prebiotic_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6lcQNIpMYbesBjJ6taMmJR/51985399e2db95fa85cd8541493a02c0/preforpro-bacteriophage-prebiotic_3-2.jpg?fl=progressive",
  },
  RHODIOLA: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5MYdRj4jNBzjNtV40mcsgy/53c0f0ce2a1bb0856ff501063e1e4046/rhodiola_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7c99DVCGiWWKUm6yKyVsYO/7a149594b5d28ae273a0d36fff5c9076/rhodiola_3-2.jpg?fl=progressive",
  },
  CITRUS_FLOWER_ACIDS: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5QUKUWDogxpRm1srpKjBl5/65cd22f2caf89eabdb5a98cfe1bd0fa3/citrus-flower-acids_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3WLyyOzq8YoGQgue5SkpR2/0a4b2ff3482552c090512d93dc4fd740/citrus-flower-acids_3-2.jpg?fl=progressive",
  },
  MILK_THISTLE: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/J5344AaOVABj0rGYFKWxK/7a7d028a42b0a4aecd316ac46acbfd54/milk-thistle_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/ULWlhwD5P3Y3b79HCyYOo/4cb14d7d1f3f1e2add2c7acaf4fc2b9e/milk-thistle_3-2.jpg?fl=progressive",
  },
  YELLOW_DOCK: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/UI4x8sOMsTssZn706VjRB/e2e322a803b614545b3f16184e50a034/yellow-dock_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3mjRaK4ZXu7ptkQJODnSJZ/d767d326414444845d82ca3144cec86c/yellow-dock_3-2.jpg?fl=progressive",
  },
  GREEN_COFFEE_BEAN: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6ZqhBmj8KpxF9UJ0x1ajsV/fe321fa0f54df0614ec8effa6dad6142/green-coffee-bean_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2zNO03SOBsXqtOJgjvzHfi/2a2c5a35f22962b4d1af0ae92f5a9e20/green-coffee-bean_3-2.jpg?fl=progressive",
  },
  METHYL_B12: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/15niaL2swrGCRuWXqQdnTl/a0289f0bf7248bd43b2bbcb8ba423dcc/methyl-b12_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/26X1gfq4dqtfCIsI2e1yB/1106ec5b82adfc7cd3c246dd50da183a/methyl-b12_3-2.jpg?fl=progressive",
  },
  METHYLFOLATE: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7wiOPrtNemmtRk8v3kIGlo/ef6b77d76194a679681ef062cedc5ada/methylfolate_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2IZx4aEg7TYefvrSFsFj8A/e4c324fd5992d81658cd87d2b2e309a1/methylfolate_3-2.jpg?fl=progressive",
  },
  IRISH_MOSS_PEPTIDES: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/59gErlYsBgXmqVBNVGrdek/64f28dad5fd60ad6d1f318ad27ab1d5b/irish-moss-peptides_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6fioKn2pEM5fG5plvEj4Bn/f0f9c2fd2c42a509e5a74684396916c4/irish-moss-peptides_3-2.jpg?fl=progressive",
  },
  PEA_SPROUT: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6hWHyELzhRnmgjkWHGSLA0/00de0d2228cc6fad48d601bd69f9fa79/pea-sprout_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2J184OUHqCLCJ1rVBVhg1C/2b507a05409673cf46968b3afebfb365/pea-sprout_3-2.jpg?fl=progressive",
  },
  MOLDAVIAN_DRAGONHEAD: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/uE5jnxi7YXeHodzRr5DJK/e1b9d53e1dc3006493499f2b8ef1189e/moldavian-dragonhead_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2vr5gzaSm0xY7PkpTAVMZo/fca548dfee7e6881b36cc16e42c3625b/moldavian-dragonhead_3-2.jpg?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3EK49Z3qo0Kjct4sHXIhC4/f951d8e928d5db209adeeaba587379f0/moldavian-dragonhead-powder_1-1.jpg?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3R9ce9emSOWfbfBUdzki7t/2f69ed77431245fc086d92ac06f35941/moldavian-dragonhead-powder_3-2.jpg?fl=progressive",
  },
  SEA_BUCKTHORN: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7IWVFN0G0uYbFQZkwhtINE/d05bedc866cbc1ce7a445a71c610155a/sea-buckthorn_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3Gb2vYnSRu9LFOuh61GsII/a2bfc6f99ff492fb53e51ed4758ca7c4/sea-buckthorn_3-2.jpg?fl=progressive",
  },
  BACILLUS_SUBTILIS1111_PROBIOTIC: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6XRfe3GaKVBtk9I29UWHKK/8cfb44f1df6e7a7693123637a6b8984e/bacillus-subtilis1111-probiotic_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1TvEKF1UVsmdHMaICyWmzj/55ffb72ba5b7d10331c1795d51e659b1/bacillus-subtilis1111-probiotic_3-2.jpg?fl=progressive",
  },
  B_COMPLEX: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3vs2BhEtUMxnqmosBbzCcz/1ef96341547123ea9a4c5b902a29a742/b-complex_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6xeERUmoWvTtCyR5lh0df0/428f096a2b6c1b478eeae56a1b8f1be0/b-complex_3-2.jpg?fl=progressive",
  },
  SCHISANDRA_BERRY: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5WsZUTEdEXw3YJRIdz4wUl/45286d71de5d73e2f010e7fda3cb3e4b/schisandra-berry_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1t1I7OQ9JQthFa4BbIjNUK/ee507e50d13347fbf418d8721456cff1/schisandra-berry_3-2.jpg?fl=progressive",
  },
  TERABIOME_BIOSURFACTANT: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4DapA8qmyTJOuV8Jhfe71z/ae8ba6c237785e4fef0b2f5a5b9479f0/terabiome-biosurfactant_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/DPe06NqTDx8QGsN1dlDXD/4447b461c0a18605adbdad35e3425b19/terabiome-biosurfactant_3-2.jpg?fl=progressive",
  },
  PREBIOTIC: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/j6TrchCjaRQcjeD3ymwgc/98063e4b177e7a0aeaaf0c21ccfe794b/prebiotic_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3MCi3Kpgek5FacaP9rmf2r/c0a6e950b120f14012817117785de25e/prebiotic_3-2.jpg?fl=progressive",
  },
  VEGAN_PROTEINS: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4lgvkeRhxCZLYaF60k1FWB/b0e3334daa288a768f3543d30688ef3c/vegan-proteins_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1unh0WsSuaQIrV6iz93oIL/539fa72c55fa52509bfcbf7d6f6df76a/vegan-proteins_3-2.jpg?fl=progressive",
  },
  QUINOA: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6Jjjkr80iEA9P0HIxxDIGy/664a1a1b9254713fec3872a4f7ee0a98/quinoa_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6zZiIxRqi5wZ2McuN5CMo6/d958b9c0e91dced7e6d6d7cf121a2e31/quinoa_3-2.jpg?fl=progressive",
  },
  JOJOBA_ESTERS: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2Ted4MoVEYcq9bn7JDzJbM/ab9ffee55e208bc8e80171281988fdcc/jojoba-esters_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5frw6duTTJJ8Wb07r8sZEQ/4d065752a33545f5083b8a4bf5c9b6ab/jojoba-esters_3-2.jpg?fl=progressive",
  },
  AHA_FRUIT_ACIDS: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5b96quxsOc5ut1JkcMkunx/5622146fc3b4bbcdffdab295719c5c41/aha-fruit-acids_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3aICw13zmI7r2ffbQc0jrW/560c7fe18bb15d3d2deec447c68a7244/aha-fruit-acids_3-2.jpg?fl=progressive",
  },
  AUSTRALIAN_WILD_BERRIES: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1iiTmxqf412A3pj4H4xmpY/6f785c0d693be835d48d5ebee4b9ac23/australian-wild-berries_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6zZWXHbcfLy8aaAn809EQZ/f870dc806d8746956083f6b85c9c7a33/australian-wild-berries_3-2.jpg?fl=progressive",
  },
  MSM: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5WS33qR7RrgyXDZHaBegMO/b117a0f1866cbf4a3657ad6ab6e68fc8/msm_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1wzZaZeX3jqpMHFT0RMkSM/70f31baf5d2a2d389067dcf70f23f899/msm_3-2.jpg?fl=progressive",
  },
  SMITHSONITE_STONE: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6DkseKQHJMeh9nLwvDJpfP/7a273b934b932cd67ba8f93ad5e82fe3/smithsonite-stone_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/zNB61q3ca4KfnjJcqPWHg/4892f1c58b3f98612a4b428684032d95/smithsonite-stone_3-2.jpg?fl=progressive",
  },
  PRICKLY_PEAR: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6NLff1g5mpNrhJExOU0hS3/b8867bae6933a0a18cf5c6c576df0179/prickly-pear_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3beKEsqGNdrRqVP5PiKQEB/3970fe74fc9d35ff2ae384ab5dd1a76b/prickly-pear_3-2.jpg?fl=progressive",
  },
  POSTBIOTIC_ARGININE: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3Ht1i4yKH8QyPQFjVJ7LAy/4cc0ffdafcd76b1604d0e205348618b0/postbiotic-arginine_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2odzxBkxu7NpY1or4DJXt9/096fb5c47ff31688075a0d946beb7b7d/postbiotic-arginine_3-2.jpg?fl=progressive",
  },
  POSTBIOTIC_L_PLANTARUM: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3xCevEm53kH5nxtxNAgtWr/81c406ada423dd7c77c61baca8bd4baa/postbiotic-l-plantarum_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5GAr3C1t3gDHYXONGeF1y5/dd74caadbbe085723889c22026a8c8bb/postbiotic-l-plantarum_3-2.jpg?fl=progressive",
  },
  BACILLUS_FERMENT: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2pxjCn46043Cnl3BNGBvBu/e1a92975987d8a179644770e50fb6201/bacillus-ferment_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1VLq7FDBXFpfSmqiJkQ8Xt/5e8acf416cb5832cd057e79139679090/bacillus-ferment_3-2.jpg?fl=progressive",
  },
  COQ10: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/8XTFZQGrh3OK9ssqgZtny/b612df616586ede757132de1e01034eb/coq10_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/34mKnxemPlnx1L8H612YTJ/f3f492a50a38a4bc7431b093330e31db/coq10_3-2.jpg?fl=progressive",
  },
  ARTICHOKE_ROOT: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6DQXiGkjSf4FHn3vNLCVvD/369c32167898592d8adaa4be1c8769fd/artichoke-root_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1FrzA6PrGK7DwiLoKrTpur/b4264b9d44d7f284c5a485415bb2401c/artichoke-root_3-2.jpg?fl=progressive",
  },
  CHROMIUM: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3ScOpQX7147sfOI9ODPp2P/ed54d1734830c74b6e9972445a181bd2/chromium_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5j5tTXXLT6Fb6sMtk9AMqM/4cdee81cf586ce5d1f29f2a3e1a1336c/chromium_3-2.jpg?fl=progressive",
  },
  MYO_INOSITOL: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/WhunKvrOqJve0EGSt07jV/788525f03e57792418f2e5712115dcef/myo-inositol_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/81bpEdRDgGMlItQYD4lBb/95ca827bbe173256109e6712d4007ea4/myo-inositol_3-2.jpg?fl=progressive",
  },
  GLUTATHIONE: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5kjlln60Gv8vVGsHR6xrFS/46a314838366025a222277300119c3a7/glutathione_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5SZJbBIxos8Sq7jXS1eXeL/823a58376a6c902e673877318a697467/glutathione_3-2.jpg?fl=progressive",
  },
  ALPHA_GLUCAN_OLIGOSACCHARIDE: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/HCz5rzdrgD5IrpIscLxiV/34892a087fc340e88145da24355e3911/alpha-glucan-oligosaccharide_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/16WktwcmSfRAHuqcCHj4QM/beadf5610b04a1d9f62ce1b74134451a/alpha-glucan-oligosaccharide_3-2.jpg?fl=progressive",
  },
  CHIA_SEEDS: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7nv9anBb19x1swfXN06iCu/13da346e74d1f184d9a53c85ca65dff2/chia-seeds_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5nrd82ZgEVZELgb6rLQkKM/099244fee932f95344b288dc9599e38c/chia-seeds_3-2.jpg?fl=progressive",
  },
  BIOSURFACTANT: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4bKN8LGRy0X1OIxXOoHhYn/bd0ae79747edcc6704983d292553415e/biosurfactant_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4jBfVynfMoYUx13WWyk2Qp/d4c5a9533822ad000da03dff0330836a/biosurfactant_3-2.jpg?fl=progressive",
  },
  XIMENIA_OIL: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4nuDq6fE9vfqYgIog0Huvf/38487c4e07151c61be3eaed86a3c130d/ximenia-oil_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1Up60DcRly6ee20aK8avaU/bc6071f842c62ebdba936b162c88ac0e/ximenia-oil_3-2.jpg?fl=progressive",
  },
  KOREAN_RED_GINSENG: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6NE23QbVzhqGjwMriKM3KY/5543018649a8065e0c60e93cb7d28f03/korean-red-ginseng_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7t06AnMmnBBP38pbGOP98H/7ae4dbbef764cb116c70b09faf5d4806/korean-red-ginseng_3-2.jpg?fl=progressive",
  },
  GLUCOSE_FIBERS: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/m7NxvfbKQ3OR75NPo36K0/29ff3e517d8c9d4319058d6899c467ad/glucose-fibers_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/pnDhAXr6MJACUQ1qgnUCr/ce6455bf3ff1352e9fde10534dba45f3/glucose-fibers_3-2.jpg?fl=progressive",
  },
  HYALURONIC_ACID: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3pKep3u998UDj4JFtZLNes/7ae598f444cc5bf4789d3d0b29b80f33/hyaluronic-acid_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3GuWwcTvL3je3xm8cno6Ua/b7aa89e6c33f37edb7aaba37c1f47f31/hyaluronic-acid_3-2.jpg?fl=progressive",
  },
  AMINO_ACID: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7djejbXLPJ2Fo1EHbVbrwf/f0776a8eac3e4b1a64f87af2a8553557/amino-acid_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5UaYHYfCB2YN75d5PvpHzg/5c92f24b1fc6fd03809d73279a981a1c/amino-acid_3-2.jpg?fl=progressive",
  },
  BAMBOO: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1wCOORG4uIC7aKvbXZQj5P/3ed54da9f1140823095c961085631ac6/bamboo_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/KuIq4L4Hn8TTKs2wBZdNr/ae4e51750238b151b6760718dc2aab65/bamboo_3-2.jpg?fl=progressive",
  },
  BERBERINE_ROOT: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6V1QiPK4Fto2G1Ro11j2z2/5c7072634ae3d9ecc2aad15461443ba7/berberine-root_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4CrLY8OMdzSU7Fll8K1aGI/c01e5210f871a1a8e4e4f140328e514e/berberine-root_3-2.jpg?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4lfIqQDknskL4NWqz1dVUU/df040e1a1a456b0d5a887f5e231cc53c/berberine-root-powder_1-1.jpg?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7sVsJJ0vrIRSlqyf6MeZVd/78876012f39aee2271b77460287da957/berberine-root-powder_3-2.jpg?fl=progressive",
  },
  BARLEY_SEEDS: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6y4L8poHnVBNRJpfXz0LOh/bc1f795fac8aee59ed8f7253714ba609/barley-seeds_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3ZKiMj35JPMea40TWZSzI2/fb69d34c77e66fe1bc82301f125c1f8e/barley-seeds_3-2.jpg?fl=progressive",
  },
  HOLY_BASIL: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1maNJfa8h3tPLHkCWvqZ08/02c1b4e56c8a479b48b2f81f82a9cd9e/holy-basil_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/Rfy2UG3paM7vvZwjwWgLA/86b037da80c25aa35dae82eb9715c4b5/holy-basil_3-2.jpg?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/67CDU38IM6FyhaX9oQX5HI/03d51214243037da9fde3a66b316cee3/holy-basil-powder_1-1.jpg?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6MU9geHetssMCJUez7a1gB/62bdbbbfcb25d58ad5c122fa9d5b3729/holy-basil-powder_3-2.jpg?fl=progressive",
  },
  GINGER_ROOT: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7IFHQnMYKhLie5DccZRMRz/e53ff8a35bd526bdd478373c85e6d1eb/ginger-root_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3sFVdAnUaUpc6qyQwFhlMC/5ec56ec1d8c5dd61da42e987f9e88af4/ginger-root_3-2.jpg?fl=progressive",
  },
  KONJAC_ROOT_POWDER: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4TKajtvvKnI2qUr4eCKJLn/7a731ca27beff5125fc87816316c50cc/konjac-root-powder_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3Fi3iTQHS2lwUejIxHd1Th/309d9445c77d61f800ca09c42b565089/konjac-root-powder_3-2.jpg?fl=progressive",
  },
  OLIVE: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7JM5nsXoPYVuVlNObmOICO/00af91fa857293745871ed41f6e6c559/olive_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3oxUhl095vtycOxEPRFlIo/5cd2031ed5e06ec807cbee7feed8fa99/olive_3-2.jpg?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5tt8W69vJODEzu5cQOM4eL/d652aaae184508f24a95627c3017e6b8/olive-powder_1-1.jpg?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7aESWtm2v144I2iUh9yMO2/33ee4b796e76dd3c9452f49137b0cf7c/olive-powder_3-2.jpg?fl=progressive",
  },
  PEACH_GUM: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6RzqGn3eimefiRtBpNiDxc/c465f690f12aad057816fd3d410eebf8/peach-gum_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/Oj4F5K7NgSnjYplG39Cx7/3c55906ee857a6b487fd144419178658/peach-gum_3-2.jpg?fl=progressive",
  },
  POMEGRANATE: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/79Ctsl5BqotlyKLdE7FbJQ/61a53eb4737ddc421742586d4106be22/pomegranate_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1hZETWF1TJmNDOdX3mXBs1/de925ae0058579ce2bd48b22e9784e0f/pomegranate_3-2.jpg?fl=progressive",
  },
  PULLUAN: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4c3GztO7nZ8AebCFZUREgU/59ef5a9c4c1a0d79e95e5f6713b4e493/pulluan_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7x5uBKb5eAzQ5HyjEYQ4Qi/fe1a6a9f91e2f77438fe93c314707263/pulluan_3-2.jpg?fl=progressive",
  },
  ROOIBOS_LEAF: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1mMXQpA7K642rdWuVbdvV0/76b27bdf21df300799e9ac6bc6bed0de/rooibos-leaf_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3AIaYL8RhrVPq9OE0PTP1I/647d86c9b6cfe64d1ea33fb52b50ea77/rooibos-leaf_3-2.jpg?fl=progressive",
  },
  SICILLIAN_ORANGE: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/41pvtmCBXuwZiC2CI94G8/affd3aad2b93799e87f9b7100f06fb72/sicillian-orange_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/36mM8dgdd1A1uCtMNaxbHh/a4e2966b355eb23ce4151128da65086a/sicillian-orange_3-2.jpg?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3RVAjDovAiE8CuN0zFF74u/73c6c3633d3c80188153b388d0b0abf4/sicillian-orange-powder_1-1.jpg?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/weFaZIwSLtq4I40AUVdjn/2f579ec3d8d40bcd8fdf1ff04b578744/sicillian-orange-powder_3-2.jpg?fl=progressive",
  },
  TOMATO: {
    label0:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4wmv4oCRMr1ZIZmveNBKIB/16e284f84159208137951122c435165f/tomato_1-1.jpg?fl=progressive",
    label1:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7jtnhtyPU9pq9RJnQAoyuB/136ecfd3afe5b5e6d0d7b3900572563b/tomato_3-2.jpg?fl=progressive",
    label2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4W9Q11vnrcQNmnQtqXb8Lw/ad0eced15b56facad662fee9fa9ac972/tomato-powder_1-1.jpg?fl=progressive",
    label3:
      "https://images.ctfassets.net/0rbfqd9c4jdo/33TLqYx1weQD28Qjy2bbRF/97a821847dfe372846f0994cbae8a2f5/tomato-powder_3-2.jpg?fl=progressive",
  },
}

export const IMAGESMAP = {
  AREAWOMEN: {
    AFTER: `https://images.ctfassets.net/0rbfqd9c4jdo/3yegR9xtDXJCSeuIo6hFFV/dbe0ecb0273c09c33412709f733bfa03/women-pdp-after_desktop.jpg?fl=progressive`,
    AFTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/iwLKC431kPeejfxJOVgWB/c5509f6d1bf51bdef29c949d34192ae8/women-pdp-after_mobile.jpg?fl=progressive`,
    BEFORE: `https://images.ctfassets.net/0rbfqd9c4jdo/49sylcGwLwho6BjrCXBZjY/2b0257ff88555f306f1012528f14e68f/women-pdp-before_desktop.jpg?fl=progressive`,
    BEFORE_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/61JgLXZJjEA8CrCRR8fQS5/e7b65118261a48d15d81633d22ace688/women-pdp-before_mobile.jpg?fl=progressive`,
    INGR: `https://images.ctfassets.net/0rbfqd9c4jdo/2SZngFd25RqOzLtMwzK84a/6d82633d4785c05c7714cb743be5615b/pdp-carousel-women-ingredient.jpg?fl=progressive`,
    INGR_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/hlBp34FOIx4S7dUlCvxAK/183cb967c7f823f08c75c2b05fdd5fd5/pdp-carousel-women-ingredient-mobile.jpg?fl=progressive`,
    PACKAGING: `https://images.ctfassets.net/0rbfqd9c4jdo/lsaBFToAZi8M58K64vhJc/700ebcbbd0d139cc8549a07be641775e/pdp-women-package.jpg?fl=progressive`,
    PACKAGING_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/7McfBY9T1EZV7K6ZIMsGw/634945a3747586737a391130b5c018dc/pdp-women-package-mobile.jpg?fl=progressive`,
    PILLS: `https://images.ctfassets.net/0rbfqd9c4jdo/1IbyloxG7909vUg3of9bKV/a78e963a32792192dfc3e50dec93c62c/pdp-women-pills.jpg?fl=progressive`,
    PILLS_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/1pkOyzXdfCtPk3EUAOFrtu/7e4b629fe25e770e7af9ab5199e300e4/pdp-women-pills-mobile.jpg?fl=progressive`,
    STAT: `https://images.ctfassets.net/0rbfqd9c4jdo/1sPOh8nDVHH89G3SPR5rax/17c3096515e14e234b9e3ccf2c23050a/pdp-carousel-women-ingredient-stat.png?fl=progressive`,
    STAT_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/3AXal71ODQSBD7GxnRz9Me/b5eb933ae47939348b820296c3afa3fd/pdp-carousel-women-stat-mobile.png?fl=progressive`,
    VIDEO_POSTER: `https://images.ctfassets.net/0rbfqd9c4jdo/1NDRCrM99mp6Bk1c3heagz/23e58242cd39010a4f6c6cf37458ab66/2024Jan_PDPTestimonials_Desktop_Womens_Avery_STILL_732x960_v1.png?fl=progressive`,
    VIDEO_POSTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/u7bWJ12ooWUsVJtTpu8uz/077a7fc02421128cf6c2bc514aaf34cb/2024Jan_PDPTestimonials_Desktop_Womens_Avery_STILL_686x760_v1.png?fl=progressive`,
    VIDEO2_POSTER: `https://images.ctfassets.net/0rbfqd9c4jdo/2B1vODbijruc1XJFxXohs3/3fdf457b7b0369dd96455d6f4fc38cb9/Aisha_PDP_Preview_Desktop.jpg?fl=progressive`,
    VIDEO2_POSTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/ohkx2tB0HsseVDXirUoxC/61c3e39670d958e047a24ab40ff1a53d/Aisha_PDP_Preview_Mobile_760.jpg?fl=progressive`,
    VIDEO3_POSTER: `https://images.ctfassets.net/0rbfqd9c4jdo/3V9InJaUKov8DMM9P5x3fh/7e5ffd46e9ab648d27686bdc0141a95c/pdp-women-video.jpg?fl=progressive`,
    VIDEO3_POSTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/5O3zoHPc1v8Va5f02NLJtM/58db0008633abb611180f745cde03177/pdp-women-video-mobile.jpg?fl=progressive`,
    REDESIGN_INGR: `https://images.ctfassets.net/0rbfqd9c4jdo/7g36agitTZioYfx12imjYt/816f1a6cda7d27d74dd9702792dc98e6/wom-core-ingr-nova.jpg?fl=progressive`,
    REDESIGN_BEFORE: `https://images.ctfassets.net/0rbfqd9c4jdo/4XdGVh8AXeJMbRFDUeAjgg/5fab66d70c65672a139a24df0bdb1712/wom-pdp-before.jpg?fl=progressive`,
    REDESIGN_AFTER: `https://images.ctfassets.net/0rbfqd9c4jdo/4xWmXGZ0Muq4DZr9jJAsK2/6ccf5de112986d500224c42a9311e6aa/wom-pdp-after.jpg?fl=progressive`,
    REDESIGN_PACKAGE: `https://images.ctfassets.net/0rbfqd9c4jdo/5otwzeb8OWDKHR76c5tDaS/3b9157bf29a04d9927b11da457da8964/wom-pdp-package.jpg?fl=progressive`,
    REDESIGN_STAT: `https://images.ctfassets.net/0rbfqd9c4jdo/30rm7Fv4Cvya5sqSe8CLcK/d6eb7e18a61ba022a750d67f3d6787ab/wom-pdp-stat.jpg?fl=progressive`,
    REDESIGN_PILL: `https://images.ctfassets.net/0rbfqd9c4jdo/5toezGZc1uVC8q2PokS3z4/c4d2afd1c62ad0f1a22a2589d58c3978/wom-pdp-pill.jpg?fl=progressive`,
    REDESIGN_VIDEO1: `https://images.ctfassets.net/0rbfqd9c4jdo/2Q33KFq8HZ3pcHZE529HVj/cdde98136fb623cb900149812c43a843/wom-pdp-video-avery.jpg?fl=progressive`,
    REDESIGN_VIDEO2: `https://images.ctfassets.net/0rbfqd9c4jdo/4ThE9PmxLm0SDlOVf45Ebm/b7a22502987dd99d1a685497fadb55ac/sheila-carousel-poster.jpg?fl=progressive`,
  },
  AREAVEGAN: {
    AFTER: `https://images.ctfassets.net/0rbfqd9c4jdo/6WHzREYtdHycLWOXWRbuYd/adcf52ae7d185b0ab3a171be3339a525/pdp-vegan-after-upd.jpg?fl=progressive`,
    AFTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/n53ybdw8SFtI4WlGqqSvb/044d93fe79982ac867363926ffcec8f2/pdp-vegan-after-mobile-upd.jpg?fl=progressive`,
    BEFORE: `https://images.ctfassets.net/0rbfqd9c4jdo/5NwjqzqOx5yjlZQY4rRg6i/2f8c8028247b86159541841db09f72e1/pdp-vegan-before-upd.jpg?fl=progressive`,
    BEFORE_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/5xXoVqltaffg2i55NDf8Md/7629a6ff7f861589499072b889e9fd91/pdp-vegan-before-mobile-upd.jpg?fl=progressive`,
    INGR: `https://images.ctfassets.net/0rbfqd9c4jdo/4l0lNIaYVE2RJEUNNB20id/1105a46a7b6b867128155164dd91fc37/pdp-carousel-vegan-ingredient.jpg?fl=progressive`,
    INGR_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/7lGaLHRshRKMjIv5z6Z9kO/73255010897cad7dba57518ee7230e83/pdp-carousel-vegan-ingredient-mobile.jpg?fl=progressive`,
    PACKAGING: `https://images.ctfassets.net/0rbfqd9c4jdo/4PIKdSiH0TbEfCub7ZCs4f/1e027dbfb853611e290d9032061f4e1a/pdp-vegan-package.jpg?fl=progressive`,
    PACKAGING_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/7C22dYrYJ0hXtVw1vu6GGC/bc033a039dd896946e86d0a35191f883/pdp-vegan-package-mobile.jpg?fl=progressive`,
    PILLS: `https://images.ctfassets.net/0rbfqd9c4jdo/7yorC9oCZV5tA7Aswn39ga/cc8bf222f40c76986c46b64ced71cc6f/pdp-vegan-pills.png?fl=progressive`,
    PILLS_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/6HiqlzF1xHv42ireDImkpm/5a4f14f6f1e55feea7b07f29b61cfff9/pdp-vegan-pills-mobile.png?fl=progressive`,
    STAT: `https://images.ctfassets.net/0rbfqd9c4jdo/3PloNHYP92XGZp8d8oXF7n/9f5068b171a033df2ec3c52b02d30618/pdp-carousel-vegan-stat.png?fl=progressive`,
    STAT_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/6XskikAYro3V3fQ0fBOh95/6c12d99c94c112e069369261520edc13/pdp-carousel-vegan-stat-mobile.png?fl=progressive`,
    VIDEO_POSTER: `https://images.ctfassets.net/0rbfqd9c4jdo/11mVw274szfcF2SaM3awvs/2bcab15db043f942ab5cb0cc76e05b39/2024Jan_PDPTestimonials_Desktop_Vegan_Bianca_STILL_732x960_v1.png?fl=progressive`,
    VIDEO_POSTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/5dxgyn2m65gULnGjf5brf3/35eb13bec266f266974a631a3302f975/2024Jan_PDPTestimonials_Mobile_Vegan_Bianca_STILL_686x760_v1.png?fl=progressive`,
    VIDEO2_POSTER: `https://images.ctfassets.net/0rbfqd9c4jdo/CW9JggVPHuc7WhNPkIBwc/9a2b1481cc0d3fb16972e6cd9ed626ee/preview-vegan-krystal.jpg?fl=progressive`,
    VIDEO2_POSTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/6L1tEZzL9RsHJKVb42r3WV/13ddbcc4a63b6cb6b249ebea2d606f6b/pdp-vegan-video-mobile.jpg?fl=progressive&w=686`,
    REDESIGN_INGR: `https://images.ctfassets.net/0rbfqd9c4jdo/4LiuOSJcmr9sM6gj3JxUGI/f1f0e62a55a3b3cefbf810a8c09aa91e/vegan-ing-nova.jpg?fl=progressive`,
    REDESIGN_BEFORE: `https://images.ctfassets.net/0rbfqd9c4jdo/2AArK5gFLwB01MqmVcJS4B/76a7a69b6474d09dd79b75582b8a72aa/veg-pdp-before.jpg?fl=progressive`,
    REDESIGN_AFTER: `https://images.ctfassets.net/0rbfqd9c4jdo/NzgFIssG0fIdryjMlmJfs/d1a97e2fd21b2e442ed3611dec9f1d02/veg-pdp-after.jpg?fl=progressive`,
    REDESIGN_PACKAGE: `https://images.ctfassets.net/0rbfqd9c4jdo/1uiq4ml6odMHfmIbW8siFK/2b26fa1ee93c8ac228069b39a87c1a42/veg-pdp-package.jpg?fl=progressive`,
    REDESIGN_STAT: `https://images.ctfassets.net/0rbfqd9c4jdo/41CTzE1WfxonidCT4YeFWT/26835381b76150ce9d11fdb840415b95/veg-pdp-stat.jpg?fl=progressive`,
    REDESIGN_PILL: `https://images.ctfassets.net/0rbfqd9c4jdo/4iddyVxF4VYnZzbX8K2h1v/25c9e1cb61a4c1072bd6f9d0582095bb/veg-pdp-pill.jpg?fl=progressive`,
    REDESIGN_VIDEO1: `https://images.ctfassets.net/0rbfqd9c4jdo/6kdeFrh8WeFY6X6sTJK5FO/9ecc2a47817c3fc3ab7a79c39d387c70/veg-pdp-video-bianca.jpg?fl=progressive`,
    REDESIGN_VIDEO2: `https://images.ctfassets.net/0rbfqd9c4jdo/5QRaQ6a0xf4DMENgceUr9H/b03db761b93379a0808d7504fbf30a4c/veg-pdp-video-krystal.jpg?fl=progressive`,
  },
  AREABALANCE: {
    AFTER: `https://images.ctfassets.net/0rbfqd9c4jdo/3u5QpGx1W3CiMbaqQmBggW/08a6213ffb792915cdf9de785670b759/balance-after-desktop.png?fl=progressive`,
    AFTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/fSvSeXMYAY7MlVY7iiSlr/0cd7c557611c6a33fd6292f2e4ba300c/balance-after-mobile.png?fl=progressive`,
    BEFORE: `https://images.ctfassets.net/0rbfqd9c4jdo/7CgyeDz4bycwy2tM3OQ2DA/354c6fe54e8c0efda15d04d74a66244d/balance-before-desktop.png?fl=progressive`,
    BEFORE_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/3LqEOpCd6jCtmaaIWqMCO5/c12143c662f54247ae6578b4f05256ba/balance-before-mobile.png?fl=progressive`,
    INGR: `https://images.ctfassets.net/0rbfqd9c4jdo/1BLIk48gilypBQqT9qLzrL/38cf9febf14c2fdefc82aa71a3caebe2/pdp-carousel-balance-ingredient.png?fl=progressive`,
    INGR_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/H1cHC0HEkOVI0Z9wrDqua/3f573da8e650466a9755133a065b3f2d/pdp-carousel-balance-ingredient-mobile.png?fl=progressive`,
    PACKAGING: `https://images.ctfassets.net/0rbfqd9c4jdo/2mDJD1VOZwcYJDU9eoyX1O/dd5690fa13a180e20aa0ebd092009ff5/pdp-balance-package.jpg?fl=progressive`,
    PACKAGING_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/10VqrMtAcIUUxWY5FdfLT8/45f6d3a32238bad42b6adbcb24808b7f/pdp-balance-package-mobile.jpg?fl=progressive`,
    PILLS: `https://images.ctfassets.net/0rbfqd9c4jdo/4IJgfp9hdASPi6aW5JQw3h/dceb3d93e1e678aa7711fa08b7acf832/ingredient-desktop-balance.png?fl=progressive`,
    PILLS_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/4LMew246p83IomxT3iVaWE/e11661a78efd2eb49ae7bb4d6e5c29ee/ingredient-mobile-balance.png?fl=progressive`,
    STAT: `https://images.ctfassets.net/0rbfqd9c4jdo/45nhbwslEjOiNm5A9WuGT0/1cd81b34b8d37f11e15b334c192d6255/pdp-carousel-balance-stat.png?fl=progressive`,
    STAT_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/7ijYSPs0SVsRuoL4x1W1QW/205b6953fae3c55622c4c3fd8932284e/pdp-carousel-balance-stat-mobile.png?fl=progressive`,
    VIDEO1_POSTER: `https://images.ctfassets.net/0rbfqd9c4jdo/5UPqrBWvVtbxxACLjok9ru/74a29767600fbd71681a47186134f240/2024Jan_PDPTestimonials_Desktop_Balance_Stacey_STILL_732x960_v1.png?fl=progressive`,
    VIDEO1_POSTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/3ZHAGKO6Mwv3Dc1qKyR4QE/45ac7210b3bb5d2c03863df7f1209a76/2024Jan_PDPTestimonials_Mobile_Balance_Stacey_STILL_686x760_v1.png?fl=progressive`,
    VIDEO2_POSTER: `https://images.ctfassets.net/0rbfqd9c4jdo/5hQiAq10SkoH7xdb3uiMn4/45051057e85eb976d55ee850c453b535/KarenRochelle_Testimonials_Preview_Desktop_R2.jpg?fl=progressive`,
    VIDEO2_POSTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/6OU3Nu0dWwMP6TpDp1yazl/742c55437a2b1cb7be234981727d5ab4/KarenRochelle_Testimonials_Preview_Mobile_R2.jpg?fl=progressive`,
    INGR2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4Cigm9FYmChg7omPmokRYG/63df4cfb149d0525c43963ed9830690b/balance-ingredient-desktop.jpg?fl=progressive",
    INGR2_MOBILE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5e4aUuKaBewWvkF4pnRXiU/2cb6fc96501a167aa5c6d8f391b35afd/balance-ingredient-mobile.jpg?fl=progressive",
    BEFORE2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/Kly9FdnIOXnm5XJiUsdEL/cb642b135893d85d75acaa9bae1c1d89/balance-before-desktop.png?fl=progressive",
    BEFORE2_MOBILE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2fUU6kliXem5axmIHLJ5Pm/48333c426187ab4f6657a957c2a442ae/before-mobile-balance.png?fl=progressive",
    AFTER2:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4s3XMnvfEFlZ5XIJq6TY4N/e42c89f0be4a5a1269d047b8b28d7cd9/after-desktop-balance-upd.png?fl=progressive",
    AFTER2_MOBILE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4vbcIFSCFEajBzN9HiYJd7/a707e30427ffcd7b614099dd6f3e9c45/after-mobile-balance-upd.png?fl=progressive",
    REDESIGN_INGR: `https://images.ctfassets.net/0rbfqd9c4jdo/48RnY0ZVsBFXlThBKK53Lk/d827333bc0e9567e0fec14bb6d9bef13/bal-ingr-nova.jpg?fl=progressive`,
    REDESIGN_BEFORE: `https://images.ctfassets.net/0rbfqd9c4jdo/5Ckp9EgpjXlkXowJPUNMBy/99de0b4f2bcdc4858b70c71073076569/bal-pdp-before.jpg?fl=progressive`,
    REDESIGN_AFTER: `https://images.ctfassets.net/0rbfqd9c4jdo/6z0vmasi7l2jQGPQbyKouY/a75eb29db33a507a00b0d6c68940fdfc/bal-pdp-after.jpg?fl=progressive`,
    REDESIGN_PACKAGE: `https://images.ctfassets.net/0rbfqd9c4jdo/6NJJXfX0cOWzV7WHrNT1Ja/e5e801b44e94cee33d4341114745ede8/bal-pdp-package.jpg?fl=progressive`,
    REDESIGN_STAT: `https://images.ctfassets.net/0rbfqd9c4jdo/x89CFel5933La70rROUBE/b5df15f40684ac6ab4840b1870060451/bal-pdp-stat.jpg?fl=progressive`,
    REDESIGN_PILL: `https://images.ctfassets.net/0rbfqd9c4jdo/QiIRDhJAH9xtTyEQyEdtN/d7d08fe17a5a240ccad3e64a4c3d1343/bal-pdp-pill.jpg?fl=progressive`,
    REDESIGN_VIDEO1: `https://images.ctfassets.net/0rbfqd9c4jdo/1UhMd9zBMelMZArok2Kxy0/efc4667f322e7ba7100751af0926c280/bal-pdp-video-stacey.jpg?fl=progressive`,
    REDESIGN_VIDEO2: `https://images.ctfassets.net/0rbfqd9c4jdo/30MLCVgHzViLZp17yZ3dLc/297509a360d2e873d3c622c921a16c84/kathy-pdp-balance-carousel-poster.jpg?fl=progressive`,
  },
  AREAPOSTPARTUM: {
    INGR: `https://images.ctfassets.net/0rbfqd9c4jdo/7x01fs87Gi5SVX2mr2x8v0/9f49cf662c37bc9e3081db2576d412d8/pdp-carousel-postpartum-ingredient.jpg?fl=progressive`,
    INGR_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/2Q0tkV5gnyNl2TIKiw3Zlk/eed49751e04a468c70dce4c4fccd18cb/pdp-carousel-postpartum-ingredient-mobile.jpg?fl=progressive`,
    LIFESTYLE: `https://images.ctfassets.net/0rbfqd9c4jdo/5cLWhgVjsw7OtEVaTqvjnB/37a0ac587da1928958971175c498c926/pdp-postpartum-lifestyle.jpg?fl=progressive`,
    LIFESTYLE_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/4oOo9lrwVTyWdkp0L7w6wc/f00dc3cddb231efb49f4a4c1331a9753/pdp-postpartum-lifestyle-mobile.jpg?fl=progressive`,
    PACKAGING: `https://images.ctfassets.net/0rbfqd9c4jdo/6TWBbY4jZCfqC2AhKtv1hI/c58a4328ecba6aeb82ea5b69df70b56e/pdp-postpartum-package.jpg?fl=progressive`,
    PACKAGING_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/6zzo3JhB4SxbenpYaZorK8/0ad9fdafabead4481691dd1279731778/pdp-postpartum-package-mobile.jpg?fl=progressive`,
    PILLS: `https://images.ctfassets.net/0rbfqd9c4jdo/2NCokOmXrSswcKdU8f7dFH/e245b961c1e38176e1ee252902fd26d5/pdp-postpartum-pills.jpg?fl=progressive`,
    PILLS_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/33ps93A5dlxIsPhwLfi3NW/74079dafe035249cd4e6725d935115cf/pdp-postpartum-pills-mobile.jpg?fl=progressive`,
    STATS: `https://images.ctfassets.net/0rbfqd9c4jdo/2k9VLfQ5bDpsr8nkD2ELli/431bf9cac012d9329b5425a769178ede/postpartum-stats-desktop.png`,
    STATS_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/1Pve4cwBLaGG6a66EPmztP/7cc4bfbd6f241c5445909a6107cfd21d/postpartum-stats-mobile.png`,
    VIDEO_POSTER: `https://images.ctfassets.net/0rbfqd9c4jdo/57CMSrJwFaMNl40tx6HcXt/de22bfedd783e61b579cc7575ebfa2d7/pdp-postpartum-video-new.jpg?fl=progressive`,
    VIDEO_POSTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/1xM6cX0DigKjRTLF2GS2y9/a463a58ce0b542cf4bff0ed13cf42ad4/pdp-postpartum-video-mobile-new.jpg?fl=progressive`,
    VIDEO2_POSTER: `https://images.ctfassets.net/0rbfqd9c4jdo/7l6HJGPrlPasdUBy7xjAHP/2c00cfc6565e4a9db2896c1fcf5fd459/Jojo_PreviewImagesPreview_Desktop.jpg?fl=progressive`,
    VIDEO2_POSTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/2vaLP7YiAhchkDBwp9uYbt/a130ca5a3ea646bb23abb988c00d0327/Jojo_PreviewImagesPreview_Mobile_760.jpg?fl=progressive`,
    VIDEO3_POSTER: `https://images.ctfassets.net/0rbfqd9c4jdo/1uERLHFPIeBiEIHTIcgodR/2fb078d261af967b8b9c2c5f67912eb6/2024Jan_PDPTestimonials_Desktop_PP_Gigi_STILL_732x960_v3.png?fl=progressive`,
    VIDEO3_POSTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/3QSHml0p2elA1lKKHoUT8R/6147c1591e419887806e568ec3ff34de/2024Jan_PDPTestimonials_Mobile_PP_Gigi_STILL_686x760_v3.png?fl=progressive`,
    REDESIGN_INGR: `https://images.ctfassets.net/0rbfqd9c4jdo/5fuLM7lQa44RlTu3zUdTDA/8dc60f91cd675884719b02d054564038/postpartum-ing-nova.jpg`,
    REDESIGN_PACKAGE: `https://images.ctfassets.net/0rbfqd9c4jdo/2rrwcym3w6yYULO5oxe19B/47939ebbc53d014179fe66cee4cce92a/pos-pdp-package.jpg?fl=progressive`,
    REDESIGN_STAT: `https://images.ctfassets.net/0rbfqd9c4jdo/5EdEb6Whw4aIj2g1UIl12e/46d841762f5e8fb43b40f753d255f098/pos-pdp-stat.jpg?fl=progressive`,
    REDESIGN_PILL: `https://images.ctfassets.net/0rbfqd9c4jdo/6UDNOs9jEeYaktqgTgeEUo/c7497f76817e93ff86f058640691a48c/pos-pdp-pill.jpg?fl=progressive`,
    REDESIGN_VIDEO1: `https://images.ctfassets.net/0rbfqd9c4jdo/3nyJWLQgbUrvV5x8LC2L5O/49ccf7746b7bea4d5a9def7a39ace724/samantha-video-poster.jpg?fl=progressive`,
    REDESIGN_VIDEO2: `https://images.ctfassets.net/0rbfqd9c4jdo/49eXLLA8rx97AoTKvVQ8ac/fdbeac9ae261f40eabc148a1c6574903/pos-pdp-video-jojo.jpg?fl=progressive`,
  },
  AREAMEN: {
    AFTER1: `https://images.ctfassets.net/0rbfqd9c4jdo/gJbbEU3UKN5yTziVpAh3c/f64159216af4acea00129a0e1e555e96/pdp-jose-after-upd.jpg?fl=progressive`,
    AFTER2: `https://images.ctfassets.net/0rbfqd9c4jdo/6McfZfJ8swej12Oktx1Rew/7fde0384e6c2dcb5a5bd8cd3a4aa561e/pdp-brian-after-upd.jpg?fl=progressive`,
    BEFORE1: `https://images.ctfassets.net/0rbfqd9c4jdo/6Pvo7b5chXdGmL2bZHmc2j/174b7f0200f66697a0588b7a3ef221cb/pdp-jose-before-upd.jpg?fl=progressive`,
    BEFORE2: `https://images.ctfassets.net/0rbfqd9c4jdo/2nmMjVvlOJlQkvMCBotgAP/d1ed7641eb5a1581bfad8db42930f55a/pdp-brian-before-upd.jpg?fl=progressive`,
    FOLLICLE1: `https://images.ctfassets.net/0rbfqd9c4jdo/6xPB3dj04LO5xvSxVl4GPq/c21b8de6520ff28c795d02691ebd5038/men-pdp-follicle-1.jpg?fl=progressive`,
    INGR1: `https://images.ctfassets.net/0rbfqd9c4jdo/ASzGhVYXeIYcbgzLqmbFd/0557636cfa3d3a7584f6df6ab2bd72d6/men-core-carousel-ing.jpg?fl=progressive`,
    INGR2: `https://images.ctfassets.net/0rbfqd9c4jdo/4ed4BZTk778Z6SiPauudPU/94c2fcb73e4a6f5e4764bd212252cb8a/men-pdp-ingr-2.jpg?fl=progressive`,
    INGR3: `https://images.ctfassets.net/0rbfqd9c4jdo/25fqcNnbE9RiM805vZ69sm/971ac995d1c5497911589387c1a1c7aa/dp-imagearea-men-ingredient-without-drugs.jpg`,
    INGR_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/2sRYvV6gxS942RmVJDUMZi/c397bcd70d8020911274c61fb55244ae/pdp-campaign-image-men-mobile.jpg`,
    INSTR1: `https://images.ctfassets.net/0rbfqd9c4jdo/ZuNdJuCeJ6nh8tTAXWJzN/1f94fe85cc8d376d37ec70e1e94470c0/men-pdp-instructions-1.jpg?fl=progressive`,
    LIFESTYLE1: `https://images.ctfassets.net/0rbfqd9c4jdo/4prXChvv3QZGQiI4bsC4uj/49fe7c4b612d7d673681ccaba7a1e3f3/men-pdp-lifestyle.jpg?fl=progressive`,
    LIFESTYLE2: `https://images.ctfassets.net/0rbfqd9c4jdo/1FqTJTCKiUHW5dlWNTEZFA/f55514bce37594e4775c01033dd8ef61/hair-serum-small-mens.png?fl=progressive`,
    LIFESTYLE3: `https://images.ctfassets.net/0rbfqd9c4jdo/3BCpORv04lYJ5MUVjVGKm4/c10dc082dd63db371f77722bca6a892f/men-pdp-lifestyle-2.jpg?fl=progressive`,
    LIFESTYLE4: `https://images.ctfassets.net/0rbfqd9c4jdo/5afqs3r6npwBwrQOEdRsoq/e852622da682134f7097a44de1c0cda8/men-lifestyle-serum-small.png?fl=progressive`,
    PILLS: `https://images.ctfassets.net/0rbfqd9c4jdo/6SjTzjSKtKRPW4LmpEE8Ff/c3796d3d108e4d292247762305f07813/men-pdp-pills.jpg?fl=progressive`,
    QUOTE1: `https://images.ctfassets.net/0rbfqd9c4jdo/1Si7u9clLVBShd6p7C1ZMG/34f6aa9af515d865a29f650cbd032f18/men-pdp-quote-1.jpg?fl=progressive`,
    QUOTEBOLD: `https://images.ctfassets.net/0rbfqd9c4jdo/43cnqZBOIRpBdiJNCwGWnY/cb8639411a5a3a348f81dea15620bbcb/men-dht-quote-bold.jpg?fl=progressive`,
    QUOTEBOLDMOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/1kY1teZC7X5fLDdbXxFkLU/6db9c23e6bae4a1b6dfd9a30d0e1219c/men-dht-quote-bold-mobile.jpg?fl=progressive`,
    SIGNS1: `https://images.ctfassets.net/0rbfqd9c4jdo/33sOg2OiZzjJylcpoZ82cW/050a7cdac7b6b4eaddadf3e55c5bc1a1/pdp-carousel-men-signs-new.jpg?fl=progressive`,
    SIGNS2: `https://images.ctfassets.net/0rbfqd9c4jdo/5qUlShitSQ3pxBrIzcFNaR/e7fab1d242ac1e5ec227288598ae1880/men-pdp-signs-2.jpg?fl=progressive`,
    STAT1: `https://images.ctfassets.net/0rbfqd9c4jdo/71nwaKZMWPZVrItRBXogxy/a4dcaf131ba7e9f88a05c98471a1e31a/pdp-carousel-men-stats.jpg?fl=progressive`,
    STAT2: `https://images.ctfassets.net/0rbfqd9c4jdo/6FqAjue2GsFJ5nyvUvdBGJ/69b6f26b2db4cdc867c7b888ac624794/pdp-carousel-men-serum-stats.png?fl=progressive`,
    STAT3: `https://images.ctfassets.net/0rbfqd9c4jdo/2rySY1SRw35r5IwfVLM5UJ/942afd626c5a7aa37e83afe0fdd907ff/pdp-active-cleanse-men-stats.png?fl=progressive`,
    AFTER3: `https://images.ctfassets.net/0rbfqd9c4jdo/4ZErrpQUP044wPgGw3uLB2/62cfb44cddbba64b0498d7ad64b5a591/pdp-kyron-after-upd.jpg?fl=progressive`,
    BEFORE3: `https://images.ctfassets.net/0rbfqd9c4jdo/59exjAjfNkOgiVZXXh8KIx/4086463110bb43c33feb141625c4cf00/pdp-kyron-before.jpg?fl=progressive`,
    AFTER4: `https://images.ctfassets.net/0rbfqd9c4jdo/2LXwpTGn5D0D6FoAgRGZYR/224e3fdf825c3daa15eea013d597fba4/pdp-brian-after-upd2.png`,
  },
  AREASTRESSADAPTOGEN: {
    REDESIGN_SUPPORT: `https://images.ctfassets.net/0rbfqd9c4jdo/1EaiNT5TWDfXD0loWUbZkU/12dd70431cdc7afb298008737ad6f428/stress-support.jpg?fl=progressive`,
    REDESIGN_AFTER: `https://images.ctfassets.net/0rbfqd9c4jdo/1hBT9a1wZRe6akmN7BpZM3/94eb4803d616fc3acfd2467db63b06f7/stress-after.jpg?fl=progressive`,
    REDESIGN_BEFORE: `https://images.ctfassets.net/0rbfqd9c4jdo/3r7Be0QiWpzYPtoNMhuHRS/3a03ce955eb7081f2f2a22c0ce5b66c9/stress-before.jpg?fl=progressive`,
    REDESIGN_PACKAGE: `https://images.ctfassets.net/0rbfqd9c4jdo/4oZWWpsylBaOAgVSfqjsZA/e59b02d2fe904750dfde460086291f0c/stress-package.jpg?fl=progressive`,

    AFTER: `https://images.ctfassets.net/0rbfqd9c4jdo/4Tit2TPlgrt6ekgtHFTxNP/b24a0519a5419148b64a8589ef5e0ff6/stress-after-desktop.jpg?fl=progressive`,
    AFTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/1n0ToC6U62FHcckbarkLYR/4b53ead0a7b2bda704f9206a5bf32200/stress-after-mobile.jpg?fl=progressive`,
    BEFORE: `https://images.ctfassets.net/0rbfqd9c4jdo/1FSwTWxhrd6NnrNNRADPBM/d18060ca24224c8929b1a1fbb391bda7/stress-before-desktop.jpg?fl=progressive`,
    BEFORE_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/3xtxw0mmRRplZb0CuMqPxj/9d6e74ee0953edac9ac7ad596ff24f51/stress-before-mobile.jpg?fl=progressive`,
    INGR: `https://images.ctfassets.net/0rbfqd9c4jdo/7LHmqywFB68Sv9tvOBWA43/5dd712d2b70a740fc3ed1aea0426f79b/de-stress-duo-ingredients.png?fl=progressive`,
    INGR_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/cwgVj1WJWzMHMOF7LJ56i/63909e35cc9aeff68fcc4521946a55d5/de-stress-duo-ingredients-mobile.png?fl=progressive`,
    PACKAGING: `https://images.ctfassets.net/0rbfqd9c4jdo/2odaEDyT6q8G9NSHUAHxiw/6c1021cc65da6582de230195374b2406/de-stress-duo-packaging.png?fl=progressive`,
    PACKAGING_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/4kpE8nMNjHi1Z5jwYw7OgT/466bedf23aacf6d94c52ffde236543f5/de-stress-duo-packaging-mobile.png?fl=progressive`,
    ROOT_CAUSE: `https://images.ctfassets.net/0rbfqd9c4jdo/67BYG6KdCkh8jMB3orNrZP/87b22cc4c268a8b40233571a71c74bf7/de-stress-duo-root-cause.png?fl=progressive`,
    ROOT_CAUSE_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/480FYSFNC6xXxRDWzqC7dL/510f6809cd5ddb6ccebb854bdaba4fc4/de-stress-duo-root-cause-mobile.png?fl=progressive`,
  },
  AREAHAIRBIOTIC: {
    REDESIGN_SUPPORT: `https://images.ctfassets.net/0rbfqd9c4jdo/yTOTg0SMletLPu1zxvKAg/7579d3e3cc324b3fe1aa6ddb91dad68a/hairbiotic-support.jpg?fl=progressive`,
    REDESIGN_AFTER: `https://images.ctfassets.net/0rbfqd9c4jdo/3SiLYGFXTx8cnzHoumuGYa/7dc2d9dfebf53b7d05c7d8d95057da3c/hairbiotic-after.jpg?fl=progressive`,
    REDESIGN_BEFORE: `https://images.ctfassets.net/0rbfqd9c4jdo/3u3BUo1tZqvURkthKiiO07/7a9ced196365c6279fc64fa764101662/hairbiotic-before.jpg?fl=progressive`,
    REDESIGN_PACKAGE: `https://images.ctfassets.net/0rbfqd9c4jdo/6TfJrOu8LvcXuEbnddKdpJ/8751204e83ff21e36630f98081acd2d7/hairbiotic-package.jpg?fl=progressive`,
    REDESIGN_STAT: `https://images.ctfassets.net/0rbfqd9c4jdo/167ORRlqucREIocLH8Nfj9/e48082836fd427fc60918daa48fb28f8/hairbiotic-stat.jpg?fl=progressive`,

    AFTER: `https://images.ctfassets.net/0rbfqd9c4jdo/655bBNfIx5hIcHtuYNPCDL/df55dd335cc26ed7ccd649853139db29/hairbiotic-after-desktop.jpg?fl=progressive`,
    AFTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/3AODFFXoN0UDTMM6kbAtYB/9d4999102322ce2e173c4527fb407ad5/hairbiotic-after-mobile.jpg?fl=progressive`,
    BEFORE: `https://images.ctfassets.net/0rbfqd9c4jdo/6YmkkXhTjqfdZEpC3RIJEE/95da7a8af20ef580dfb2fa46cf4f5575/hairbiotic-before-desktop.jpg?fl=progressive`,
    BEFORE_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/1NhnBPkOLFz5A86T7o563o/c15524f07195699fd475b11e2a988dd1/hairbiotic-before-mobile.jpg?fl=progressive`,
    INGR: `https://images.ctfassets.net/0rbfqd9c4jdo/5pGoC6MSvwYcqsZ8CML1S4/496e52dc6841d7eb6349c3a0d6da4345/gut-mircrobiome-duo-ingredients.png?fl=progressive`,
    INGR_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/4vl4i0BfZRShTSOZNNAQwl/ed18263ce9f5ee9de38e42bde230a282/gut-mircrobiome-duo-ingredients-mobile.png?fl=progressive`,
    PACKAGING: `https://images.ctfassets.net/0rbfqd9c4jdo/3qqDdnTuAmWKkYpWk6xNtC/52712560acddbd65ff4d39340478b13a/hairbiotic-packaging.png?fl=progressive`,
    PACKAGING_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/50oUYHXGMb57mVJrBsoPne/aeb9220d646e294550213bd88e43ce76/hairbiotic-packaging-mobile.png?fl=progressive`,
    ROOT_CAUSE: `https://images.ctfassets.net/0rbfqd9c4jdo/7EfGAmHAv7vog6VmSnqzGU/b6aaf64c184ad70a22789222170ba33e/gut-mircrobiome-duo-root-cause.png?fl=progressive`,
    ROOT_CAUSE_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/4N8dakMNmcu0ZrmrFEQVAN/c2e139c077084f2563bba42b10afcf65/gut-mircrobiome-duo-root-cause-mobile.png?fl=progressive`,
    STAT: `https://images.ctfassets.net/0rbfqd9c4jdo/1KfBW1ugygTuZv5EiSAodu/523e6ad3fa3012696c305e99a43a213c/gut-mircrobiome-duo-stat.png?fl=progressive`,
    STAT_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/17We3EPhX6Tv3LbCciqA4x/7474e39688f11065b67bb5dcf2be5638/gut-mircrobiome-duo-stat-mobile.png?fl=progressive`,
  },
  AREACOLLAGENINFUSION: {
    REDESIGN_SUPPORT: `https://images.ctfassets.net/0rbfqd9c4jdo/3AWoNIa8Rtt4ti9c7YmXj/631fd358cde3b053f280ee3e9f59cbed/strengthening-support.jpg?fl=progressive`,
    REDESIGN_AFTER: `https://images.ctfassets.net/0rbfqd9c4jdo/73LMFqx7CaNWGRJtTthE7v/dc5fd966469d264e893eceef3b8f8c24/strengthening-after.jpg?fl=progressive`,
    REDESIGN_BEFORE: `https://images.ctfassets.net/0rbfqd9c4jdo/WFVVTy4f43wv6XLZhDpKT/40cf37054e047494611cc7fd6be2b3a8/strengthening-before.jpg?fl=progressive`,
    REDESIGN_PACKAGE: `https://images.ctfassets.net/0rbfqd9c4jdo/1rsAkjpvHnCusbQEs2ONWI/60884f10781f24933a43ead31700f915/strengthening-package.jpg?fl=progressive`,

    AFTER: `https://images.ctfassets.net/0rbfqd9c4jdo/7jvEDOC6XCzE9zEU3Eq62/7381a57d5107a4db839b5e12ac69e02c/strengthening-after-desktop.jpg?fl=progressive`,
    AFTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/nXJiQWcNeWkYPoTRBQXY9/381000007761ff4c23c0bc9858c60374/strengthening-after-mobile.jpg?fl=progressive`,
    BEFORE: `https://images.ctfassets.net/0rbfqd9c4jdo/4gUm0bUfeFsEZd4iuCYlMM/d98e2f72de1f4352ff0b936001f2d461/strengthening-before-desktop.jpg?fl=progressive`,
    BEFORE_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/4CnXdFV6peFvjP7HmX6f79/5ec72c769acedefed1529c5d64f5e322/strengthening-before-mobile.jpg?fl=progressive`,
    INGR: `https://images.ctfassets.net/0rbfqd9c4jdo/5YSdN6E60WXYE4mdT1Y381/5b3c43171c7313cf00d1b5c0a6acb6ff/strengthening-duo-ingredients.png?fl=progressive`,
    INGR_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/3lCy5hnIh5iUCJty9prYSa/090add4f76b3ee16a8faadb0368baefb/strengthening-duo-ingredients-mobile.png?fl=progressive`,
    PACKAGING: `https://images.ctfassets.net/0rbfqd9c4jdo/3e6nJFwPtkVYNWJdnXR1xw/54102bedea302e24d39fb64f582c320a/collagen-packaging.png?fl=progressive`,
    PACKAGING_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/3FZEpIc2q17jaa6lptY3dk/d784d7f8c4e7634d72da7140ba8f7a1a/collagen-packaging-mobile.png?fl=progressive`,
    ROOT_CAUSE: `https://images.ctfassets.net/0rbfqd9c4jdo/TkG4ZX0aQAqQApti0sJoZ/f65aeed48161ed071635bea3c4dd1fd2/strengthening-duo-root-cause.png?fl=progressive`,
    ROOT_CAUSE_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/6uxXyErsCmUyrJIBQLjoH0/8b8d4733ae1aeaeb42044f329dc63014/strengthening-duo-root-cause-mobile.png?fl=progressive`,
    VIDEO_POSTER: `https://images.ctfassets.net/0rbfqd9c4jdo/5MswQBaumkPOW7EJ4xIzTA/ec25a203d5a4ebad2ebb62864484019c/StrengtheningDuo_B_Preview_Desktop.jpg?fl=progressive`,
    VIDEO_POSTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/4SYP8X08mJOFlcTvSOqNa9/05cbd74cede84bdd5f115555b3a5cd19/StrengtheningDuo_B_Preview_Mobile_760.jpg?fl=progressive`,
  },
  AREAHORMONESUPPORT: {
    REDESIGN_SUPPORT: `https://images.ctfassets.net/0rbfqd9c4jdo/1fdyaGQOOlytPFGhiMFmP9/79749fca8348cb5df8c151831b5c6772/hormone-support.jpg?fl=progressive`,
    REDESIGN_AFTER: `https://images.ctfassets.net/0rbfqd9c4jdo/2Hhxjf02pPJMLtaaykxD92/b0683fc06592f3ed7ded55cd8d2fc82f/hormone-after.jpg?fl=progressive`,
    REDESIGN_BEFORE: `https://images.ctfassets.net/0rbfqd9c4jdo/4RupKkmbF5RoejUd11xfkz/08a82f95699f30f4fcc5e4bf7e02b682/hormone-before.jpg?fl=progressive`,
    REDESIGN_PACKAGE: `https://images.ctfassets.net/0rbfqd9c4jdo/4A61M6DzwuGffnyWIulA3M/46a48ff540a8b356cb04256604448b50/hormone-package.jpg?fl=progressive`,

    AFTER: `https://images.ctfassets.net/0rbfqd9c4jdo/5zdsA4Utk4HXu8EPOhj7Rk/03779b8935a0716ac88741e29f7f676b/hormone-after-desktop.jpg?fl=progressive`,
    AFTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/2txYq1KYkKTmicM6WrqRFW/64a5fbe1e01850834bb289235bf2ff9c/hormone-after-mobile.jpg?fl=progressive`,
    BEFORE: `https://images.ctfassets.net/0rbfqd9c4jdo/6RAWAekz6AUdicWTi5Gz4Z/1f30ca2de304fb35ae1cd0ed9c65b719/hormone-before-desktop.jpg?fl=progressive`,
    BEFORE_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/58u8kuOCBJ9X8cDj2qiBoC/805f2729365e625cac89f53c58bb9315/hormone-before-mobile.jpg?fl=progressive`,
    INGR: `https://images.ctfassets.net/0rbfqd9c4jdo/6AkX5bcUmstGXaXLwNBf6k/37e2c52f41f3e93df80b2d4c60bd605f/hormone-support-duo-ingredients.png?fl=progressive`,
    INGR_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/QCBmSZvEEME0JshDJPqDE/0fb68b290c64cff0d2a7e1c760420d0e/hormone-support-duo-ingredients-mobile.png?fl=progressive`,
    PACKAGING: `https://images.ctfassets.net/0rbfqd9c4jdo/1Y2zRtU6BNHqz02zdGtk6k/9cc9f4ec878d1b628900a46b3dbdbb45/hormone-support-packaging.png?fl=progressive`,
    PACKAGING_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/7Q7hDbucwSs5IX4vWK9ek/5545acade378ca0b53789580f777098d/hormone-support-packaging-mobile.png?fl=progressive`,
    ROOT_CAUSE: `https://images.ctfassets.net/0rbfqd9c4jdo/63aL2DUGIHTlGI8XmP4nIe/e3f929d85d0124b09ab297d8b41ac6c5/hormone-support-duo-root-cause.png?fl=progressive`,
    ROOT_CAUSE_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/6DYTcgamfnLRYaNJ1Jsn1D/ab57f1ef9c683ec51e3fdfc61db0e168/hormone-support-duo-root-cause-mobile.png?fl=progressive`,
  },
  AREATOXINCLEANSE: {
    REDESIGN_SUPPORT: `https://images.ctfassets.net/0rbfqd9c4jdo/400tD37iQmlGF27TfBySdJ/ea63f2a206009eab9025dcf38064489c/toxin-support.jpg?fl=progressive`,
    REDESIGN_AFTER: `https://images.ctfassets.net/0rbfqd9c4jdo/g20LMgY5x7L5ASK3K3nUZ/e047c1c858a2b285399e0e90ba4e71a5/toxin-after.jpg?fl=progressive`,
    REDESIGN_BEFORE: `https://images.ctfassets.net/0rbfqd9c4jdo/lBK1HD1MDoeP5FQSWRemY/6215b13753a231e33ff198f81f750ab5/toxin-before.jpg?fl=progressive`,
    REDESIGN_PACKAGE: `https://images.ctfassets.net/0rbfqd9c4jdo/lo7aIJC5gsUL6ODXNaXcd/d26ea664c16ffcc7b9f62c955ea43eaa/toxin-package.jpg?fl=progressive`,

    AFTER: `https://images.ctfassets.net/0rbfqd9c4jdo/62kOxHhsNGNQMCEnLY7OEB/1650c8164d5fa52fecf79dfa07dbc24f/toxin-after-desktop.jpg?fl=progressive`,
    AFTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/3UON4mTyAzwSOcIzvEKQ1s/8a5ea84395df9840ef09fdaadae0797e/toxin-after-mobile.jpg?fl=progressive`,
    BEFORE: `https://images.ctfassets.net/0rbfqd9c4jdo/48S6fPPYiqRxfLPrCTzxjP/aa30a000964fdd6be2501ebec452b820/toxin-before-desktop.jpg?fl=progressive`,
    BEFORE_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/6qcP9FfYM8xa52jqwh0Q1K/4338a1796ab861a35790ff5476340505/toxin-before-mobile.jpg?fl=progressive`,
    INGR: `https://images.ctfassets.net/0rbfqd9c4jdo/3qUaaqwcYCr8ZR1feNUN1c/dcc0e1b7feea3db6d18c793facae9209/toxic-cleanse-duo-ingredients.png?fl=progressive`,
    INGR_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/1NgoVxj3KP7VQDBZe44Nyo/c3ebed28b4cadeeecc2cf2c53d8117cb/toxic-cleanse-duo-ingredients-mobile.png?fl=progressive`,
    PACKAGING: `https://images.ctfassets.net/0rbfqd9c4jdo/3o2t4rsl8aF1xs28IOOE7w/696335380bc46f92ae8be782f9a50ede/toxin-cleanse-package.png?fl=progressive`,
    PACKAGING_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/3Nga0pWpOF3w8eNL8uqEOK/a4ee2d855aa12ef3f261d6aa67627a3f/toxing-cleanse-package-mobile.png?fl=progressive`,
    ROOT_CAUSE: `https://images.ctfassets.net/0rbfqd9c4jdo/5avuqQ8VAYbP1Rfm3xuhsM/5609d08092402ca3413caa10798d85fc/toxic-cleanse-duo-root-cause.png?fl=progressive`,
    ROOT_CAUSE_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/5MZl0LckIfUgawEEbwkNQA/49bdbd07f162f7182ff62507d07191bf/toxic-cleanse-duo-root-cause-mobile.png?fl=progressive`,
    STAT: `https://images.ctfassets.net/0rbfqd9c4jdo/6YzMe2OLHUpzF7bBjFIJdu/3b0e175697c6f0f0353bc619543c31bb/toxic-cleanse-duo-stat.png?fl=progressive`,
    STAT_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/2behn7pIplGqdzic6C3FSo/6980d2d10066dde50c77766411207226/toxic-cleanse-duo-stat-mobile.png?fl=progressive`,
  },
  AREABENERGIZED: {
    REDESIGN_SUPPORT: `https://images.ctfassets.net/0rbfqd9c4jdo/117qj5BToToRwTE85LhLFI/23454288d675a9b2152501b5cc44e9b5/energy-support.jpg?fl=progressive`,
    REDESIGN_AFTER: `https://images.ctfassets.net/0rbfqd9c4jdo/ziJy8hpF3qjwdoFWGIhPG/75c3bf08fcd1cacff2791f5dd6fcee57/energy-after.jpg?fl=progressive`,
    REDESIGN_BEFORE: `https://images.ctfassets.net/0rbfqd9c4jdo/6qohATelHFXCULT7L7rfSH/ac365a10ca2395e9c481475792aa64b1/energy-before.jpg?fl=progressive`,
    REDESIGN_PACKAGE: `https://images.ctfassets.net/0rbfqd9c4jdo/2SelRyLWZqH5eC5lIPm3lx/0b9417497ebf3519c16556a61edd887d/energy-package.jpg?fl=progressive`,

    AFTER: `https://images.ctfassets.net/0rbfqd9c4jdo/75jnkK7Mp02YiPYctEO5oS/599c40d3ba30841779e383d36a78dbdc/energy-after-desktop.jpg?fl=progressive`,
    AFTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/6rEnQ6ueg1vNT2QAYlXOfe/4f021bf16633c4114b28a419fc8bab23/energy-after-mobile.jpg?fl=progressive`,
    BEFORE: `https://images.ctfassets.net/0rbfqd9c4jdo/3lmE0RYLayTpEMAgNxE33N/126d88a08d06a017ff25cf4aa716c520/energy-before-desktop-upd.jpg?fl=progressive`,
    BEFORE_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/1GjeA5XWOPzy4XEwfQiefB/b298c22446272d31c7b17535dac9cb54/energy-before-mobile-upd.jpg?fl=progressive`,
    INGR: `https://images.ctfassets.net/0rbfqd9c4jdo/4sKgQdBxIa7aWYMVPKvfcG/96acfaa0a7f3a0f5b33abd35e850c203/energy-duo-ingredients.png?fl=progressive`,
    INGR_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/5QdiLithoalcXVtPGuhGkJ/d9d6300919a8d0ecb4256c43e9ced959/energy-duo-ingredients-mobile.png?fl=progressive`,
    PACKAGING: `https://images.ctfassets.net/0rbfqd9c4jdo/8PM1XJMyxXZBtwAs7b7NG/7a8ee12cd8f33775b9fc33df80123ce9/b-energized-packaging.png?fl=progressive`,
    PACKAGING_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/2RoPL9skIP2M4fq8ApGVey/aff74e30b4ce0d1ef8aa409063307165/b-energized-packaging-mobile.png?fl=progressive`,
    ROOT_CAUSE: `https://images.ctfassets.net/0rbfqd9c4jdo/WyGx8u65tYNxooE2dHi8t/841465c38f1b0bc76cf9d0b9290a1be5/energy-duo-root-cause.png?fl=progressive`,
    ROOT_CAUSE_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/6XN60jWT4Ri1topI36XfZl/c84caaec4aa8c94272da1df724395b21/energy-duo-root-cause-mobile.png?fl=progressive`,
  },
  AREAGROWTHKIT: {
    DROP: `https://images.ctfassets.net/0rbfqd9c4jdo/2cg3ubITRc2WKs13EsSaKJ/373016d2224166b815eb67a5793b81bf/ga-img-4.png?fl=progressive`,
    DROP_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/4z1ncB3iZdFFvjD21skcjT/7947a44d2b553e9190089d04829ab03c/ga-img-4-mobile.png?fl=progressive`,
    INGR: `https://images.ctfassets.net/0rbfqd9c4jdo/7KpE8FPOM9cBfXtOooNZSe/01b5649a437f50f396aa79d1f99f41aa/fhk_ingredients.png?fl=progressive`,
    INGR_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/2KIvhKn4LVKQuyOCmHw0XK/37ea33194ac3a95024b501b1eb218499/fhk_ingredients_mobile.png?fl=progressive`,
    PILLS: `https://images.ctfassets.net/0rbfqd9c4jdo/5GVmrchZR4iymkT7c4UpMU/10e5becd430334179ab5876214e16702/Nutrafol-broken-pill-hair-growth-supplements.jpg?fl=progressive`,
    PILLS_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/3yHUccvuflJGoXJytlx4Ol/ac4597f11dc816fa4c12168f6f21d3a8/Nutrafol-broken-pill-hair-growth-supplements-mobile.jpg?fl=progressive`,
    VIDEO_POSTER: `https://images.ctfassets.net/0rbfqd9c4jdo/2PJYVKUFInmLjeIgXoDbvT/5cc71bccc90c96cb14f58a3977948cd8/Hair-Serum-poster.png?fl=progressive`,
    VIDEO_POSTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/4GAiJAAWg3Ksfht2xqiFw5/f9a48494806bfe00145068238cc4a682/Hair-Serum-poster-mobile.png?fl=progressive`,
  },
  AREAGROWTHACTIVATOR: {
    BOTTLE: `https://images.ctfassets.net/0rbfqd9c4jdo/5EryZ45rqN95f4F4Q83sf7/1a87b0bf3ea9b73c3fe5696a4e5e5718/Hair-Serum-hand.jpg?fl=progressive`,
    IMG1: `https://images.ctfassets.net/0rbfqd9c4jdo/19qJKDYF9xVnjCa9RLp3Hq/934deef84aa4a323b132b46fa6937288/Hair-Serum-Carousel-1.jpg?fl=progressive`,
    IMG2: `https://images.ctfassets.net/0rbfqd9c4jdo/4havF9Lgnuo6FEVAYmhja/869a22b2ccc414665595dd5e69f868b3/Hair-Serum-Carousel-5.jpg?fl=progressive`,
    IMG3: `https://images.ctfassets.net/0rbfqd9c4jdo/2X9Hpuo3zYkcT6QkF3Q17T/541a7d4184fcfc3c578a28b800ce1409/Hair-Serum-Carousel-2.jpg?fl=progressive`,
    DROP: `https://images.ctfassets.net/0rbfqd9c4jdo/2RdHdb7t8WQEltzJ9fkoNV/1e630dea2b2f2a35000e914332b99c24/Hair-Serum-Drops.jpg?fl=progressive`,
    VIDEO_POSTER: `https://images.ctfassets.net/0rbfqd9c4jdo/2PJYVKUFInmLjeIgXoDbvT/5cc71bccc90c96cb14f58a3977948cd8/Hair-Serum-poster.png?fl=progressive`,
    VIDEO: `https://nutrafol.com/media/video_storage/Hair-Serum-Desktop.mp4`,
  },
  AREAHAIRSERUMSML: {
    APPROACH: `https://images.ctfassets.net/0rbfqd9c4jdo/2xT9Hf79jyCIXe6Fo3TItl/e41cedeefc2059717b42a9095cc880c6/serum-imagearea-1.png?fl=progressive`,
    APPROACH_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/5olKRk9fQmlDbn5Q3mnkNJ/2dc51e42d064997399efac6d045bb74c/serum-imagearea-1-mobile.png?fl=progressive`,
    PILL: `https://images.ctfassets.net/0rbfqd9c4jdo/4Ztv7uVcvLw8c0O0YLPkhW/1d709f7a8b05a55c50e4b1c87a2c3ba6/serum-imagearea-2.png?fl=progressive`,
    PILL_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/24mswwEKTIcP8jPcxUCD8H/8341f96d6bafed2861cb2c6f0231169a/serum-imagearea-2-mobile.png?fl=progressive`,
    USAGE: `https://images.ctfassets.net/0rbfqd9c4jdo/67lHzevlyE9IxPh28Kwe87/d074d99836633480812be5bbf2ab94c9/serum-imagearea-4.png?fl=progressive`,
    USAGE_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/55LzvIgnUWZzwl6ENjKEOX/2d3f4e5deabd9ab6e501e5437303b868/serum-imagearea-4-mobile.png?fl=progressive`,
    VIDEO_POSTER: `https://images.ctfassets.net/0rbfqd9c4jdo/5KCHir9VYb6wzvpg7Qnp2i/1a6c41ceaaba80d33989022e375dc491/Desktop_Poster_HairSerum_PDPVideo_05.23.2024.jpg?fl=progressive`,
    VIDEO_POSTER_MOBILE: `https://images.ctfassets.net/0rbfqd9c4jdo/5Up4oapTXV6AYWaxUNz3ik/99f894d2584aeb1227ee7435fe6f0074/Mobile_Poster_HairSerum_PDPVideo_05.23.2024.jpg?fl=progressive`,
  },
  RESULTS: {
    JON_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/xKPrPUjjHujDxyn0b01g0/c63261ed552abfbf29645cf25b149eda/results-jon-before.png?fl=progressive",
    JON_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/WvPReUOtEAawwIrGDWlUx/807172f5941fbbfa5586abd49279b39f/results-jon-after.png?fl=progressive",
    ERIC_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6eu54wMg7A5CqFIAmURyRP/86ccd09188fcc769145815ea8d8e314b/results-eric-before.png?fl=progressive",
    ERIC_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/40j9q7HUFzfZ36lV6cCUtB/798928b64d2e47d025032607bc8c464a/results-eric-after.png?fl=progressive",
    KYRON_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/JViIQVKP0EeH212AAFTuF/78ac00373d2fb5f6c7f9c89c6429e04f/results-kyron-before.png?fl=progressive",
    KYRON_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5HyKayRFUYOVjYQF4TaOS1/95bb11cb7c73df531b2feff3b0e9969e/results-kyron-after.jpg?fl=progressive",
  },
  RESULTS_MEN_NOVA: {
    BRIAN_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3FFJ9USkrbbbYlBYMmuik5/9c02ca3d0ab73c5ed0ab2a188dce4b10/brian-before.jpg?fl=progressive",
    BRIAN_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4KYb93oX0pzujuGyK5YGNG/2906ee3c7ff35e6f693a9e3734778438/brian-after.jpg?fl=progressive",
    BRIAN_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5y6AMNMrNOo1l0DFEFrJ9W/1af241ab24e542962fccf558e7b2d65e/brian-tn.png?fl=progressive",
    MARK_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6TOcwnB4NnjPHj0sluC4Nx/e0a78d11b96ba5540af5a86d2141c840/mark-before.jpg?fl=progressive",
    MARK_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3ScIEjgHreCdZXpLRrIIp8/6e5fe1e11da07f2663eb2879fd05769d/mark-after.jpg?fl=progressive",
    MARK_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7aMyR6usRwz0A7kzKz4YMK/d82d55676eceec8c3b7a44f83c996ae3/mark-tn.png?fl=progressive",
    JIM_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3VjHlJ0wJMiNxflljLQooz/538138e923fd676c3d44215524269357/jim-before.jpg?fl=progressive",
    JIM_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/FplJWF9tsbyqAe7xiNFxs/2976649a74881683731b73908aa7ab9f/jim-after.jpg?fl=progressive",
    JIM_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2S4VaAHC2laRcxHqhaIN4u/6651ae9994d58df3e62b878f3973d39c/jim-tn.png?fl=progressive",
    JOSE_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/77N8bXOfWlctu7muENUjJC/8c2eda973ccb57a0f471380266149aa2/jose-results-before.jpg?fl=progressive",
    JOSE_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3B5RpTOEayhhWfqitaNXYo/07f63dcdaa3e492e1d1908c4402ca7be/jose-results-after.jpg?fl=progressive",
    JOSE_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1ngkM9rlVGuk0BdeOBD12y/c03633650afa88e08d6cce5c3f889821/jose-tn.png?fl=progressive",
    ANURADHA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4jWJIyEbnSWCdOTMArsAm8/8447b30523b0e0c6fa29e5ffc7a98aa2/anuradha-before.jpg?fl=progressive",
    ANURADHA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/SfGE2q6qcgFFststehZVd/36e5e5d32c2d6a7e9f7059a191dd47a0/anuradha-after.jpg?fl=progressive",
    ANURADHA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4f9nbQkLbv57VVT9GYb8I1/4be755be285d8e5623538763ea0bedc8/anuradha-tn.png?fl=progressive",
  },
  RESULTS_MEN: {
    ADRIAN_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/41rC9RMltP5Hv55XCJfSL4/76c639ce264ffb4c4572cb117bde2255/results-adrian-before.jpg?fl=progressive",
    ADRIAN_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7rj5oV8CmPum4mKSNs4Hbk/27a03d7e300805dfbadcb2ca5aabc957/results-adrian-after.jpg?fl=progressive",
    ADRIAN_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7dmPsCpDISvVQPRnMEmn5P/8a45a3a6387a33b6dfd6e5f20912ea60/results-adrian-tn.png?fl=progressive",
    ANURADHA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7eRmp4KPu6Pdry55YOTTwl/e48664ec292aa5717768a09db0afab35/anuradha-before-mens.jpg?fl=progressive",
    ANURADHA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1ChmeFi7nbvtV0D04gifdn/074f39a3cdc80b57194b084b50593905/anuradha-after-mens.jpg?fl=progressive",
    ANURADHA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4f9nbQkLbv57VVT9GYb8I1/4be755be285d8e5623538763ea0bedc8/anuradha-tn.png?fl=progressive",
    BRIAN_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5v6BDgqZ64P1Ajr8G1pHEx/8b99d74a3be87d002576e8c93c500b54/results-brian-before.jpg?fl=progressive",
    BRIAN_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2zS0FThsXTOhzMuuqBuPpz/3ac332da9316cac7211d6ab85044d648/results-brian-after.jpg?fl=progressive",
    BRIAN_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4Nr4z81RFTRCNVWtC1TaGI/075a14dbf1bd4ace019e22488034f4a4/results-brian-tn.png?fl=progressive",
    DANNY_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5RliUcTXo4VwtooBSGbYMh/e7156dc88aa691cbc4eea0c06adce7b9/results-danny-tn.png?fl=progressive",
    DANNY_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7haENw8YyfD9hXAezUwYxN/8f7b9e82046abe5cee09b1401945b3cd/results-danny-before.jpg?fl=progressive",
    DANNY_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1sB3v4LQPfBbteRAolHXkg/705e40f85ac19d25800c07457f645292/results-danny-after.jpg?fl=progressive",
    JASON_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/cQcFWm72xcw4HdpSdr5Hp/fc87201ccff8f841dbfee44759913b1a/jason-before.png?fl=progressive",
    JASON_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6O7RTsGWygUlZGXocBbtBg/80874559b4ee8e9bf4bfe61089705913/jason-after.png?fl=progressive",
    JASON_BEFORE_ALT:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6tYiJh7Ch8vq3Q8pY38xst/8a719f5026918e94a7be330c20311d6a/jason-before-variant.jpg?fl=progressive",
    JASON_AFTER_ALT:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6tTei0FxzRn026pjLUNC42/6890e264a3f97c607e89758abbe3cb4c/jason-after-variant.jpg?fl=progressive",
    JASON_BEFORE_SIDE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5QasAwopktlS0nPHumXAAZ/5c1d568211e2bd97e1fc3a55aab49d57/jasonBefore.png?fl=progressive",
    JASON_AFTER_SIDE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3ytBlrQZBtK73J2Er91gc1/9472c47292813b33dff87145a5af78ff/jasonAfter.png?fl=progressive",
    JASON_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/31jdDCgiaZXymCczUbJLFM/d46cc8b8d4651d20a8062d175a3e7e8d/results-jason-tn.png?fl=progressive",
    JIM_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6eZZiiyAXldrw9KUm5xzF1/10ea56c3224b0832642c4892412e5c0b/jim-before.png?fl=progressive",
    JIM_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6j2KSzzoNjOQC11Iu883Qw/e3b67166f9f20a2860793623eddf4b66/jim-after.png?fl=progressive",
    JIM_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2AzHECe2QXqN2TNSB80qx7/7b143121ac863639bce1e42d59465dc1/jim_tn.png?fl=progressive",
    JON_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/xKPrPUjjHujDxyn0b01g0/c63261ed552abfbf29645cf25b149eda/results-jon-before.png?fl=progressive",
    JON_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/WvPReUOtEAawwIrGDWlUx/807172f5941fbbfa5586abd49279b39f/results-jon-after.png?fl=progressive",
    JON_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6fOJLag7d9r9YvubJmT54z/53ae02a5f19ab0548e66aae391716fe1/testimonial-thumbnail-jon.png?fl=progressive",
    JOSE_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4HwdEVlMaHafkR69ABvlLA/59ecbf7b77809bd5c8179afdda5746ed/results-jose-before.jpg?fl=progressive",
    JOSE_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/792r3yeH9yEyxDSiy5jUVL/ddc4852b26056f6182dd043e9600f5d7/results-jose-after.jpg?fl=progressive",
    JOSE_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6y0VrZiVZi5kIb71Ktyyc6/50489539f8dc3548571827f9d20dc573/results-jose-tn.png?fl=progressive",
    KYRON_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3qFK4TYihGltvASJ6asFm/f85e38de1ac3d87c50a2ab53c0a73585/results-kyron-before.jpg?fl=progressive",
    KYRON_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5wyqqhlIJuJWCm10CMp4YG/c1f9775b9f694548802dab6a67d5c4ba/results-kyron-after.jpg?fl=progressive",
    KYRON_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/209OiVL5ylmig82bNdD2ck/2339369571badfa28db4f6e31756851d/results-kyron-tn.png?fl=progressive",
    MARK_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/Mtzz3DInwTCm0IeDxoXgG/7f38c0b715ad27a43ad84621a2baddf7/results-mark-before.jpg?fl=progressive",
    MARK_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5RUEe0gpFYXNxucbr11sJq/097ab566f8e1c2d69e9670906c09f90b/results-mark-after.jpg?fl=progressive",
    MARK_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5JA5omwGhwmLdcOnQyugoO/132defb54a98e9830f08fdbba80740ec/results-mark-tn.png?fl=progressive",
    SAM_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2OVsJknIT73T1hHnV90Rvv/60599325a54db480393e72f76e36692a/results-sam-before.jpg?fl=progressive",
    SAM_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3134KIIA2HlM58Ue1MhF9N/49900ce1d4e5894da2c0f8d16d44fcd5/results-sam-after.jpg?fl=progressive",
    SAM_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7n1o2QvgU2ZYh2l2X7Qpwb/22cb7aa81e9f0bc3bb346f360865b374/results-sam-tn.png?fl=progressive",
    TANNER_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2VdVLiktLvaLKtiCryz9WQ/0a618e42557154da16869b71f01c2e5e/results-tanner-before.jpg?fl=progressive",
    TANNER_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7z5Qv10ckNV49W7klE9xvj/b084b0a070dd4085ca1f8e5a3eaa79ee/results-tanner-after.jpg?fl=progressive",
    TANNER_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3TFkYUy8g0HjfDwoNekSgX/c486c5db3159ff59bc42756e2a67aac6/results-tanner-tn.png?fl=progressive",
  },
  RESULTS_WOMEN_NOVA: {
    TANYA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4bjabl4coq6lMcDFo1o9Py/3b71867238f0b5f88aead6a30012fc2c/tanya-before.jpg?fl=progressive",
    TANYA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1ExpN6f0hL2hEzE8n0TYL1/ff9c5dec5ed3d97c0af53ef559e81c6d/tanya-after.jpg?fl=progressive",
    TANYA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7cwXJtwjFizQmtLhmE4ryi/2ee415e0fd2417fb899922da1ec0397e/tanya-tn.png?fl=progressive",
    NEGHIN_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/17c5ssH0L1959Mk2y4OwLr/6bf7f8942505ff3bfc57ec05a9aa2b66/neghin-before.jpg?fl=progressive",
    NEGHIN_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/kEyIBiMRhmSXDMXiTPb3Y/9c7dd9e6a3c0f6f160d684fa7d67d18f/neghin-after.jpg?fl=progressive",
    NEGHIN_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6fFA1Mk0nHVyN316cN0FYC/f2628783ca69a6cc2788d415bc531ea3/neghin-tn.png?fl=progressive",
    LILYANA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1xRlzfxyiGtul5keYo7la2/62227ee790d40c2d95694dd8e731ffa9/lilyana-before.jpg?fl=progressive",
    LILYANA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3bTG65ZRBM16CYRaryFd1m/ed117c9db2b2c7c1b6fc153996f21279/lilyana-after.jpg?fl=progressive",
    LILYANA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4VmLt6HiUM2YM5dZv7ZDDC/7db2deb9e90665ad63a65c2e1bb3e428/lilyana-tn.png?fl=progressive",
    CANDACE_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3aiQpUBjJukntnudsG0VpZ/a8c500f531011eccff24e4af787a6c79/candace-tn.png?fl=progressive",
    CANDACE_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3T8QdZ9HE95NKkQPOLCyRp/a44be307b024efca832e99c76050b3c0/candace-before.jpg?fl=progressive",
    CANDACE_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/225pQdIsMuhCtX039rQthx/2a73cbc85b0a4e1bc450893efbc1290e/candace-after.jpg?fl=progressive",
    ALIZA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7Ev96872PFjQ0qdTUOXsne/33a4f0c1f598556997a7f2b0f84c30e6/results-aliza-before.jpg?fl=progressive",
    ALIZA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4zvrKJyGmqeqTWQpZIPUw4/6298a78625d6439d0d8b99a1d2ba75d5/results-aliza-after.jpg?fl=progressive",
    ALIZA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5PUyZstakRVfxavx6Vi27X/a3be831e2eacce95ef20dfdc86feaeed/aliza-tn.png?fl=progressive",
    CAT_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3SkzhKFdHUmTClFS6InRYn/d00e786ebd75b3fb15ae3520e70c28d9/cat-before.jpg?fl=progressive",
    CAT_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3PBhPehNiRaW7GflwVtmgk/815a35328ef3152cb75ab61094cfe638/cat-after.jpg?fl=progressive",
    CAT_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/a5NTTdrqknaQvrL6pqXuT/4da31d257e956e31032fa7e65e3d8092/cat-thumbnail.jpg?fl=progressive",
    DAWNTE_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/OOjEn8opzsNmMdBkZTjLk/851f71932473b9ce9b6385486b663f38/dawnte-before.jpg?fl=progressive",
    DAWNTE_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5iHI9fkvqUpBLIUMhL4YRs/0be8a4fc4f4ce29dcf97ee58bd7fbeac/dawnte-after.jpg?fl=progressive",
    DAWNTE_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4II84OX7C4zYv6UADFxWBi/17a7040d7e6043e0ed2fb5887477b565/dawnte-tn.png?fl=progressive",
    ENSLEY_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1Y46VR7qPjraPCSLpVyK4w/d31ca786df807594a797997cd2392848/ensley-before.jpg?fl=progressive",
    ENSLEY_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/BxLkhnKfRuyiQtHoc2zIV/5cee6465bb5698cdb857648ae0321de7/ensley-after.jpg?fl=progressive",
    ENSLEY_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4rMCbjab6kyzxZukGv8FG/e88fd06309cc9aa081c72fc99f699fef/ensley-tn.png?fl=progressive",
    ERRYN_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2LjZibvbgkdN6LJt5bAW74/b24a6129d1fc522c18790974d8260391/results-erryn-before.jpg?fl=progressive",
    ERRYN_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3ItcbWapqu3A1qEKKhIPW/052660c21f1fe4f13e9237e698e14419/results-erryn-after.jpg?fl=progressive",
    FELISHA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/17d6sUUPcGzGQVyQXjwOXF/eaf915ab33b7297ab84840f4adfba777/results-felisha-before.jpg?fl=progressive",
    FELISHA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1u3YVvTmtz9yBRXEVdYkI9/f3030cb8f6a6dd7cb2fc83bd368dc7c7/results-felisha-after.jpg?fl=progressive",
    KARIN_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3tgepqXwqPg8xXjVtIzuKm/073da3a696b5df91c8a46e782ad16366/results-karin-before.jpg?fl=progressive",
    KARIN_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2oTIyZxgh2Oga8CYDkTJhb/5f254e2d8164dd2e47e9de9adc5396cb/results-karin-after.jpg?fl=progressive",
    KAYLA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/14NgQM6WHR0QXGPSkVNHxW/c4acb072f4f8ccf3b69e5b9c732f7bbd/kayla-before.jpg?fl=progressive",
    KAYLA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2kW6nTp0nsC7uKINhjosqN/b1d754e8ec5c206221147bc94a0a6be9/kayla-after.jpg?fl=progressive",
    KAYLA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4UxuIVhoSY2jUJ22EkrVd8/8ae89c6c5fa467bfa9236071bdc04505/kayla-tn.png?fl=progressive",
    KIMBERLY_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4sXJrLHbTNOhV907aMw9y6/d851a18c81f85c9fef6287de37ff1ee2/kymberly-before.jpg?fl=progressive",
    KIMBERLY_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1utBNXOPKA8tAm421K2Zlm/ec44e87ecc7a1474656de83d11675947/kymberly-after.jpg?fl=progressive",
    KIMBERLY_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3SkFGZVTPygQd3l0RKQphi/9025bebf886dfaaa3f750e10d6895b87/kymberly-tn.png?fl=progressive",
    LINDSEY_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/BS8cKZUgXEii1MtxfLBwE/cf7b2326433e6a5aaef74570b455a477/lindsey-before.jpg?fl=progressive",
    LINDSEY_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1wm49DNiKyae8X7HzFXZWj/6a58ce69de46d35b470d59f5945c7a82/lindsey-after.jpg?fl=progressive",
    LINDSEY_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2WTKsYFX2oIQuigRHdTE0u/19c02ff3e2c9983ef6b6613a347af829/lindsey-tn.png?fl=progressive",
    LYDIA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6NDf4pZPpeBNjmYHofj6Ik/08a7c88e229bf97eb6d12aef9b4ebde1/lydia-before.jpg?fl=progressive",
    LYDIA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5kGKS7yY2hRGEDY0uvSapI/a66c467f51ae9300a5242290a0ccc829/lydia-after.jpg?fl=progressive",
    LYDIA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/A4njNCDnjUHxTcYVsKP4m/4347044f1afcdcdb5a9238ba6b8be3ab/lydia-tn.png?fl=progressive",
    MIYOKI_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/76WiJXsF6Hc7xbv36uKibQ/7138d3c72ac3071b585c19c02b76d94b/miyoki-before.jpg?fl=progressive",
    MIYOKI_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4tSqg4JQ64QXSR3gDVadQJ/81cbee1df590a2cbdf7adeae36ce118a/miyoki-after.jpg?fl=progressive",
    MIYOKI_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2f5I3AvS8vRS4ZO1xTnz5M/8a36b91cb9ae1a9e962c0ac07c4c8cf3/miyoki-tn.jpg?fl=progressive",
    NAOMI_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2qZwBTMjm7NrUmFT8b4uhK/c66e025f42713774b4ffa01118eb5b88/naomi-before.jpg?fl=progressive",
    NAOMI_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/X6eOCg3OQm4Za6n2224U3/c662448a2009b2ea1a72165334db89f5/naomi-after.jpg?fl=progressive",
    NAOMI_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1u5fG0I25KAYAMmjntcnwe/f3f6c05bbaa18376e76381ae51d30fd1/naomi-tn.png?fl=progressive",
    TERRY_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/43pLIiWz25gB1OnOh0awDq/a311b3a44d68070be0c9f1e1ef959faf/terry-before.jpg?fl=progressive",
    TERRY_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3TOdFOUtT2jOx4jehfml4F/0c29eca9438eccb45268eb26c8719423/terry-after.jpg?fl=progressive",
    TERRY_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/YPmcP8jhLitYWHsLJ0Y5D/c59aa8b2a90c68aad7dc955450f18db9/terry-tn.png?fl=progressive",
    DEYANNA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/YATmP3czlL0SfsGXicGkJ/c4836b9b76ebaa5002de380004c56657/deyanna-before.jpg?fl=progressive",
    DEYANNA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2crlNtMvWYD5o2oIyjskuC/d9caca5e4a7938da672808b8cb5c8ff5/deyanna-after.jpg?fl=progressive",
    DEYANNA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3hlfwTwZSkOVFYYwJEvXHd/7bdfd9e130a88486d48f856a5c3c0725/deyanna-tn.png?fl=progressive",
    SHERRY_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2nTDH9PtgIpUGeBwYcJRDr/a8dcc9ea7bea54d9eac98c2bb34614d8/sherry_before.jpg?fl=progressive",
    SHERRY_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/41MczbiT2znpGx2MEyfGUx/385e61dc4d8e75aa7dc118b050445466/sherry_after.jpg?fl=progressive",
    SHERRY_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5gBj34ywFZK1SYRcPET3XU/d531b16a00a94e45b6ad8d7189007bc1/sherry_tn.png?fl=progressive",
    ELSIE_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/60p3T2PrNXY9hqgCjL7nob/f1993a9e427c8c249e52b810fb00d1d2/elsie_before.jpg?fl=progressive",
    ELSIE_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/249JCgqULl0TctP0FzkW5y/e80d3eb8438bb399ec67f0a6232d83a2/elsie_after.jpg?fl=progressive",
    ELSIE_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2vFrQMiEIsT2LZleIO6muM/758b2090a48d75d145e9a0bdbb12984a/elsie_tn.png?fl=progressive",
    JESSICA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/40421xca10oeHomvk1QCoo/64d3076d7e26c4a09a40b8ce645cdfa9/jessica_before.jpg?fl=progressive",
    JESSICA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/365ZgMrB0FK2ptopmbyLtB/020b74dc3fef01355a854ec0ed0443c1/jessica_after.jpg?fl=progressive",
    JESSICA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7v74oWlC9M3FggZGo3kyuj/a74f2c3a78d05d4f4d14212640214e2b/jessica_tn.png?fl=progressive",
    FRANCINE_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/53cZx3gTmdoDiZYfxdjKBu/b45d06fa8f390b992eb5524a474e45fb/francine_before.jpg?fl=progressive",
    FRANCINE_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5O43OCZ1mZmWQGvCs9CGdn/4e3a3dda945bb4a2b02fba2d3c3e92dd/francine_after.jpg?fl=progressive",
    FRANCINE_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1lTjYwdGgVSax3p5fcZgZl/0469bbe568103d4be5b0abf9d7f1fd1a/francine_tn.png?fl=progressive",
  },
  RESULTS_WOMEN: {
    ALEJANDRA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/cccXHMFlOXFIvHrfOUSWW/91229a8a3b6c776072d74bf66efcae4f/alejandra-before.png?fl=progressive",
    ALEJANDRA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/57dFpVCfRHqFCiK27zq17A/3a89a788b304c6afe77804b95d668f41/alejandra-after.png?fl=progressive",
    ALEJANDRA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5WRB7KMu9AfpmRkMIzcwfg/003136302d6d810add3b381f3910423a/results-alejandra-tn.png?fl=progressive",

    ALIZA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3HDjbl78Aj6nWXqkSmqhgE/2834c40a8adc2f79555416a4ba139fed/results-aliza-before.jpg?fl=progressive",
    ALIZA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3uFiAmHU5fLcbFbzbYO1jx/2e0d8c51c5e13245d667a42a12bc9f60/results-aliza-after.jpg?fl=progressive",
    ALIZA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4uWO1yOJKPr2tUj1dUWV4/83e8a047f1ba10e5f7e875448e893309/results-aliza-tn.png?fl=progressive",
    CANDACE_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1FJihP22Hl2P5YXGRvZsUF/7274c00a61580dd7f89c36c2940b3667/results_candace_tn.png?fl=progressive",
    CANDACE_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7pAY0AHNXbdW6kl70UkRsn/8e2c5d5f0ffbc8b72f8f8cea887eff34/candace-before.png?fl=progressive",
    CANDACE_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5FxISlpVC8e4IwwGPVoMaa/1961d05019c48eb38bc7a6a93f1cb069/candace-after.png?fl=progressive",
    CAT_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1oA7bQt13qYIfcgD0BHcZG/ad73b9fc90a7f9fa7b0a9d0a010bec6c/results-cat-before.jpg?fl=progressive",
    CAT_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/Dic0eJIeBU1gMqfliPXhi/b190858e9818b7a6991e37b647d9813e/results-cat-after.jpg?fl=progressive",
    CAT_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/73PQSydccj8RjbB7qYWjHN/1c807542dd23a53cd0d23cef1b8694aa/results-cat-tn.png?fl=progressive",
    DAWNTE_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/40TcMeze1owB5uhOndhw8b/75445956ad741afae8ca694f22fb4d2b/dawnte-before.png?fl=progressive",
    DAWNTE_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7BGYWK9tiZVz5aXNeaCCgS/0e970831ceca767d071be42cab243af4/dawnte-after.png?fl=progressive",
    DAWNTE_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5bEnz4eYTumQrb0wk7bACq/135d1a33d775ee8bda24a739b77d85fe/results-dawnte-tn.png?fl=progressive",

    ELSIE_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7DAJC6IvXCPl40J9KG3rWj/3a50ec8aeaf8f59d31c1dd61e1289df7/results-elsie-before.jpg?fl=progressive",
    ELSIE_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/59KXMFFl9PMrs8xVkOvTUP/3c94512e9ddac0fdf7fcaf8ca4d32c78/results-elsie-after.jpg?fl=progressive",
    ELSIE_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6hClwa3hPKyMUzLg8K5V2y/72b8e5c07154cf6a659db845f3851b0e/results-elsie-tn.png?fl=progressive",

    ERICA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/49FV6ZRDP12BKH6I913qOp/69c6aef804ee1455be97bb34599dde55/testimonial-erica-before.jpg?fl=progressive",
    ERICA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1cSMVPp6JjFFW4T1ab3mJX/ad66725fe6e1a84146a86bef0c221d71/testimonial-erica-after.jpg?fl=progressive",
    ERICA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4GUV6BoMWSyi8pcRNcObdE/46dc9af35506c3e4a33481e20930b27a/testimonial-thumbnail-erica.png?fl=progressive",
    KRISTY_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/JAA9M1Hl6KSbFEeJuIpme/2eed1bb4d3091ee0b9b95e6ea0a4311e/kristy-before.png?fl=progressive",
    KRISTY_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7ED09Lpfy58HcpVT2KJYev/aef79ba73d058a757bbd085307613be2/kristy-after.png?fl=progressive",

    SABINA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1uQfa2Tuh9vMl9rWSakgHQ/69f74276f436e785ce5fa95e78fe35d0/results-sabina-before.jpg?fl=progressive",
    SABINA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6fLJ665NStauHjstkDd2pH/238a6157d368312dc139ae9706ee6612/results-sabina-after.jpg?fl=progressive",
    SABINA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4AzISonZDo3ctvCV5mxEUD/23fbae52846411e9c78e9039edc99697/testimonial-thumbnail-sabina.png?fl=progressive",

    TAMARA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6CwEbk5XQHSJ308VD03LyL/c4ab64ea090a3e259b71030c58380d7e/testimonial-tamara-before.jpg?fl=progressive",
    TAMARA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5GEAZU4yp8elxHzkA3F2pQ/6e534628244c9e92c35eab3352aabc1b/testimonial-tamara-after.jpg?fl=progressive",
    TAMARA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3hGC4VTXBch88c6JwFC8OA/b400f87b321cc7944028bfaf11fc696e/testimonial-thumbnail-tamara.png?fl=progressive",

    ERRYN_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4LOhXRUSWGBvuo3q1A3JMy/e37fe8bfe78d30bbb15b5d4f753442e5/results-erryn-before.jpg?fl=progressive",
    ERRYN_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/51rUJ7mC4r7yF6TsVobsrQ/18fdac8a3b056feceb428d3979d5fd87/results-erryn-after.jpg?fl=progressive",
    ERRYN_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7c3UsVEVumKig1lRLFlJw0/cd3c6fe56088cb25a43852ae3fa1c8e6/results-erryn-tn.png?fl=progressive",

    FELISHA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4HUGW0luxYKjWF3LYAu8Q7/59018a8d13ae89a2cfd4f4cba7522ee2/results-felisha-before.jpg?fl=progressive",
    FELISHA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5Qul37yttmDLzcdFEpRo0h/572956326872b60552f4bcf8fe9df273/results-felisha-after.jpg?fl=progressive",
    FELISHA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5tSsUmkEvxo9QwMPlOevJZ/70a4bfd2b1ac47d05c63c7228bd1c8ee/results-felisha-tn.png?fl=progressive",

    FRANCINE_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/53cZx3gTmdoDiZYfxdjKBu/b45d06fa8f390b992eb5524a474e45fb/francine_before.jpg?fl=progressive",
    FRANCINE_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5O43OCZ1mZmWQGvCs9CGdn/4e3a3dda945bb4a2b02fba2d3c3e92dd/francine_after.jpg?fl=progressive",
    FRANCINE_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7xcQULPzpCLVCgdHepoWGS/0ded70d2b284aec1f5024154ceda90ca/results-francine-tn.png?fl=progressive",

    JACQUELINE_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4UGcGnPs65D4MZkkOIOamc/923cce91f826ab4f20934274213a7bac/results-jacqueline-before.jpg?fl=progressive",
    JACQUELINE_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7ayEW1V5aN5LG1zMhzy1jd/27f12c4d27d32084f9928a652a495516/results-jacqueline-after.jpg?fl=progressive",
    JACQUELINE_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/71KS7ekG1ANXDtvXhEK1uE/205882db9868698325aefa905105e5ec/results-jacqueline-tn.png?fl=progressive",

    KARIN_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6p5RFDA8BGimlYELz9WS2f/ffc49b7b6163c7db081f55304c4a809b/karin-before.png?fl=progressive",
    KARIN_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5ocCvIGh4Dj8Gio7j9tBWI/be3f075b4ae72ac9ecb407a4935db0dc/karin-after.png?fl=progressive",
    KARIN_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6viBCDtILjuc6ZJpQxk538/f89b2cf7376cd756b2205bc1f6010a34/results-karin-tn.png?fl=progressive",

    KAYLA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/14NgQM6WHR0QXGPSkVNHxW/c4acb072f4f8ccf3b69e5b9c732f7bbd/kayla-before.jpg?fl=progressive",
    KAYLA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2kW6nTp0nsC7uKINhjosqN/b1d754e8ec5c206221147bc94a0a6be9/kayla-after.jpg?fl=progressive",
    KAYLA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6AQzYfpyWCHXF89APtBHuy/012aff9164f9dc938ac0ab8458852467/results-kayla-tn.png?fl=progressive",
    KAREN_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/dEhV4hRPlZNocJ3M3WGDT/4a34f3ccb4771628a935bee07e49fd34/karen-before.png?fl=progressive",
    KAREN_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5FiWWZYWdiCgcPuskUETsK/27025502263662d814b25f0f2539898b/karen-after.png?fl=progressive",
    KIMBERLY_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3buMYH4Z10sfAJuHfhHnzK/d58a3342226dbcbe691601d68c78dc08/kimberly-results-before.jpg?fl=progressive",
    KIMBERLY_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4ZCqEIhQpRUhAM2qayNAHY/a758d70839c03698a9bc3b1034c90b49/kimberly-results-after.jpg?fl=progressive",
    KIMBERLY_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2HdaKzbj4evObBOnZGPfOo/9a8df1476a81d82b6512736bad2e98d6/kimberly-results-tn.jpg?fl=progressive",

    LERA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5y0s68Hnoc7DlkXbeZ0qwO/58fce942da900e41f08429f8b014e090/results-lera-before.jpg?fl=progressive",
    LERA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1q0ZIAyacQozxd68Nl7LdX/8b8088bd9b2d2662dc6c9c6c91b0e183/results-lera-after.jpg?fl=progressive",
    LERA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6CYcwNLVwe734I80kXFZs1/33afb17efaed7f6d9cc6a308228f7463/results-lera-tn.png?fl=progressive",

    LINDSEY_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4h5N9e1GexNDZ7w63i8qn8/a63bbb51206d3302628f19ff77ceaff2/results-lindsey-before.jpg?fl=progressive",
    LINDSEY_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6diS7N7XZInrcryurHAIo7/c26e3d3fce77e3651a17bca29d89fea1/results-lindsey-after.jpg?fl=progressive",
    LINDSEY_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4Th5ScfDV57mvfTDIsL5Ad/841077503ce3950b8ce5d90849952a31/results-lindsey-tn.png?fl=progressive",

    LYDIA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/uEQZQ27FaMeUVj5tGRjxk/cc69aa49b7a3552e43cf13ca934a6750/lydia-results-before.jpg?fl=progressive",
    LYDIA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1ejez8QTlvjtRrTzKEvoft/7565e422e9f141a8ffddb8978c703724/lydia-results-after.jpg?fl=progressive",
    LYDIA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2XanaiY8MnmHzjTWBB0yTt/30bd4e332dbbee84b2da68b7f4090ebd/lydia-results-tn.jpg?fl=progressive",

    MIYOKI_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/35o2OfuVEZIwn1ZExdbrCS/5dffaf3645f9cb1ffaaa1290e4833e3c/miyoki_results_before.png?fl=progressive",
    MIYOKI_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4GKqIatcHDCTdxGBCGVfg8/777edaf1c70153a397091fa27c4045b5/miyoki_results_after.png?fl=progressive",
    MIYOKI_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/76GTpssoFSJNdpH8Qn9mfs/f9e10351502ce2c8b39f9d1e5dac50bd/results-miyoki-tn.png?fl=progressive",

    MITRA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/XKCP2lF2BfXs442ziGzE9/f51efe2ff8d8df7f7e8bcb018d3c4982/testimonial-mitra-before.jpg?fl=progressive",
    MITRA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6rIWhIVk8WKeEVxhf9jnST/6b466b88c2543450f4bd01f5e5ddfb60/testimonial-mitra-after.jpg?fl=progressive",
    MITRA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4DjLtDcSMD3hTsx7nSl1zm/07b569045eb24ffb44d7daeb3d82cf73/testimonial-thumbnail-mitra.png?fl=progressive",

    NAME_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/v8pJKtEIBcYC9QNdziwtQ/76d029ac20cd1f68a2cda7b63cd93bc2/results-name-before.jpg?fl=progressive",
    NAME_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/57WgE6TlpG1EQTl5XqP4Fe/2bd7b2d4228bc593e5f4ae9da46a310d/results-name-after.jpg?fl=progressive",
    NAME_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1YWZyKaYZnKb7Bo8yB31DR/2186434c50d4ddeb9825ad8f3a552e3a/results-name-tn.png?fl=progressive",

    NAOMI_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7fTnef8pxfYvuIRgDnAY3a/5728bac4c909046cddc0c777df9dce7a/results-naomi-before.jpg?fl=progressive",
    NAOMI_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6402afQQcfn8cUivQbDp7K/3ca5ad9e0f2f4f3dafeac41bc766636c/results-naomi-after.jpg?fl=progressive",
    NAOMI_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3GY52VAdaynriBvqPdjxs3/4bed29566829383378da27423eefbcac/results-naomi-tn.png?fl=progressive",

    NATALIA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1sUeT4cxKZVwIMuxULgg93/de305050ba2339b73b986c3a20327b4f/results-natalia-before.jpg?fl=progressive",
    NATALIA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2N6kVEmNaH6bAUJfFeDoAF/7df774dde3adf7c604748365dd3538b4/results-natalia-after.jpg?fl=progressive",
    NATALIA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5ypE3PLdIGkm7TpZStsf5R/09446eb875840bd07e49c148f85bc9ff/results-natalia-tn.png?fl=progressive",

    SHERRY_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1zQbCCXMTwH3aKk9QOONCe/9b6dc91f30c5f5ff76a03a7487609e94/results-sherry-before.jpg?fl=progressive",
    SHERRY_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4TaNH4Z0ttizljtedDafAW/e84613810b82f257468d7457779a3430/results-sherry-after.jpg?fl=progressive",
    SHERRY_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3UPpf5Z9IiCY8R3cAiewgq/1e772be883074512bce505ab632e0bdc/results-sherry-tn.png?fl=progressive",

    TANYA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2zzCEY5fap7iJmS3ArwBT5/e639ed735a0a5945a437b46d184deee2/results-tanya-before.jpg?fl=progressive",
    TANYA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2Dm27bG5RVfEOK6ZmFikFj/bbd3ea82eb720bc7c327aa16949c73b2/results-tanya-after.jpg?fl=progressive",
    TANYA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6Ug1rgxKGHztdXdmidbiYN/c8efab574a2941d29daa25009c725673/results-tanya-tn.png?fl=progressive",

    TERESA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/12ebKVs53O4W7UNxKBoW0M/2269d76e0a39e0297ef84b6c9419ad3b/results-teresa-before.jpg?fl=progressive",
    TERESA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4Ej4jMG2Cz6v0qgclTFTBc/dbd689f7651bf49a2f35e5224b958050/results-teresa-after.jpg?fl=progressive",
    TERESA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/11XvpdeNa4GHgwGlEaGQ8z/76fc94b129497a2281cdc039a23c500a/results-teresa-tn.png?fl=progressive",

    TERRY_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4sgcbw9MMA1rQDVjnb17Ws/14b907dcb5507f48910f27334b6e84ee/results-terry-before.jpg?fl=progressive",
    TERRY_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4gE8glM01tNVMGLFknz7YU/ddda8ab9ecc7e2f51c6cec3ed4502159/results-terry-after.jpg?fl=progressive",
    TERRY_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7Hqm11Hqcr66NwiQEfSbFM/f925714aacb0f285c4bf640459a122ea/results-terry-tn.png?fl=progressive",

    WENDY_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2ENeP6B612bv9Hy5fFLIkB/47ec56657d37d9f0742fe02b087b9f38/testimonial-wendy-before.jpg?fl=progressive",
    WENDY_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5BniqgdaYSe9uHpuuQIA4A/aaefa895d73bf6896e88e634a6c9394f/testimonial-wendy-after.jpg?fl=progressive",
    WENDY_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2jks3IHJRrqwQ6R172Pbe1/8a305e93d95bb8fdf7a0275e41bfa633/testimonial-thumbnail-wendy.png?fl=progressive",

    JESSICA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2EJBtwCYPzMgNPyR3wMBfU/05107abfdb3dd65b98652607365c0bcb/results-jessica-before.jpg?fl=progressive",
    JESSICA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/45GV6GiwKtvRJs6wEZQy8R/41486816e9ccef8760e8e45fab373acc/results-jessica-after.jpg?fl=progressive",
    JESSICA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1CvZNgX8ro9D1TcYjcbwL4/c08bb5ba3b080b94755ddf2a29880769/results-jessica-tn.png?fl=progressive",

    DEYANNA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/YATmP3czlL0SfsGXicGkJ/c4836b9b76ebaa5002de380004c56657/deyanna-before.jpg?fl=progressive",
    DEYANNA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2crlNtMvWYD5o2oIyjskuC/d9caca5e4a7938da672808b8cb5c8ff5/deyanna-after.jpg?fl=progressive",
    DEYANNA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3hlfwTwZSkOVFYYwJEvXHd/7bdfd9e130a88486d48f856a5c3c0725/deyanna-tn.png?fl=progressive",

    LILYANA_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7fB7wW5SnEdxbzKVPmfaNm/5e6e7d58e023d2c953fca9abd43c3a03/results-lilyana-before.jpg?fl=progressive",
    LILYANA_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5KNIiJ60iUYNIWngpA44lP/558396d9bf8051f8cc1e7445c6cd16bd/results-lilyana-after.jpg?fl=progressive",
    LILYANA_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7q7JuL0Sif6Z5yYBooZDYh/f955575694cddd2d439f74396dbf9329/results-lilyana-tn.png?fl=progressive",

    NEGHIN_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/227Hs2DcjQTpZJfDs2LQma/e8c6603ff8ce2d4fd968b947ddafed80/results-neghin-before.jpg?fl=progressive",
    NEGHIN_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/2gW4kOIaA3Qe1mvxYcyOGr/c0a9133a1cef568c4159dd43e3cc8d62/results-neghin-after.jpg?fl=progressive",
    NEGHIN_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/a2tY29nX3NClN1pp2QSsv/95fa380649a2ba4833c856e43cfad914/results-neghin-tn.png?fl=progressive",

    ENSLEY_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/5NjxHxibM76PYZkGykhejP/bc8eb02101e5a59f5f2eebb24aaffeea/ensley-before.png?fl=progressive",
    ENSLEY_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7nHyMnSbHzTPXN1mQEeoaF/f7cc550d9da81b48aaf46185363c8dd3/ensley-after.png?fl=progressive",
    ENSLEY_TN:
      "https://images.ctfassets.net/0rbfqd9c4jdo/3dk0DmHWPDlk3XoqWbSrA0/bdcc1bcb7b4c58264f96ce581aeec8d3/results-ensley-tn.png?fl=progressive",
    MAJIT_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/61ffb7ww0fMkfi8Ankj2ln/7f9162f4b833c7bfbef1be57251dc0b2/MajitBefore.png?fl=progressive",
    MAJIT_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/XBkgAk9W8hkluVS4xclXe/1afcee592cf2d5befdf079ad441be395/MajitAfter.png?fl=progressive",
    HEIDI_BEFORE:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6QnMIF3iKbnf5NuYKk9aLh/2818c88dc3ac217c1254e575b0e6e0cb/heidi-before.png?fl=progressive",
    HEIDI_AFTER:
      "https://images.ctfassets.net/0rbfqd9c4jdo/30aNhn80Sp2AIQvyHJwFyL/6464f0d57afeb49e18b46191719ae517/heidi-after.png?fl=progressive",
  },
}

export const MASK_ESSENCE_MAP = {
  join: `https://images.ctfassets.net/0rbfqd9c4jdo/35YuyQFxHRtzDclbCDOI2O/fbeb5f99d297e4103486e7c587896547/MASK_ESSENCE_BUNDLE.png?fl=progressive`,
  WOM1: [
    `https://images.ctfassets.net/0rbfqd9c4jdo/71YPLO6GeaStObblwpiDA2/fcce0851db351f1b34b5e384d3dad07c/wom0.png?fl=progressive`,
    `https://images.ctfassets.net/0rbfqd9c4jdo/5328Oyo015oy62jVusHJiK/f3272bde7d5b262d1f886c0f5a63a44a/wom1.png?fl=progressive`,
    `https://images.ctfassets.net/0rbfqd9c4jdo/n61gh4rizDemlSyw42zkM/6b3d771c4b35144451394a84a3694b63/wom2.png?fl=progressive`,
  ],
  BAL1: [
    `https://images.ctfassets.net/0rbfqd9c4jdo/1Qn1gO9UokHEJD9jGa8Ncs/8dfadf22584ceb167c73750fb192a707/bal0.png?fl=progressive`,
    `https://images.ctfassets.net/0rbfqd9c4jdo/2tfaJ4weZbwmmwcYVlfL7j/5cbfc25e403f2e3004c015a9439ad845/bal1.png?fl=progressive`,
    `https://images.ctfassets.net/0rbfqd9c4jdo/7Km3KgtuiPuXb9B1XY5p5g/163f38f78842693a4379dd44f642676b/bal2.png?fl=progressive`,
  ],
  VEG1: [
    `https://images.ctfassets.net/0rbfqd9c4jdo/7cT8escS8GNPhUGAqeOtZq/1bfb7935eb557d35d306a6c7a1eb5c7a/veg0.png?fl=progressive`,
    `https://images.ctfassets.net/0rbfqd9c4jdo/4u4mIVy2cRWf0Lw5U4MN2N/af1a3d0adc914eab9aed30b3508e4c4b/veg1.png?fl=progressive`,
    `https://images.ctfassets.net/0rbfqd9c4jdo/7iQkpEm5aGVzxnKEhuFQoC/0aec665aa494067b778b06213d718d33/veg2.png?fl=progressive`,
  ],
  POS1: [
    `https://images.ctfassets.net/0rbfqd9c4jdo/7AWMlRxD0aNmBi499TmWjN/d4fda56506264f99e2f2a19fbecd169d/pos0.png?fl=progressive`,
    `https://images.ctfassets.net/0rbfqd9c4jdo/5WKMYYwd73fz4xIkDTiF7Q/8e0e3d04a3750d792467f3d979d8e76f/pos1.png?fl=progressive`,
    `https://images.ctfassets.net/0rbfqd9c4jdo/61byHpYHtfS4jTjaBFVqlg/8f228f2c0dfe60c911d57f3f86cf711c/pos2.png?fl=progressive`,
  ],
  MENPRO1: [
    `https://images.ctfassets.net/0rbfqd9c4jdo/3rQ2ThUV9A6SqynwM9Ww8u/7da556d635120c77e6a310c5ba9c8b4e/men0.png?fl=progressive`,
    `https://images.ctfassets.net/0rbfqd9c4jdo/SWoMcpuvsG94wRYiKFOkM/6323a08b2bfd532d850d3b8b51943e8d/men1.png?fl=progressive`,
    `https://images.ctfassets.net/0rbfqd9c4jdo/6jJkqjJfRysrmcDNrJgNN1/e21e0c3de847b5e43fdc0b329a9a0629/men2.png?fl=progressive`,
  ],
}
